import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { Store } from "../store";
import { createGroupApi, getAllGroupsApi, getAllGroupsByEventApi, getGroupDetailApi } from "../api/groups.api";
import { IGroupAdd } from "../api/types/group.type";

export const GetAllGroupsQuery = ({store}:{store:Store}) => 
    useQuery(["getAllGroupsApi"], getAllGroupsApi, {
        onSuccess(data) {
            store.setGroups(data);
            store.setRequestLoading(false);
        },
        onError(error) {
        store.setRequestLoading(false);
        },
    });

export const GetGroupsByEventQuery = ({store, eventId}:{store:Store, eventId: string}) => 
    useQuery(["getAllGroupsByEventApi",{eventId}], () => getAllGroupsByEventApi({eventId}), {
        onSuccess(data) {
            store.setGroups(data);
            store.setRequestLoading(false);
        },
        onError(error) {
        store.setRequestLoading(false);
        },
    });



export const GetGroupDetailsQuery = ({store, groupId}:{store:Store, groupId:string}) => 
    useQuery(["getGroupDetailApi",{groupId}], () => getGroupDetailApi({groupId}), {
        onSuccess(data) {
            store.setGroup(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
    });


export const CreateGroupQuery = () => useMutation({
    mutationFn: async (groupData: IGroupAdd) => {
        return await createGroupApi(groupData)
    },
})