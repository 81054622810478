import Container from "../Container/Container";

const HeaderImgs = {
  jugar: "/headers/juga.png",
  como: "/headers/como.png",
  premios: "/headers/HEADER-PREMIOS.png",
  results: "/headers/results.png",
  table: "/headers/table.png",
};

const BannerImgsClass = {
  premios: "bg-hero-premios",
  como: "bg-hero-how-to-play",
  table: "bg-hero-tabla-liga"
  
};

type HeroImg = keyof typeof HeaderImgs;
type BannerImgsClass = keyof typeof BannerImgsClass;

const Hero = ({
  title,
  image,
  main = false,
}: {
  title?: string;
  image?: string;
  main?: boolean;
}) => {
  
  return (
    <section className={`${ image && BannerImgsClass[image as BannerImgsClass] as string  ? BannerImgsClass[image as BannerImgsClass] as string  : "bg-hero-generic"} bg-center bg-no-repeat bg-contain`}>
      <div className="relative grid place-items-center h-[33vw] p-8 md:p-12">
        <Container>
          
        </Container>
      </div>
    </section>
  );
};

export default Hero;
