import React from "react";
import { Container } from "../components";

interface SigninLayoutProps {
  children: React.ReactNode;
}

const SigninLayout: React.FC<SigninLayoutProps> = ({ children }) => {
  return (
    <section className="bg-center bg-no-repeat bg-cover bg-hero-pattern">
      <div className="min-h-screen p-8 bg-claro-1000/50 md:p-12">
        <Container>{children}</Container>
      </div>
    </section>
  );
};

export default SigninLayout;
