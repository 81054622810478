import React from "react";
import { useFormContext } from "react-hook-form";

interface FormInputProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  name: string;
}

const FormInput: React.FC<FormInputProps> = (props) => {
  const { label, name, ...rest } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={name} className="text-black">
        {label}
      </label>
      <input
        disabled={rest.disabled}
        className={
          `w-full rounded-md border-black appearance-none py-2 px-4 focus:outline-none focus:ring-transparent focus:border-black
          ${ !rest.disabled ? '' : 'disabled bg-gray-300' }`
          }
        {...rest}
        {...register(name)}
      />
      {errors[name] && (
        <span className="text-red-500 text-xs">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};

export default FormInput;
