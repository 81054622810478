import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { LoadingButton } from "../../components/LoadingButton";
import { CreateTeamQuery } from "../../queries/treams.query";

const schema = yup
  .object({
    picture: yup.mixed(),
    name: yup.string().required(),
    events: yup.array().default(() => ["63f5f82492b435262c15a14b"]),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const CreateTeamsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const {
    mutate: registerTeam,
    isSuccess,
    isLoading
  } = CreateTeamQuery();

  const [selectedPicture, setSelectedPicture] = useState<File | null>(null);

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    file && setSelectedPicture(file);
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    registerTeam({
      events: values.events,
      name: values.name
    });
  };

  return (
    <>
      <section className="bg-ct-blue-600 min-h-screen">
        <div className="max-w-4xl mx-auto flex justify-center items-center">
          <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <label>Nombre del Equipo: </label>
              <input {...register("name")} />
              <p>{errors.name?.message}</p>
              <br></br>
              <input
                placeholder="Bandera del equipo"
                type="file"
                {...register("picture")}
                accept="image/*"
                onChange={handlePictureChange}
              />
              <br></br>
              {selectedPicture && (
                <img
                  src={URL.createObjectURL(selectedPicture)}
                  alt="Imagen del equipo"
                />
              )}
              <br></br>

              <LoadingButton loading={isLoading}>Crear</LoadingButton>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateTeamsPage;
