import { Spinner } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EventScores, IEventScore, Stats } from "../api/types/events.type";
import { IUsersRankings } from "../api/types/users-rankings.types";
import { Container, PredictionsTable, StatsCard, Tabs } from "../components";
import { UserPointsProps } from "../components/Table/types";
import { InitTabs, Tab } from "../components/Tabs/initTabs";
import { LiguesENUM, RankingGroupsENUM, RankingsENUM } from "../enums/rankings.enum";
import {
  predictions
} from "../mockData/predictionsTable";
import {
  GetEventScoreQuery,
  GetEventStatsQuery
} from "../queries/events.query";

import useStore from "../store";

const stats: Stats[] = [
  {
    name: "Partidos jugados",
    from: 0,
    total: 0,
    key: "game_played",
  },
  {
    name: "Resultados Correctos",
    from: 0,
    total: 0,
    key: "hits",
  },
];


const LeaguePage = () => {
  const { eventId } = useParams();
  const [tabs, setTabs] = useState<Tab[]>(InitTabs);
  const [selectedTab, setSelectedTab] = useState(InitTabs[0].name);
  const [playersScore, setPlayersScore] = useState<UserPointsProps[]>([]);
  const [userScore, setUserScore] = useState<number>(0);
  const [maxRank, setMaxrank] = useState<number>(1);
  const [userRanking, setUserRanking] = useState<number>(1);
  const [userPosition, setUserPosition] = useState<number>(0);
  const [eventStats, setEventStats] = useState<Stats[]>(stats);
  const [rankings, setRankings] = useState<IUsersRankings[]>();
  const store = useStore();
  
  const { refetch, data, isLoading } = GetEventScoreQuery({
    enabled: false,
    eventId: "63f5f82492b435262c15a14b",
  });
  const { refetch: eventStatsRefetch, data: dataStats } = GetEventStatsQuery({
    enabled: false,
    eventId: "63f5f82492b435262c15a14b",
  });

  const filterTabByLeague = ():Tab[] => {
    if (store.authUser?.isCostumer) {
      return InitTabs.filter(tab => tab.name !== LiguesENUM.VISITANTE)
    }else{
      return InitTabs.filter(tab => tab.name !== LiguesENUM.LOCAL)
    }
  }

  useEffect(() => {
    setTabs(filterTabByLeague());
  }, []);

  useEffect(() => {
    if (eventId) {
      refetch();
      eventStatsRefetch();
    }
  }, [eventId]);

  const calculateTotalScore = ({scores}:{scores:EventScores}) => {
    const totalScores = { score: 0, hits: 0, simpleHits: 0, doubleHits:0 };

    for (const roundKey in scores) {
      if(roundKey !== RankingGroupsENUM.ALL){
        const round = scores[roundKey];
        totalScores.score += round.score;
        totalScores.hits += round.hits;
        totalScores.simpleHits += round.simpleHits;
        totalScores.doubleHits += round.doubleHits;
      }
    }
    
    return totalScores
  }

  const findGroupByName = (tabs: Tab[], name: string): string => {
    const foundTab = tabs.find(tab => tab.name === name);
    return foundTab?.group || RankingGroupsENUM.ALL;
  }

  const processEventScore = (data: IEventScore[]) => {
    let usersRanking: any

    const tab = tabs.find( tab => tab.name === selectedTab);
    // Fix this.
    // Si el torneo no tiene fases la tab a iniciar es local o visitante. Pero como?
    // Dejo por defecto general
    if(!tab) return

    if (tab.group === RankingsENUM.CUSTOM_RANKING){
      const customRanking = rankings?.find( (ranking) => {
        return ranking.code === tab.code 
      })
      
      usersRanking = customRanking?.members.map( (m:any) => m._id)
    }

    let playersScore = data
      .filter((d: IEventScore) => {
        
        if(tab.name === LiguesENUM.VISITANTE){
          return !d.user?.isCostumer;
        }

        if(tab.name === LiguesENUM.LOCAL){
          return d.user?.isCostumer;
        }
        
        return true
        
      })
      .map((d: IEventScore, index: number) => {
        const _totalScore = calculateTotalScore(d)
        d.scores["ALL"] = _totalScore
        if (d.user?._id === store.authUser?.id) {
          const totalScore = calculateTotalScore(d)
          setUserScore(totalScore.score)

          setEventStats((prevStats) => {
            return prevStats.map((stat) => {
              if (stat.key === "hits") {
                return {
                  ...stat,
                  from: totalScore.hits + totalScore.simpleHits,
                  total: eventStats[0].from,
                };
              } else {
                return stat;
              }
            });
          });
        }

        
        const group = findGroupByName(InitTabs, tab.name);
        const phaseScore = d.scores[group]
        if(!phaseScore) console.log("El servidor no computo el grupo: ", group, d.scores)
        if(!phaseScore) return {
          user: d.user,
          score: 0,
          doubleHits: 0,
          hits: 0,
          simpleHits: 0,
          global: 0,
          ligue: d.user?.isCostumer ? "Liga local" : "Liga visitante",
          isCostumer: d.user?.isCostumer,
        };

        const data = {
          user: d.user,
          score: phaseScore.score,
          doubleHits: phaseScore.doubleHits,
          hits: phaseScore.hits < 0 ? 0 :  phaseScore.hits,
          simpleHits: phaseScore.simpleHits < 0 ? 0 :  phaseScore.simpleHits,
          global: d.scores[RankingGroupsENUM.ALL].score,
          ligue: d.user?.isCostumer ? "Liga local" : "Liga visitante",
          isCostumer: d.user?.isCostumer,
        };

        if(d.user.email === "admin@admin.com") console.log({d, phaseScore, data})
        
        return data;
      })
      
      
      // Sort BY
      //1. Score (points1), 2.hits (points2), 3.simpleHits (points4)
      // In case triples sort by doubleHits then score then global
      const playersScoreOrdered = playersScore.sort((a, b) => {
        if(tab.name === RankingsENUM.TRIPLES){
          if (a.doubleHits !== b.doubleHits) {
            return b.doubleHits - a.doubleHits;
          } else if (b.score !== a.score) {
            return b.score - a.score;
          } else {
            return b.global - a.global;
          }
        }else{
          if (a.score !== b.score) {
            return b.score - a.score;
          } else if (b.doubleHits !== a.doubleHits) {
            return b.doubleHits - a.doubleHits;
          } else if (b.hits !== a.hits) {
            return b.hits - a.hits;
          } else {
            return b.global - a.global;
          }
        }
        
      });

      let currentRank = 1
      let userRanking = 1
      
      const playersScoreProcess = playersScoreOrdered.map( (d: UserPointsProps, index: number)=>{
        if(index ===  0){
          d.ranking = currentRank
          d.rankingLabel = currentRank.toString()
          return d
        }
        const previousD = playersScore[index - 1] 
        const previousUserScoring =  
          previousD.score +
          previousD.doubleHits +
          previousD.hits +
          previousD.simpleHits +
          previousD.global
        
        const currentUserScoring = 
          d.score +
          d.doubleHits +
          d.hits +
          d.simpleHits +
          d.global

        if (index > 0 && previousUserScoring !== currentUserScoring) {
          currentRank += 1;
        } 

        if(  d.user._id === store.authUser?.id ){
          userRanking = currentRank
        }

        d.ranking = currentRank
        d.rankingPosition = previousUserScoring
        
        d.rankingLabel = previousUserScoring === currentUserScoring ? "-" : currentRank.toString()
        d.rankingPosition = currentRank
        
        return d
        

      });
      
      setMaxrank(currentRank)
      setUserRanking(userRanking)
      setPlayersScore(playersScoreProcess || []);
  };

  useMemo(() => {
    if (dataStats) {
      const totalCrosses = dataStats.crosses?.open + dataStats.crosses?.close;
      const totalBets = dataStats.bets?.hits + dataStats.bets?.unhits;
      stats[0].from = dataStats.crosses?.close;
      stats[0].total = isNaN(totalCrosses) ? 0 : totalCrosses;
      stats[1].from = dataStats.bets?.hits;
      stats[1].total = isNaN(totalBets) ? 0 : totalBets;
      setEventStats(stats);
    }
  }, [dataStats]);

  useEffect(() => {
    if (data) {
      processEventScore(data);
    }
  }, [data, selectedTab]);

  return (
    <Container className="flex flex-col gap-4 px-5 py-5 md:py-12 lg:px-0">
      <section className="flex flex-col justify-between md:flex-row">
        <h3><span className="text-transform: capitalize">{selectedTab}</span>: {userRanking}° / {maxRank}</h3>
        <h3>Tus puntos: {userScore}</h3>
      </section>
      <dl className="grid grid-cols-1 overflow-hidden bg-white border border-gray-200 divide-y divide-gray-200 rounded-lg md:grid-cols-5 md:divide-y-0 md:divide-x">
        {eventStats.map(({ name, from, total }) => (
          <StatsCard key={name} name={name} from={from} total={total} />
        ))}
      </dl>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      { 
       isLoading 
        ? 
       <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
          <Spinner className="mt-10"/>
          Cargando tabla de datos
        </div> :
      
          <>
            {playersScore.length > 0 ? <PredictionsTable
              columns={predictions.columns}
              players={playersScore}
              currenPlayer={store?.authUser}
              selectedTab={selectedTab}
              setUserPosition={setUserPosition}
            /> : 
            <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
              Todavía no hay datos para mostrar
            </div>
            }
          </> 
          }
    </Container>
  );
};

export default LeaguePage;
