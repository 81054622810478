import { ITeam } from "../api/types/teams.type"
import { IUser } from "../api/types/types"

export enum  EventStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}

export interface IEvent {
  name: String,
  id?: String,
  _id?: String,
  status: string,
  timeClose?: number,
  crosses: [],
  teams: string[]
}

export interface IEventDetail {
  name: String,
  id?: String,
  _id?: String,
  status: string,
  crosses: [],
  users: IUser[]
  timeClose: number
  teams: ITeam[]
}
