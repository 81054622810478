export enum API_ENDPOINTS {
    // ADMIN
    GENERAL_METRICS = "/bets/platform-general-metrics",
    // USER RANKINGS
    CREATE_RANKING = "/users-ranking/create-ranking",
    GET_USERS_RANKINGS = "/users-ranking",
    GET_RANKING_MEMBERS = "/users-ranking/members",
    UPDATE_RANKING_MEMBER = "/users-ranking/ranking-member",
    GET_RANKINGS = "/users-ranking/rankings",
    UPDATE_RANKING_NAME = "/users-ranking/ranking-name",
    // USER 
    GET_USER_LOGIN_HISTORY = "/users/login-history",
    GET_USER_BY_EMAIL = "/users/email",
    USER_SINGUP = "/auth/singup",
    LOGIN = "/auth/login",
    USER_PROFILE = "/users/my",
    USER_ADD_EVENT = "/users/add-event",
    GET_USER_BETS_BY_EVENT = "/bets/bets-by-event",
    VERIFY_TOKEN = "/auth/verify-login",
    REQUEST_RESET_USER_PASSWORD = "/auth/request-user-password",
    REFRESH_PASSWORD = "/users/refresh-password",
    UPDATE_USER_PROFILE = "/users/profile",
    UPDATE_USER = "/users/update-user",
    USER_RESEND_EMAIL_CONFIRMATION = "/auth/resend-user-email-comnfirmation",
    //EVENTS
    GET_ALL_EVENTS = "/bets/all-events",
    GET_ALL_OPEN_EVENTS = "/bets/all-open-events",
    GET_EVENT = "/bets/event",
    GET_EVENT_DETAILS = "/bets/event-details",
    CREATE_EVENT = "/bets/add-event",
    ADD_TEAMS_TO_EVENT = "/bets/add-teams-to-event",
    UPDATE_EVENT_STATUS = "/bets/update-event-status",
    UPDATE_EVENTS_TO_TEAM = "/bets/update-events-to-team",
    ADD_EVENTS_TO_USER = "/users/update-events",
    GET_USER_EVENTS = "/users/events",
    GET_EVENT_SCORES = "/bets/event-scores",
    GET_EVENT_STATS = "/bets/event-stats",
    PROCESS_EVENT_SCORE = "/bets/process-event-score",
    //TEAMS
    GET_ALL_TEAMS = "/bets/all-teams",
    GET_TEAM_BY_ID = "/bets/team-by-id",
    GET_TEAMS_BY_EVENT = "/bets/teams-by-event",
    CREATE_TEAM = "/bets/add-team",
    //CROSSS
    GET_CROSSES_BY_EVENT = "/bets/crosses-by-event",
    CREATE_CROSS = "/bets/add-cross",
    GET_CROSSES_BY_GROUP = "/bets/crosses-by-group",
    GET_CROSSES_BY_ID = "/bets/cross-by-id",
    CLOSE_CROSS = "/bets/close-cross",
    GET_CROSSES_USER_SCORE = "/bets/cross-score",
    UPDATE_CROSS = "/bets/update-cross",
    DELETE_CROSS = "/bets/delete-cross",
    CHECK_CROSS_QUESTIONS = "/bets/check-cross-questions",
    //FIELDS
    GET_FIELDS_BY_FIELD = "/bets/bets-by-field",
    GET_FIELDS_BY_EVENT = "/bets/fields-by-event",
    GET_FIELDS_BY_CROSS = "/bets/fields-by-cross",
    CREATE_FIELD = "/bets/add-field",
    ADD_FIELD_TO_CROSS = "/bets/add-field-to-cross",
    UPDATE_FIELD_TO_CROSS = "/bets/update-fields-to-cross",
    UPDATE_FIELD_RESULT = "/bets/update-fields-results",
    //GROUPS
    CREATE_GROUP = "/bets/add-group",
    GET_ALL_GROUPS = "/bets/all-groups",
    GET_GROUP_DETAILS = "/bets/group-details",
    GET_ALL_GROUPOS_BY_EVENT = "/bets/groups-by-event",
    //BETS
    ADD_USER_BET = "/bets/add-bet"
    
    
    
    
  }