import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TypeOf } from "zod";
import { ILoginResponse } from "../api/types/types";
import { AuthContainer } from "../components";
import FormInput from "../components/Forms/FormInput";
import FormToggle from "../components/Forms/FormToggle";
import { LoadingButton } from "../components/LoadingButton";
import SigninLayout from "../layouts/SigninLayout";
import { SingUpQuery } from "../queries/user.query";
import { RoutesEnum } from "../router/enums/routes.enum";
import { registerSchema } from "../schemas";
import useStore from "../store";

export type RegisterForm = TypeOf<typeof registerSchema>;
const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCostumer, setIsCostumber] = useState<boolean>(false)
  const [errorRecaptcha, setErrorRecaptcha] = useState<string | null>()
  const captchaRef = useRef(null)
  const store = useStore();
  

  const from = ((location.state as any)?.from.pathname as string) || "/profile";

  const methods = useForm<RegisterForm>({
    resolver: zodResolver(registerSchema),
  });

  const { mutate: singupUser, isSuccess, isError, error, isIdle, data: singUpResponse } = SingUpQuery();

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useMemo(() => {
    store.reset();
  }, []);

  useEffect(()=>{
    if(isSuccess && singUpResponse){
      store.setToken((singUpResponse as ILoginResponse).access_token);
      store.setAuthUser((singUpResponse as ILoginResponse).payload);
      navigate(`${RoutesEnum.PREDICTIONS}`);
    }
  },[isSuccess, singUpResponse])

  const onSubmitHandler: SubmitHandler<RegisterForm> = (values) => {
    values.isCostumer = isCostumer

    //@ts-ignorets-ignore
    const token = captchaRef.current?.getValue();
    //@ts-ignorets-ignore
    captchaRef.current?.reset();

    if(!token || token === "" || token.lenght < 1){
      setErrorRecaptcha("Debe completar el captcha")
    }else{
      values.recaptcha = token
      singupUser(values);
    }
  };

  return (
    <SigninLayout>
      <AuthContainer title="Registrarse">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="flex flex-col w-full gap-3 px-4 md:px-8"
          >
            <p className="mb-2">
            </p>
            <FormInput
              label="Nombre"
              placeholder="Aaron"
              name="name"
              type="text"
            />
            <FormInput
              label="Apellido"
              placeholder="Judge"
              name="lastName"
              type="text"
            />
            <FormInput
              label="Email"
              placeholder="usuario@claropr.com"
              name="email"
              type="email"
            />
            <FormInput
              label="Teléfono"
              placeholder="Teléfono"
              name="phoneNumber"
              type="text"
            />
            <FormToggle
              label="¿Eres un cliente Claro?"
              setIsCostumber={setIsCostumber}

            />
            <FormInput
              label="Contraseña"
              placeholder="Contraseña"
              name="password"
              type="password"
            />
            <FormInput
              label="Confirma la contraseña"
              placeholder="Confirma la contraseña"
              name="passwordConfirm"
              type="password"
            />

            {/* {isIdle && (
              <>
                Se ha enviado un correo electronico con un link para verificar
                su cuenta{" "}
              </>
            )} */}
            {isError && (error as any)?.response?.status === 409 && (
              <>El email ya se encuentra registrado</>
            )}

            <span className="text-red-500 text-base">{ errorRecaptcha }</span>
            <ReCAPTCHA
              sitekey={"6LekklklAAAAAOe9ZAtniVXIfuzGtAGrczJ8ZOYZ"}
              ref={captchaRef}
              />
            

            <LoadingButton
              loading={store.requestLoading}
              textColor="text-white"
            >
              Empieza a ganar con Claro
            </LoadingButton>
            <div className="text-right">
              <Link to="/login">¿Ya tienes una cuenta?</Link>
            </div>
            
          </form>
        </FormProvider>
      </AuthContainer>
    </SigninLayout>
  );
};

export default RegisterPage;
