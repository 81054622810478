import React, { useEffect, useState } from "react";
import { RankingsENUM } from "../../enums/rankings.enum";
import PlayerCell from "./PlayerCell";
import { PredictionsTableProps } from "./types";


const pageSize = 25; // number of rows to display per page

const PredictionsTable: React.FC<PredictionsTableProps> = (props) => {
  const { columns, players, currenPlayer, selectedTab, setUserPosition } = props;
  
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(players.length / pageSize);
  const start = Math.max(currentPage * pageSize, 0);
  const end = start + pageSize;
  const FIRST_PAGE = 0
  const LAST_PAGE = totalPages - 1
  
  const paginatedData = players.slice(start, end);

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  useEffect(() => {
    const currentIndex = players.findIndex(
      (player) => player.user?._id === currenPlayer?.id
    );
    setUserPosition(currentIndex+1)
    const newPage = Math.floor(currentIndex / pageSize);
    setCurrentPage(newPage);
  }, [currenPlayer, players]);

  const Pagination = () => {
    const MAX_PAGES = 10;
  
    const leftOffset = Math.max(currentPage - Math.floor(MAX_PAGES / 2), 0);
    const rightOffset = Math.min(leftOffset + MAX_PAGES, totalPages);
  
    const pages = [];
    for (let i = leftOffset; i < rightOffset; i++) {
      pages.push(i);
    }
  
    const handleLeftDotsClick = () => {
      setCurrentPage((prev) => Math.max(prev - MAX_PAGES, 0));
    };
  
    const handleRightDotsClick = () => {
      setCurrentPage((prev) => Math.min(prev + MAX_PAGES, totalPages - 1));
    };
  
    return (
      <div className="flex items-center">
        {currentPage > 0 && (
          <>
          <button
            className="mx-1 px-3 py-1 rounded-lg text-gray-700 hover:bg-gray-200"
            onClick={()=>setCurrentPage(FIRST_PAGE)}
          >
            {"<<<"}
          </button>
          <button
            className="mx-1 px-3 py-1 rounded-lg text-gray-700 hover:bg-gray-200"
            onClick={handleLeftDotsClick}
          >
            {"<<"}
          </button>
          </>
        )}
        {pages.map((index) => (
          <button
            key={index}
            className={`mx-1 px-3 py-1 rounded-lg ${
              currentPage === index
                ? "bg-gray-800 text-white"
                : "text-gray-700 hover:bg-gray-200"
            }`}
            onClick={() => setCurrentPage(index)}
          >
            {index + 1}
          </button>
        ))}
        {currentPage < totalPages - 1 && (
          <>
            
            <button
            className="mx-1 px-3 py-1 rounded-lg text-gray-700 hover:bg-gray-200"
            onClick={handleRightDotsClick}
          >
            {">>"}
            </button>
            <button
              className="mx-1 px-3 py-1 rounded-lg text-gray-700 hover:bg-gray-200"
              onClick={()=>setCurrentPage(LAST_PAGE)}
            >
              {">>>"}
            </button>
          </>
          
        )}
      </div>
    );
  };
  

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
                <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  ></th>
              {columns
                .filter((column) => {
                  if(column === "Liga"){
                    return selectedTab === RankingsENUM.GENERAL
                  }
                  if(column === "Total global"){
                    return selectedTab === RankingsENUM.FINAL || selectedTab === RankingsENUM.SEMIFINAL || selectedTab === RankingsENUM.ROUND_2
                  }
                  return true
                })
                .map((column) => (
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {column}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {paginatedData.length > 0 && paginatedData.map((player, index) => {
              return (
                <PlayerCell
                  player={player}
                  index={
                    index + currentPage * pageSize + (currentPage === 0 ? 1 : 0)
                  }
                  currenPlayer={currenPlayer}
                  selectedTab={selectedTab}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {players.length !== 0 && (
        <div className="flex justify-between mt-4">
          <button
            className="px-4 py-2 text-white rounded bg-claro-1000 hover:bg-claro-800 disabled:bg-gray-600"
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            Anterior
          </button>
          
          <Pagination/>

          <button
            className="px-4 py-2 text-white rounded bg-claro-1000 hover:bg-claro-800 disabled:bg-gray-600"
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
          >
            Siguiente
          </button>
        </div>
      )}
    </>
  );
};

export default PredictionsTable;
