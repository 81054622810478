import { API_ENDPOINTS } from '../enums/api.enum';
import { api, authApi } from './';
import { IBets, IBetsAdd } from './types/bets.type';
import { ILoginResponse, INewPassword, ISingUp, IUpdateUserProfile, IUser, IUserUpdate } from './types/types';

export const getUserProfileFn = async ({userId}:{userId?:string}) => {
  let url = `${API_ENDPOINTS.USER_PROFILE}`
  if(userId) url +=  `?userId=${userId}`
  const response = await authApi.get<IUser>(url);
  return response.data;
};

export const singUpApi = async (singup: ISingUp) => {
  const response = await authApi.post<ILoginResponse>(API_ENDPOINTS.USER_SINGUP, singup);
  return response.data;
};

export const addUserEventApi = async (event: string) => {
  const response = await authApi.put<any>(API_ENDPOINTS.USER_ADD_EVENT, {event});
  return response.data;
};

export const addUserBetApi = async (bet: IBetsAdd) => {
  const response = await authApi.post<any>(API_ENDPOINTS.ADD_USER_BET, bet);
  return response.data;
};

export const requestResetUserPassword = async (email: string) => {
  const response = await api.post<any>(API_ENDPOINTS.REQUEST_RESET_USER_PASSWORD, {email});
  return response.data;
};

export const getUserBetsByEventApi = async (eventId: string, userId?:string) => {
  let url = `${API_ENDPOINTS.GET_USER_BETS_BY_EVENT}`
  if(userId) url +=  `&userId=${userId}`
  if(eventId) url +=  `&id=${eventId}`
  const response = await authApi.get<IBets[]>(url);
  return response.data;
};

export const patchUserPassordApi = async (newPassword: INewPassword) => {
  const response = await authApi.patch<IUser>(`${API_ENDPOINTS.REFRESH_PASSWORD}`, newPassword);
  return response.data;
};

export const patchUserProfileApi = async (updateProfile: IUpdateUserProfile) => {
  const response = await authApi.patch<IUser>(`${API_ENDPOINTS.UPDATE_USER_PROFILE}`, updateProfile);
  return response.data;
};

export const getUserByEmailApi = async (email: string) => {
  const response = await authApi.get<IUser>(`${API_ENDPOINTS.GET_USER_BY_EMAIL}/${email}`);
  return response.data;
};

export const updateUserApi = async (user: IUserUpdate) => {
  const response = await authApi.patch<IUserUpdate>(`${API_ENDPOINTS.UPDATE_USER}`, user);
  return response.data;
};

export const resendEmailConfirmationApi = async (email: string) => {
  const response = await authApi.post<void>(`${API_ENDPOINTS.USER_RESEND_EMAIL_CONFIRMATION}`, {email});
  return response.data;
};





