import { Badge, Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableOptions, useTable } from "react-table";
import { IEvent } from "../interfaces/event.interfaces";
import {
  GetAlleventsQuery,
  GetProcessEventScoreApi,
} from "../queries/events.query";
import { RoutesEnum } from "../router/enums/routes.enum";
import useStore from "../store";

interface IEventTable extends IEvent {
  actions?: string;
}

const EventsTable = () => {
  const [eventSelected, setEventSelected] = useState<string>("");
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const navigate = useNavigate();
  const store = useStore();

  GetAlleventsQuery({ store });

  const {
    refetch,
    isLoading,
    isFetching
  } = GetProcessEventScoreApi({
    eventId: eventSelected,
    enabled: false,
  });

  useEffect(() => {
    if (eventSelected) {
      setIsLoadingData(true); // set loading state to true when fetching data
      refetch().finally(() => setIsLoadingData(false)); // reset loading state after fetching data
    }
  }, [eventSelected]);

  const data = React.useMemo(
    (): IEventTable[] => store.events ?? [],
    [store.events]
  );

  const columns = React.useMemo<Column<IEventTable>[]>(
    () => [
      {
        Header: "Nombre",
        accessor: "name", // accessor is the "key" in the data
        Cell: ({ cell }) => (
          <>
            {cell.row.original.status === "OPEN" ? (
              cell.row.original.name
            ) : (
              <div className="line-through"> {cell.row.original.name} </div>
            )}
          </>
        ),
      },
      {
        Header: "Estado",
        accessor: "status",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.status === "OPEN" ? (
              <Badge color="success">ABIERTO</Badge>
            ) : (
              <Badge color="failure">CERRADO</Badge>
            )}
          </>
        ),
      },
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: ({ cell }) => (
          <>
            <button
              className="blueButton"
              onClick={() => handleOnClickDetails(cell.row.original.id ?? "")}
            >
              Ver detalle
            </button>
            <button
              className="blueButton"
              onClick={() => handleOnClickEdit(cell.row.original.id ?? "")}
            >
              Editar
            </button>
            <button
              className="blueButton"
              onClick={() => handleOnClickCrosses(cell.row.original.id ?? "")}
            >
              Cruces
            </button>
            <button
              className="blueButton"
              onClick={() =>
                handleOnClickProcessEvent(cell.row.original.id ?? "")
              }
            >
              {isLoadingData ? "Procesando..." : "Procesar resultados"}
              
            </button>
          </>
        ),
      },
    ],
    []
  );

  const options: TableOptions<IEventTable> = {
    data,
    columns,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  const handleOnClickDetails = (eventId: String) => {
    navigate(`${RoutesEnum.EVENT_DETAILS}/${eventId}`);
  };

  const handleOnClickEdit = (eventId: String) => {
    navigate(`${RoutesEnum.EVENT_EDIT}/${eventId}`);
  };

  const handleOnClickCrosses = (eventId: String) => {
    navigate(`${RoutesEnum.CROSSES_BY_EVENT}/${eventId}`);
  };

  const handleOnClickProcessEvent = (eventId: String) => { 
    if(eventId === eventSelected) refetch()
    setEventSelected(eventId as string);
  };

  return (
    <>
    
    <div className="flex flex-col items-center justify-between">
      { isFetching && <>
        <Spinner/>
        <p>Procesando resultados...</p>
      </> }

    </div>
      <table {...getTableProps()} className="w-">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    </>
  );
};

export default EventsTable;
