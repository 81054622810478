import { Badge, Button, Card, Select } from "flowbite-react";
import moment from 'moment';
import { useEffect, useMemo, useState } from "react";
import { GrAlert } from 'react-icons/gr';
import { useNavigate, useParams } from "react-router-dom";
import { ICross } from "../api/types/cross.type";
import { EventStatus } from "../interfaces/event.interfaces";
import { DelteCrossQuery, GetCrossesByEventQuery } from "../queries/crosses.query";
import { GetAlleventsQuery } from "../queries/events.query";
import useStore from "../store";
import { ConfirmDialog } from "./Dialog";

import FormAddCrossesToEvent from "./Forms/FormAddCrosses";
import FormCloseCross from "./Forms/FormCloseCross";
import DefaultModal from "./Modal/Modal";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CrossesByEventCards = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [ event, setEvent ] = useState<string>( eventId || "" )
    const [ crosses, setCrosses ] = useState<ICross[]>([])
    const [showAll, setShowAll] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState<Boolean>(false)
    const [crossToDelete, setCrossToDelete] = useState<string | undefined>()
    const [crossIdToClose, setCrossIdToClose] = useState<string>()
    const [crossIdToEdit, setCrossIdToEdit] = useState<string>()
    const [newCross, setNewCross] = useState<boolean>(false)
    
    const store = useStore();

    
    const { refetch } = GetCrossesByEventQuery({ store, eventId:event, enabled:true })
    const { mutate: delteCrossEvent, data, isLoading, isSuccess:  delteCrossSuccess} = DelteCrossQuery();
    GetAlleventsQuery({store})
    
    const events = store.events
    
    const handleEventOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setEvent(e.currentTarget.value)
    }

    useEffect(()=>{
      refetch()
    },[event])

    useEffect(()=>{
        setCrosses(store.crosses || [])
    },[store.crosses])

    const statusBadge = (cross: ICross) => {
        return cross.status === EventStatus.OPEN ? 
            <Badge color="success"> ABIERTO </Badge>
            : <Badge color="failure"> {cross.team1Result && cross.team2Result ? "CERRADO Y PROCESADO" : "CERRADO SIN PROCESAR"} </Badge>
    }

    const handleCloseCross = (crossId: string) => {
      store.setShowModal(!store.showModal)
      setCrossIdToClose(crossId)
    }

    const handleCreateCross = () => {
      store.setShowModal(!store.showModal)
      setNewCross(true)
    }

    const handleEditCross = (crossId: string) => {
      setCrossIdToEdit(crossId)
      store.setShowModal(!store.showModal)
    }

    const handleDeleteCross = (crossId: string) => {
      setCrossToDelete(crossId)
      setIsOpen(true)
    }

    useEffect(()=>{
      if(!store.showModal) setNewCross(false)
    },[store.showModal])

    useMemo(()=>{
      setIsOpen(false)
      if(crossToDelete) delteCrossEvent(crossToDelete)
    },[ confirmDelete ])



    useEffect(()=>{
      
      setCrossToDelete(undefined)
      refetch()
    },[delteCrossSuccess])

    const cardHeader = (cross:ICross) => {
      return <div className="flex flex-row  justify-between">

          <div className="flex flex-col items-center justify-center justify-items-center content-between">
              <img
                src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team1.photo}`}
                alt={cross.team1?.name}
                className="object-cover w-10 border border-black rounded-md mb-3"
              />
              <p>{cross.team1.name}</p>
              <p>{cross.status === EventStatus.CLOSE && cross.team1Result}</p>
              
          </div>
          <div className="flex flex-col items-center justify-center justify-items-center content-between">
              <img
                src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team2.photo}`}
                alt={cross.team2?.name}
                className="object-cover w-10 border border-black rounded-md mb-3"
              />
              <p>{cross.team2.name}</p>
              <p>{cross.status === EventStatus.CLOSE && cross.team2Result}</p>

          </div>
          
          
        </div>

    }

    const crossCard = ( cross: ICross ) => {
      const momentDate = moment.utc(cross.playTime)
      return <div className="max-w-sm">
        <Card>
                
            {cardHeader(cross)}
            { statusBadge(cross) }

          <p className="font-normal text-gray-700 dark:text-gray-400">
            Fecha: { cross.playTime ? momentDate.format("DD/MM/YYYY")  : "Sin fecha definida"  }
            <br></br>
            Hora: { cross.playTime ? momentDate.format('h:mm A') : "Sin hora definida"  }
          </p>

          
          <Button onClick={()=>{ handleCloseCross(cross._id) }}>Cerrar cruce</Button>
          <Button onClick={()=>{ handleEditCross(cross._id) }}>Editar cruce</Button>
          <Button onClick={()=>{ handleDeleteCross(cross._id) }}>Borrar cruce</Button>
          
        </Card>
      </div>
    }

    
    return (
      <>
        <ConfirmDialog
         open={ isOpen }
         title= {<> <GrAlert className="mr-3" /> <span>Estas por borrar un cruce</span> </>}
         body =  {"Estás por borrar un cruce y las apuestas realizadas por los usuarios para dicho cruce."}
         buttonConfirmText = {"Borrar cruce"}
         buttonConfirmEvent = {setConfirmDelete}
         setIsOpenCallback =  { setIsOpen }
        />

        {
          crossIdToClose && <DefaultModal title="Cerrar cruce">
                            <FormCloseCross crossId={crossIdToClose} />
                          </DefaultModal>
        }

        {
          crossIdToEdit && <DefaultModal title="Editar cruce">
            <FormAddCrossesToEvent crossId={crossIdToEdit}/>
          </DefaultModal>
        }

        {
          newCross && <DefaultModal title="Crear cruce">
            <FormAddCrossesToEvent/>
          </DefaultModal>
        }


        <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"> {store.group?.name } </h1>
            <Button onClick={()=>{ handleCreateCross() }}> Crear Cruce </Button>
            <Select
                onChange={handleEventOption} 
                value={event}
                id="events"
                required={true}
            >
                  { events?.map( e => <option value={e.id as string} key={e.id as string} >{e.name}</option>) }
            </Select>

            <Button color={"failure"} onClick={()=>{setShowAll(!showAll)}}>{ !showAll ? "Mostrar Todos" : "Mostrar sin procesar"}</Button>

            
          {
            crosses.filter( (c) => { 
              if(showAll) return true
              return (!c.team1Result || !c.team2Result) && c.status === EventStatus.CLOSE
            }).map( c => crossCard(c))
          }
          
      </>
    );
  };
  
export default CrossesByEventCards;

