import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { ITeam } from '../../api/types/teams.type';
import { CreateOrUpateTeamQuery } from '../../queries/treams.query';
import useStore from '../../store';

const eventId = "63f5f82492b435262c15a14b"

type Props = {
  team?: ITeam
}

const schema = yup.object({
  name: yup.string().required(),
  photo: yup
    .mixed()
}).required();

type FormData = yup.InferType<typeof schema>;

const BASE_URL = process.env.REACT_APP_BASE_URL;

const FormTeam = (props: Props) => {
  const { team } = props;
  const store = useStore();
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [teamName, setTeamName] = useState<string>("")
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const { register, handleSubmit, setValue, setError, resetField, formState: { errors }, reset } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (team?.name) setValue("name", team.name)
    if (team?.photo) {
      const photo = `${BASE_URL}/bets/public/team-photo?photo=photo/${team.photo}`
      setPreviewImage(photo);
      setValue("photo", photo);
    }
  }, [props])

  const {
    mutate: CreateOrUpateTeamEvent,
    isSuccess
  } = CreateOrUpateTeamQuery()

  useEffect(() => {
    if (isSuccess) {
      if(team){
        setSuccessMessage(`El equipo  ${teamName} fue actualizado`)
      }else{
        setSuccessMessage(`El equipo  ${teamName} fue creado`)
        resetField('name');
        resetField('photo');
        setPreviewImage(null)
      }
      
    }
  }, [isSuccess])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    setTeamName(values.name)
    if (!team) {
      if (!values.photo) {
        setError("photo", { type: "manual", message: "La bandera del equipo es requerida" });
        setHasError(true);
        return;
      }
    }

    const upload = values.photo && values.photo[0];

    const params = {
      id: team?.id,
      name: values.name,
      events: [eventId]
    }

    CreateOrUpateTeamEvent({ params, upload });
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} encType="multipart/form-data">
      <Label
        htmlFor="name"
        value={"Nombre del equipo: "}
      />
      <TextInput
        {...register("name")}
      />

      <p>{errors.name?.message}</p>

      <input
        placeholder="Bandera"
        type="file" {...register("photo")}
        accept=".png, .jpg, .jpeg"
        onChange={handleFileChange}
      />
      {previewImage && (
        <img src={previewImage} alt="Preview" className="w-[200px] mt-2" />
      )}
      {/* @ts-ignore */}
      {errors.photo && <p className="text-red-500">{errors.photo.message}</p>}
      {hasError && <span>Se produjo un error en el formulario</span>}

      {successMessage && (
        <Alert color="success">
          <p className="font-medium">
            {successMessage}
          </p>
        </Alert>
      )}

      <Button type="submit">
        {team ? "Actualizar equipo" : "Crear Equipo"}
      </Button>
    </form>
  );
};

export default FormTeam;
