import { API_ENDPOINTS } from '../enums/api.enum';
import { authApi } from '.';
import { IGroup, IGroupAdd } from './types/group.type';


export const createGroupApi = async (group:IGroupAdd) => {
  const response = await authApi.post<IGroupAdd>(API_ENDPOINTS.CREATE_GROUP, group);
  return response.data;
};

export const getAllGroupsApi = async () => {
    const response = await authApi.get<IGroup[]>(API_ENDPOINTS.GET_ALL_GROUPS);
    return response.data;
};

export const getAllGroupsByEventApi = async ({eventId}:{eventId:string}) => {
    const response = await authApi.get<IGroup[]>(`${API_ENDPOINTS.GET_ALL_GROUPOS_BY_EVENT}?id=${eventId}`);
    return response.data;
};

export const getGroupDetailApi = async ({groupId}:{groupId:string}) => {
  const response = await authApi.get<IGroup>(`${API_ENDPOINTS.GET_GROUP_DETAILS}?id=${groupId}`);
  return response.data;
};


  