import { Card } from "flowbite-react";
import CrossesCards from "../../components/CrossesCards";
import Body from "../../components/Body";

const CrossesByGroupPage = () => {
    return (
      <Body title={"Cruces por grupo"}>
            <Card>
            <CrossesCards/>
            </Card>
        </Body>
    );
  };
  
export default CrossesByGroupPage;
