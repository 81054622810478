import BackButton from "./Buttons/BackButtons";

type BodyProps = {
  children: React.ReactNode;
  title: string;
};

const Body = (props: BodyProps) => {
  return (
    <section className="min-h-screen pt-20">
      <div className="max-w-4xl mx-auto rounded-md flex flex-col justify-center items-center">
        <div className="flex flex-raw justify-center items-center align-middle">
          <BackButton/> <h1 className="text-2xl text-gray-900">  {props.title}</h1>
        </div>
        
        <br></br>
        {props.children}
      </div>
    </section>
  );
};

export default Body;
