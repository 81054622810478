import { useEffect, useState } from "react";
import { UserRolesEnum } from "../router/enums/userRoles.enum";
import useStore from "../store";


export function useIsAdmin(): boolean {
  const store = useStore();
  const user = store.authUser;
  const userRoles = user?.roles;
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (userRoles) {
      const isAdminUser = userRoles.includes(UserRolesEnum.ADMIN);
      setIsAdmin(isAdminUser);
    }
  }, [userRoles]);

  return isAdmin;
}
