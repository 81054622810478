import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableOptions, useTable } from "react-table";
import { IGroup } from "../api/types/group.type";
import { IEvent } from "../interfaces/event.interfaces";
import { GetAllGroupsQuery } from "../queries/groups.query";
import { RoutesEnum } from "../router/enums/routes.enum";
import useStore from "../store";
import FormCreateGroup from "./Forms/FormCreateGroup";
import DefaultModal from "./Modal/Modal";

interface IGroupsTable extends IGroup {
  actions?: string;
}

const GroupsTable = () => {
  const [groups, setGroups] = useState<IGroupsTable[]>([]);
  const [newGroup, setNewGroup] = useState<boolean>(false);
  const navigate = useNavigate();
  const store = useStore();

  useEffect(() => {
    setGroups(store.groups || []);
  }, [store.groups]);

  const eventsId: string[] = [];
  const eventsUnique: IEvent[] = [];
  store.groups
    ?.map((g) => g.event)
    .forEach((e) => {
      if (!eventsId.includes(e._id)) eventsUnique.push(e);
    });

  let data = React.useMemo((): IGroupsTable[] => groups ?? [], [groups]);

  GetAllGroupsQuery({ store });

  const columns = React.useMemo<Column<IGroupsTable>[]>(
    () => [
      {
        Header: "Nombre",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: ({ cell }) => (
          <>
            <button
              className="blueButton"
              onClick={() => handleOnClickEdit(cell.row.original._id ?? "")}
            >
              Editar
            </button>
            <button
              className="blueButton"
              onClick={() => handleOnClickCrosses(cell.row.original._id ?? "")}
            >
              Ver Cruces
            </button>
          </>
        ),
      },
    ],
    []
  );

  const options: TableOptions<IGroupsTable> = {
    data,
    columns,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  const handleOnClickEdit = (groupId: String) => {
    
  };

  const handleOnClickCrosses = (groupId: string) => {
    navigate(`${RoutesEnum.GET_CROSSES_BY_GROUP}/${groupId}`);
  };

  const handleCreateGroup = () => {
    setNewGroup(true)
    store.setShowModal(!store.showModal)
  }

  useEffect(()=>{
    !store.showModal && newGroup && setNewGroup(false)
  },[store.showModal])

  return (
    <>
        {
          store.showModal && newGroup && <DefaultModal title="Crear grupo">
                            <FormCreateGroup/>
                          </DefaultModal>
        }
        
      <Button className="mx-auto max-w-max" onClick={handleCreateGroup} > Crear grupo </Button>

      <table {...getTableProps()} className="w-">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default GroupsTable;
