// Libraries
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
// Components
import { Disclosure, Menu, Transition } from "@headlessui/react";
import Container from "../Container/Container";
import { MenuIcon, XMark } from "../Icons";
// Other shit
import { classNames } from "../../helpers";
import { ResendEmailConfirmationQuery } from "../../queries/user.query";
import { RoutesEnum } from "../../router/enums/routes.enum";
import { UserRolesEnum } from "../../router/enums/userRoles.enum";

const navigation = [
  { name: "Predicciones", href: "/predictions" },
  { name: "¿Cómo jugar?", href: "/how-to-play" },
  { name: "Tabla Liga Fántastica", href: "/league/63f5f82492b435262c15a14b" },
  {
    name: "Mis Ligas",
    href: "/custom-league/63f5f82492b435262c15a14b",
  },
  { name: "Resultados", href: "/results" },
  { name: "Premios", href: "/prizes" },
];

const Header = () => {
  const navigate = useNavigate();
  const store = useStore();
  const user = store.authUser;
  const {
    mutate: requestResendUserConfirmation,
    isSuccess: isSucccessRequestResendUserConfirmation,
  } = ResendEmailConfirmationQuery();
  const userImg = `https://api.dicebear.com/5.x/initials/svg?seed=${user?.name}`;
  const isAdmin = user?.roles.includes(UserRolesEnum.ADMIN);
  const isViewer = user?.roles.includes(UserRolesEnum.VIEWER);

  const handleLogout = () => {
    store.reset();
    navigate(`${RoutesEnum.LOGIN}`);
  };

  const emaiLPendingConfirmNav = () => {
    return (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full px-3 py-1 bg-claro-1000">
        {isSucccessRequestResendUserConfirmation ? (
          <span className="mr-2 text-xs font-medium text-white">
            Correo enviado, revisá tu bandeja de SPAM
          </span>
        ) : (
          <>
            <span className="mr-2 text-xs font-medium text-white">
              Tu cuenta no esta verificada
            </span>
            <button
              onClick={handleSendEmailConfirmation}
              className="px-3 py-1 text-xs bg-white rounded-lg text-claro-1000 hover:bg-claro-1000 hover:text-white"
            >
              Enviar correo de verificación
            </button>
          </>
        )}
      </div>
    );
  };

  const handleSendEmailConfirmation = () => {
    if (user) requestResendUserConfirmation(user?.email);
  };

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          {!user?.emailConfirmed && emaiLPendingConfirmNav()}

          <Container className="px-5 py-5 md:px-5 md:py-6 lg:px-0">
            <div
              className={`flex items-center justify-between h-16 ${
                !user?.emailConfirmed ? "mt-12" : ""
              }`}
            >
              <div className="flex-shrink-0">
                <a
                  href={
                    isAdmin || isViewer
                      ? RoutesEnum.ADMIN_LANDING
                      : RoutesEnum.HOME
                  }
                >
                  <img className="h-16" src="/logo-black.png" alt="Claro" />
                </a>
              </div>
              <div className="flex items-center">
                <div className="hidden md:block">
                  <div className="grid items-baseline grid-cols-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          "text-black hover:text-claro-600 transition hover:no-underline",
                          "lg:px-3 lg:py-2 rounded-md font-medium text-sm lg:text-base"
                        )}
                      >
                        {item.name}
                      </a>
                    ))}

                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="flex items-center ml-4 md:ml-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex items-center max-w-xs text-black rounded-full bg-claro-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="w-8 h-8 rounded-full"
                          src={userImg}
                          alt={user?.name}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <a
                            href={"/profile"}
                            className={classNames(
                              "block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:no-underline"
                            )}
                          >
                            Tu perfil
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <a
                            href="#"
                            onClick={handleLogout}
                            className={classNames(
                              "block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:no-underline"
                            )}
                          >
                            Cerrar Sessión
                          </a>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="flex -mr-2 md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 bg-white border-2 rounded-md text-claro-700 border-claro-700 hover:bg-claro-700 hover:bg-opacity-75 hover:text-black focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-claro-700">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMark className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </Container>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    "text-black hover:text-claro-600 transition hover:no-underline",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-claro-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={userImg}
                    alt={user?.name}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-black">
                    {user?.name}
                  </div>
                  <div className="text-sm font-medium text-teal">
                    {user?.email}
                  </div>
                </div>
              </div>
              <div className="px-2 mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href={"/profile"}
                  className="block px-3 py-2 text-base font-medium text-black transition rounded-md hover:text-claro-600 hover:no-underline"
                >
                  Tu perfil
                </Disclosure.Button>
                <Disclosure.Button
                  as="button"
                  onClick={handleLogout}
                  className="block px-3 py-2 text-base font-medium text-black transition rounded-md hover:text-claro-600 hover:no-underline"
                >
                  Cerrar Sessión
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
