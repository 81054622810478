import { Card } from "flowbite-react";
import Body from "../../components/Body";
import FormAddCrossesTuEvent from "../../components/Forms/FormAddCrosses";
import FormAddTeamToEvent from "../../components/Forms/FormAddTeamToEvent";
import EditEventForm from "../../components/Forms/FormEventEdit";


const EventEditPage = () => {
    
    return (
      <Body title={"Editar evento"}>
            <Card>
              <EditEventForm/>   
              <FormAddTeamToEvent/>   
              <FormAddCrossesTuEvent/>
            </Card>
        </Body>
    );
  };
  
export default EventEditPage;
