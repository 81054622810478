import { Button, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IBets } from "../api/types/bets.type";
import { ICross } from "../api/types/cross.type";
import { IUser } from "../api/types/types";
import { Container, TeamVS } from "../components";
import Answers from "../components/Answers/Answers";
import { GetCrossesByEventQuery } from "../queries/crosses.query";
import { GetUserProfile } from "../queries/user.query";
import { UserRolesEnum } from "../router/enums/userRoles.enum";
import useStore from "../store";

const UserPredictions = () => {
  const navigate = useNavigate();
  let { userId } = useParams();
  const store = useStore();
  const [event, setEvent] = useState<string>("63f5f82492b435262c15a14b");
  const [crosses, setCrosses] = useState<ICross[]>([]);
  const [bets, setBets] = useState<IBets[]>([]);
  const { data, refetch: refechUserProfile} = GetUserProfile({userId})
  const [ user, setUser ] = useState<IUser>()
  const [ isAdmin, setIsAdmin ] = useState<boolean | undefined >(
    store.authUser?.roles?.includes(UserRolesEnum.ADMIN) || store.authUser?.roles?.includes(UserRolesEnum.VIEWER)
  )
  
  const { refetch: refetchCrosses, isLoading, isFetching: isFetchingCrosses } = GetCrossesByEventQuery({
    store,
    eventId: event,
    enabled: false,
  });


  useEffect(() => {
    setCrosses(store.crosses || []);
  }, [store.crosses]);

  useEffect(() => {
    if (event) {
      refetchCrosses();
    }
  }, [event]);

  useEffect(() => {
    if (userId) {
      refechUserProfile();
    }
    
  }, [userId]);

  useEffect(()=>{
    setUser(data)
  }, [data])

  const closeedCrosses = crosses.filter((c) => c.status === "CLOSE");

  const crossesList = () => {
    return closeedCrosses.map((c, cindex) => {
      return (
        <TeamVS key={cindex} cross={c} userId={userId} >
          <Answers cross={c} bets={bets} userId={userId}/>
        </TeamVS>
      );
    });
    
  };

  const handleBackButton = () => {
    navigate(-1);
    
  }

  return (
    <Container className="flex gap-4 px-5 py-5 md:py-12 flex flex-col justify-center gap-12 py-0 md:gap-8 md:pb-20">
      <Button className="max-w-fit" color="failure" onClick={handleBackButton}>
        Volver
      </Button>
      <div className="mt-3">
          <h3>Historial del usuario:</h3>
          <h3>{user?.name }, {user?.lastName} { isAdmin ? `( ${user?.email} )` : ""  } </h3>
      </div>
      
      {
        isFetchingCrosses
          ? <div className="w-full h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
          <Spinner className="mt-10"/>
          Cargando datos
        </div>
        :
        
        
          <div className="grid grid-cols-1 gap-12 mt-4 md:grid-cols-2">
              { closeedCrosses.length > 0 ? 
                crossesList():
                "No hay cruces para mostrar"
              }
          </div>
        

      }
          
    </Container>
  );
};

export default UserPredictions;
