import React from "react";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode;
}

export const RedButton: React.FC<Props> = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <button
      type="submit"
      className= "bg-claro-1000 text-white w-min-content py-3 font-semibold rounded-lg outline-none border-none flex justify-center transition mt-2 hover:bg-claro-800 hover:text-white px-4 border border-claro-500 hover:border-transparent"
      {...rest}
    >
      {children}
    </button>
  );
};
