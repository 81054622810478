import { useNavigate } from "react-router-dom";
import { Container } from "../../components";
import { GetProcessEventScoreApi } from "../../queries/events.query";
import { RoutesEnum } from "../../router/enums/routes.enum";
import { RedButton } from "../Buttons/RedButton";
import { LoadingButton } from "../LoadingButton";
const eventId = "63f5f82492b435262c15a14b"
const AdminButtonBox = () => {
  const navigate = useNavigate();

  const {
    refetch: refetchGetProcessEventScoreApi,
    isFetching: isFetchingGetProcessEventScoreApi
  } = GetProcessEventScoreApi({
    eventId,
    enabled: false,
  });
  
  const redirect = (ruta:string) => {
    navigate(ruta);
  };
  
  return (
    <Container className="m-0">
      <div className="flex flex-col md:flex-row items-center p-5 space-x-2">
        <RedButton onClick={() => redirect(RoutesEnum.ADMIN_LANDING)}>Metricas</RedButton>
        <RedButton onClick={() => redirect(RoutesEnum.RANKING)}>Ranking</RedButton>
        <RedButton onClick={() => redirect(RoutesEnum.GET_ALL_GROUPS)} > Grupos </RedButton>
        <RedButton onClick={() => redirect(`${RoutesEnum.CROSSES_BY_EVENT}/${eventId}`)} > Cruces </RedButton>
        <RedButton onClick={() => redirect(`${RoutesEnum.ADMIN_EDIT_USER}`)} > Usuarios </RedButton>
        <RedButton onClick={() => redirect(`${RoutesEnum.TEAMS_LIST}`)} > Equipos </RedButton>
        <div className="max-w-25">
        <LoadingButton
              loading={isFetchingGetProcessEventScoreApi}
              onClick={()=>{refetchGetProcessEventScoreApi()}}
            >
             Procesar resultados
            </LoadingButton>
        </div>
        
      </div>
    </Container>
  );
};

export default AdminButtonBox;
