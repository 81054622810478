import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Label, Spinner, TextInput } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ICross } from "../../api/types/cross.type";
import { CheckCrossQuestions, CloseCrossQuery, GetCrossesByIdQuery } from "../../queries/crosses.query";
import { RoutesEnum } from "../../router/enums/routes.enum";
import useStore from "../../store";
import AnswersAdmin from "./../Answers/AnswersAdmin";
import { LoadingButton } from "./../LoadingButton";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const schema = yup
  .object({
    team1Result: yup.number().required(),
    team2Result: yup.number().required(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type Props = {
  crossId: string
}

const FormCloseCross = (props: Props) => {
  const { crossId } = props
  const navigate = useNavigate();
  const store = useStore();
  const [cross, setCross] = useState<ICross | null>();
  const [getingCrossing, setGetingCrossing] = useState<boolean>(true)
  const [errorAllAnswers, setErrorAllAnswers] =  useState<string | undefined>()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const team1Result = watch("team1Result");
  const team2Result = watch("team2Result");
  
  let refetchCheckCrossQuestions: any

  if (crossId){
    const { isLoading } = GetCrossesByIdQuery({ crossId, store, enabled: true });
    let { refetch: _refetchCheckCrossQuestions, data: dataCheckCrossQuestions } = CheckCrossQuestions({ crossId, enabled: false });
    
    refetchCheckCrossQuestions = _refetchCheckCrossQuestions

    if(isLoading !== getingCrossing) 
      setGetingCrossing(isLoading)
  }

  const { mutate: registerEvent, data, isLoading } = CloseCrossQuery();


  useMemo(() => {
    if (!crossId) navigate(`${RoutesEnum.EVENTES_LIST}`);
  }, [crossId]);

  useEffect(() => {
    if (store.cross && store.cross._id === crossId) {
      setCross(store.cross);
    }
  }, [store.cross]);

  useEffect(() => {
    if (data){
      setCross(data);
      setValue("team1Result", Number(data.team1Result) || 0)
      setValue("team2Result", Number(data?.team2Result) || 0)
    }
  }, [data]);

  const onSubmitHandler: SubmitHandler<FormData> = async (values) => {
    
      const resp = await refetchCheckCrossQuestions()
      if(resp.data){
        setErrorAllAnswers(undefined)
        registerEvent({
          crossId: crossId || "",
          team1Result: values.team1Result,
          team2Result: values.team2Result,
        });
      }else{
        setErrorAllAnswers("Todas las preguntas deben ser respondidas.")
      }
  };

  const showResultSaved = () => {
    return (
      <div className="flex flex-col items-center mt-4">
        <div className="flex items-start justify-around w-full">
          <img
            src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross?.team1.photo}`}
            alt={cross?.team1?.name}
            className="object-cover w-10 h-full border border-black rounded-md"
          />
          {cross?.team1Result ?? " ... "}-{cross?.team2Result ?? " ... "}
          <img
            src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross?.team2.photo}`}
            alt={cross?.team2?.name}
            className="object-cover w-10 h-full border border-black rounded-md"
          />
        </div>
      </div>
    );
  };

  const showLoading = () => {
    return <div className="w-full h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
    <Spinner className="mt-10"/>
    Cargando datos
  </div>
  }

  return (
    <>
     
        { getingCrossing ? showLoading() : 
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmitHandler)}
        >

          {cross ? showResultSaved() : ""}

    
          <p>{errors.team1Result?.message}</p>

          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col items-center justify-between">
              <Label
                htmlFor="team1Result"
                value={`Puntaje ${cross?.team1.name}: `}
              />

              <TextInput
                {...register("team1Result")}
                defaultValue={cross?.team1Result}
                className={"w-20"}
              />
              </div>
              
              <div className="flex flex-col items-center justify-between">
                  <Label
                    htmlFor="team2Result"
                    value={`Puntaje ${cross?.team2.name}: `}
                  />

                  <TextInput
                    {...register("team2Result")}
                    defaultValue={cross?.team2Result}
                    className={"w-20"}
                  />
              </div>
          </div>
        
          <p>{errors.team2Result?.message}</p>
          <h3>Marque la respuesta correcta</h3>

          { cross && <AnswersAdmin cross={cross} team1Result={Number(team1Result)} team2Result={Number(team2Result)}/>}

          { errorAllAnswers && 
              <Alert
              color="failure"
              icon={HiInformationCircle}
            >
              <span>
                {errorAllAnswers}
              </span>
            </Alert>
          }
          
          <LoadingButton
                loading={isLoading}
                btnColor={
                  "bg-green-500 hover:bg-green-500" 
                }
                disabled={isLoading}
              >
                { !isLoading && "Actualizar Cierre del Cruce"}
              </LoadingButton>
          {/* <Button type="submit"></Button> */}
        </form>
      }
    </>
  );
};

export default FormCloseCross;
