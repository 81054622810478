export enum RoutesEnum {
  ADMIN_LANDING = "/admin-landing",
  ADMIN_EDIT_USER = "/admin/edit-user",
  LANDING = "/landing",
  LANDING_B = "/landingb",
  LOGIN = "/login",
  HOME = "/home",
  PROFILE = "/profile",
  PROFILE_PASSWORD = "/profile/password-reset",
  REGISTER = "/register",
  RESET = "/reset",
  // Regular User
  PREDICTIONS = "/predictions",
  HOW_TO_PLAY = "/how-to-play",
  LEAGUE = "/league",
  RESULTS = "/results",
  PRIZES = "/prizes",
  CUSTOM_LEAGUES = "/custom-league",
  // Admin
  RANKING = "/admin/ranking",
  EVENTES_LIST = "/events-list",
  EVENT_DETAILS = "/event-details",
  EVENTS_CREATE = "/events-create",
  DELETE_CROSS = "/delete-cross",
  EVENT_EDIT = "/event-edit",
  TEAMS_LIST = "/teams-list",
  TEAMS_CREATE = "/teams-create",
  TEAMS_EDIT = "/teams-edit",
  CROSSES_BY_EVENT = "/crosses-by-event",
  GET_CROSSES_BY_GROUP = "/crosses-by-group",
  CREATE_OR_UPDATE_CROSSS = "/create-cross",
  CREATE_FIELD = "/create-field",
  GET_FIELDS_BY_EVENT = "/get-field-by-event",
  GET_FIELDS_BY_CROSS = "/get-field-by-cross",
  CREATE_GROUP = "/group-create",
  GET_ALL_GROUPS = "/groups-list",
  GET_ALL_EVENT_GROUPS = "/groups-list-by-event",
  USER_EVENT_LIST = "/user/event-list",
  USER_EVENT_DETAILS = "/user/event-details",
  CROSS_CLOSE = "/cross-close",
  USER_PREDICTIONS = "/user-predictions"
}
