import { zodResolver } from "@hookform/resolvers/zod";
import { Badge, Button } from "flowbite-react";
import moment from 'moment';
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TypeOf } from "zod";

import { IUser, IUserUpdate } from "../../api/types/types";
import { RequestResetUserPasswordQuery, ResendEmailConfirmationQuery, UpdateUserQuery } from "../../queries/user.query";
import { GetUserLoginHistoryQuery } from "../../queries/users-login-history.query";
import { UserRolesEnum } from "../../router/enums/userRoles.enum";
import { updateUserSchema } from "../../schemas";
import useStore from "../../store";
import { LoadingButton } from "./../LoadingButton";
import FormInput from "./FormInput";
import FormToggle from "./FormToggle";

type EditUserFormProps = {
    user: IUser
}

export type UpdateUserForm = TypeOf<typeof updateUserSchema>;

const EditUserForm = (props: EditUserFormProps) => {
    const { user } = props;
    const [isCostumer, setIsCostumber] = useState<boolean>(false)
    const lastLoginDate = moment.utc(user.lastLogin)
    const { mutate: updateUser, isSuccess, isError, error, isLoading } = UpdateUserQuery();
    const { mutate: requestUserPassword, isSuccess: isSuccessRequestUserPassword  } = RequestResetUserPasswordQuery()
    const { mutate: requestResendUserConfirmation, isSuccess: isSucccessRequestResendUserConfirmation  } = ResendEmailConfirmationQuery()
    const store = useStore()
    const isAdmin = store.authUser?.roles.includes(UserRolesEnum.ADMIN)
    const { data: dataUserLoginHistory } = GetUserLoginHistoryQuery({ userId: user.id, enabled:true });

    useEffect(() => {
      reset(props.user);
      setIsCostumber(props?.user?.isCostumer ?? false)
    }, [props.user]);

    const methods = useForm<UpdateUserForm>({
        resolver: zodResolver(updateUserSchema),
        defaultValues: useMemo(() => {
          setIsCostumber(props?.user?.isCostumer ?? false)
          return props.user;
        }, [props]),
      });

    const {
        handleSubmit,
        formState: { errors },
        reset
      } = methods;

      const onSubmitUpdateUserHandler: SubmitHandler<UpdateUserForm> = (values) => { 

        const dataUpdate : IUserUpdate = { 
          ...values,
          isCostumer,
          id: user.id,
        }

        updateUser(dataUpdate)
        
      };

      const handleSendNewPassword = () => {
        const email = user.email
        requestUserPassword(email);
      }

      const handleSenEmailConfirmation = () => {
        const email = user.email
        requestResendUserConfirmation(email);
      }

      const LoginHistoryPanel = () => {
        return <ul>
          {dataUserLoginHistory?.slice(0,10).map( (h:any) => <li> {  moment.utc(h.createdAt).format("DD/MM/YYYY h:mm A") } </li> )}
        </ul>
      
      }
    
    return (
      <div>
                <h3> Editar usuario: {user?.email} </h3>
                <div>Último inicio de sesion: {lastLoginDate.format("DD/MM/YYYY")} a las {lastLoginDate.format('h:mm A')}</div>
                <br/>
                <div>
                  Últimos 10 inicios de sesión:
                  <LoginHistoryPanel/> 
                </div>
                <br/>
                <div className="max-w-fit"> Estado del email: {
                  user.emailConfirmed ?
                  <Badge color="success"> CONFIRMADO </Badge>
                        : <Badge color="failure"> SIN CONFIRMAR </Badge>
                        }
                  </div>
                <div>

                </div>
                { isSucccessRequestResendUserConfirmation ? "Correo enviado" : ""}
                { 
                  !user.emailConfirmed ?
                    <><br/><Button onClick={handleSenEmailConfirmation}>Enviar mail de confirmacion</Button></> : ""
                }
                
                { isSuccessRequestUserPassword ? "Correo enviado" : ""}
                <br/>
                { isAdmin && <Button onClick={handleSendNewPassword}>Enviar nueva contraseña</Button> }
                
                <br/>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmitUpdateUserHandler)}
                        className="flex flex-col w-full gap-3 px-4 md:px-8"
                    >
                        <FormInput
                        label="Nombre"
                        placeholder="Aaron"
                        name="name"
                        type="text"
                        disabled={!isAdmin}
                        />
                        <FormInput
                        label="Apellido"
                        placeholder="Judge"
                        name="lastName"
                        type="text"
                        disabled={!isAdmin}
                        />
                        <FormInput
                        label="Teléfono"
                        placeholder="Teléfono"
                        name="phoneNumber"
                        type="text"
                        disabled={!isAdmin}
                        />
                        <FormToggle
                          label="¿El ususario es un cliente Claro?"
                          setIsCostumber={setIsCostumber}
                          defaultValue={user.isCostumer ? 1 : 0}
                          disabled={!isAdmin}
                        />

                        {isSuccess && (
                        <>
                            Usuario actualizado {" "}
                        </>
                        )}

                        { isAdmin && 
                          <LoadingButton
                          loading={isLoading}
                          textColor="text-white"
                          disabled={!isAdmin}
                          >
                          Editar usuario
                          </LoadingButton>
                        }
                        
                        <div className="text-right">
                        </div>
                    </form>
                    </FormProvider>
            </div>
      
    );
  };
  
export default EditUserForm;
