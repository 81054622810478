import { Container } from "../components";

const prices = [
  // "/premios/premio1.png",
  "/premios/premio2.png",
  "/premios/premio3.png"
];

const Prizes = () => {
  return (
    <Container className="flex flex-col items-center gap-6 px-5 py-5 md:py-12 lg:px-0">
      {prices.map((image, imgx) => (
        <img className="w-full md:w-1/2" src={image} alt={imgx.toString()} />
      ))}
    </Container>
  );
};

export default Prizes;
