import useStore from "../store";
import { useParams } from "react-router-dom";
import { Badge, Card } from "flowbite-react";
import { ICross } from "../api/types/cross.type";
import { EventStatus } from "../interfaces/event.interfaces";
import { GetGroupDetailsQuery } from "../queries/groups.query";
import moment from 'moment'

const CrossesCards = () => {
    const { groupId } = useParams();

    const store = useStore();
    
    if(groupId){
        GetGroupDetailsQuery({ store, groupId })
    }
    
    const crosses: ICross[] = store.group?.crosses || []

    const statusBadge = (status: EventStatus | undefined) => {
        return status === EventStatus.OPEN ? 
            <Badge color="success"> ABIERTO </Badge>
            : <Badge color="failure"> CERRADO </Badge>
    }

    const crossCard = ( crosss: ICross ) => {
      const momentDate = moment.utc(crosss.playTime)
      return <div className="max-w-sm">
        <Card>
            
                
          <h5 className="text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
            {crosss.team1.name} vs { crosss.team2.name}
          </h5>
          
          { statusBadge(crosss.status) }

          <p className="font-normal text-gray-700 dark:text-gray-400">
            Fecha: { crosss.playTime ? momentDate.format("DD/MM/YYYY") : "Sin fecha definida"  }
            <br></br>
            Hora: { crosss.playTime ? momentDate.format('h:mm A') : "Sin hora definida"  }
          </p>
        </Card>
      </div>
    }

    return (
      <>
        <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"> {store.group?.name } </h1>
          {
            crosses.map( c => crossCard(c))
          }
          
      </>
    );
  };
  
export default CrossesCards;
