import { RadioGroup } from "@headlessui/react";
import { FC, useEffect, useState } from "react";
import { IOptions, IResults } from "../../api/types/fields.type";
import { classNames } from "../../helpers";
import { UpdateFieldResultsQuery } from "../../queries/fields.query";
import { AnswerProps } from "./types";

const AnswerAdmin: FC<AnswerProps> = (props) => {
  const {
    question,
    answers,
    questionId,
    correctOption,
  } = props;
  
  const [selectedAnswers, setSelectedAnswers] = useState<IOptions>();
  
  const { mutate: registerEvent, data } = UpdateFieldResultsQuery();

  const handlSubmitAnswer = (optionSelected: IOptions) => {
    setSelectedAnswers(optionSelected);

    const result: IResults = {
      fieldId: questionId,
      correctOption: optionSelected.uuid || "",
    };

    registerEvent(result);
  };

  useEffect(() => {
    const check = answers.find((a) => {
      return a.uuid === correctOption;
    });
    if (check) setSelectedAnswers(check);
  }, [correctOption]);

  const classNameGenerator = ({active, checked, answer}:{active:any, checked:any, answer:any}) => {
    checked = selectedAnswers?.uuid === answer.uuid
    
    return classNames(
      "cursor-pointer focus:outline-none ",
      active ? "ring-2 ring-offset-2 ring-claro-500" : "",
      checked
        ? "bg-green-600 border-transparent text-white hover:bg-green-600"
        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",

      "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 whitespace-nowrap",
      answer.uuid === correctOption && checked
        ? "bg-green-600 hover:bg-green-600"
        : ""
    )
  }
  
  
  return (
    <div>
      <p className="text-center">{question}</p>
      <div className="flex justify-center">
        <RadioGroup
          value={selectedAnswers}
          onChange={(optionSelected: IOptions) => {
            handlSubmitAnswer(optionSelected);
          }}
          className="mt-2"
        >
          <RadioGroup.Label className="sr-only">
            {" "}
            Choose a memory option{" "}
          </RadioGroup.Label>
          <div className="flex items-center gap-3">
            {answers.map((answer) => (
              <RadioGroup.Option
                key={answer.uuid}
                value={answer}
                className={({ active, checked }) =>
                  classNameGenerator({active, checked, answer})
                }
              >
                <RadioGroup.Label as="span">{answer.option}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default AnswerAdmin;
