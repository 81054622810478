import { Spinner } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EventScores, IEventScore, Stats } from "../api/types/events.type";
import { IUsersRankings } from "../api/types/users-rankings.types";
import { Container, PredictionsTable, Tabs } from "../components";
import CreateCustomRanking from "../components/CustomRanking/CreateCustomRanking";
import { UserPointsProps } from "../components/Table/types";
import { Tab } from "../components/Tabs/initTabs";
import { LiguesENUM, RankingGroupsENUM, RankingsENUM } from "../enums/rankings.enum";
import {
  predictions
} from "../mockData/predictionsTable";
import {
  GetEventScoreQuery,
  GetEventStatsQuery
} from "../queries/events.query";
import { GetRankingsQuery } from "../queries/users-ranskings.query";

import useStore from "../store";

const stats: Stats[] = [
  {
    name: "Partidos jugados",
    from: 0,
    total: 0,
    key: "game_played",
  },
  {
    name: "Resultados Correctos",
    from: 0,
    total: 0,
    key: "hits",
  },
];


const CustomLeaguesPage = () => {
  const { eventId } = useParams();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const [playersScore, setPlayersScore] = useState<UserPointsProps[]>([]);
  const [userScore, setUserScore] = useState<number>(0);
  const [maxRank, setMaxrank] = useState<number>(1);
  const [userRanking, setUserRanking] = useState<number>(1);
  const [userPosition, setUserPosition] = useState<number>(0);
  const [eventStats, setEventStats] = useState<Stats[]>(stats);
  const [rankings, setRankings] = useState<IUsersRankings[]>();
  const store = useStore();
  
  
  const { refetch, data, isLoading } = GetEventScoreQuery({
    enabled: false,
    eventId: "63f5f82492b435262c15a14b",
  });
  const { refetch: eventStatsRefetch, data: dataStats } = GetEventStatsQuery({
    enabled: false,
    eventId: "63f5f82492b435262c15a14b",
  });

  const { data: dataRankings, refetch: refetchGetRanking, isFetched } = GetRankingsQuery({
    enabled: true,
  });

  useEffect(()=>{
    if(dataRankings){
      setRankings(dataRankings)
      const newTabs = dataRankings.map( (r:any) => {
        return {
          name: r.name,  
          group: RankingsENUM.CUSTOM_RANKING,
          code: r.code
        }
      })

      if(newTabs.length > 0){
        setTabs([...newTabs]);
        setSelectedTab(newTabs[0].name)
      }
      
    }
  },[dataRankings])

  
  useEffect(() => {
    if (eventId) {
      refetch();
      eventStatsRefetch();
    }
  }, [eventId]);

  const calculateTotalScore = ({scores}:{scores:EventScores}) => {
    const totalScores = { score: 0, hits: 0, simpleHits: 0, doubleHits: 0 };

    for (const roundKey in scores) {
      if(roundKey !== RankingGroupsENUM.ALL){
        const round = scores[roundKey];
        totalScores.score += round.score;
        totalScores.hits += round.hits;
        totalScores.simpleHits += round.simpleHits;
        totalScores.doubleHits += round.doubleHits;
      }
    }
    
    return totalScores
  }

  const findGroupByName = (tabs: Tab[], name: string | undefined): string => {
    const foundTab = tabs.find(tab => tab.name === name);
    return foundTab?.group || RankingGroupsENUM.ALL;
  }

  const processEventScore = (data: IEventScore[]) => {
    let usersRanking: any
    const tab = tabs.find( tab => tab.name === selectedTab);
    
    if(!tab) return

    if (tab.group === RankingsENUM.CUSTOM_RANKING){
      const customRanking = rankings?.find( (ranking) => {
        return ranking.code === tab.code 
      })
      
      usersRanking = customRanking?.members.map( (m:any) => m._id)
    }

    let playersScore = data
      .filter((d: IEventScore) => {

        if(tab.name === LiguesENUM.VISITANTE){
          return !d.user?.isCostumer;
        }

        if(tab.name === LiguesENUM.LOCAL){
          return d.user?.isCostumer;
        }

        if (tab.group === RankingGroupsENUM.ALL){
          return true
        }

        if (tab.group === RankingsENUM.CUSTOM_RANKING){
          return usersRanking.includes(d.user._id) 
        }
        console.log("Error filter by default user in ranking table")
        return true
        
      })
      .map((d: IEventScore, index: number) => {
        d.scores["ALL"] = calculateTotalScore(d)
        const phaseScore = d.scores[RankingGroupsENUM.ALL]
        setUserScore(phaseScore.score)
        const data = {
          user: d.user,
          score: phaseScore.score,
          doubleHits: phaseScore.doubleHits,
          hits: phaseScore.hits < 0 ? 0 :  phaseScore.hits,
          simpleHits: phaseScore.simpleHits < 0 ? 0 :  phaseScore.simpleHits,
          global: phaseScore.score,
          ligue: d.user?.isCostumer ? "Liga local" : "Liga visitante",
          isCostumer: d.user?.isCostumer,
        };

        return data;
      })
      
      // Sort BY
      //1. Score (points1), 2.hits (points2), 3.simpleHits (points4)

      const playersScoreOrdered = playersScore.sort((a, b) => {
        if (a.score !== b.score) {
          return b.score - a.score;
        } else if (b.doubleHits !== a.doubleHits) {
          return b.doubleHits - a.doubleHits;
        } else if (b.hits !== a.hits) {
          return b.hits - a.hits;
        } else {
          return b.global - a.global;
        }
      });

      let currentRank = 1
      let userRanking = 1
      
      const playersScoreProcess = playersScoreOrdered.map( (d: UserPointsProps, index: number)=>{
        if(index ===  0){
          d.ranking = currentRank
          d.rankingLabel = currentRank.toString()
          return d
        }
        const previousD = playersScore[index - 1] 
        const previousUserScoring =  
          previousD.score +
          previousD.doubleHits +
          previousD.simpleHits +
          previousD.hits +
          previousD.global
        
        const currentUserScoring = 
          d.score +
          d.doubleHits +
          d.simpleHits +
          d.hits +
          d.global

        if (index > 0 && previousUserScoring !== currentUserScoring) {
          currentRank += 1;
        } 

        if(  d.user._id === store.authUser?.id ){
          userRanking = currentRank
        }

        d.ranking = currentRank
        d.rankingPosition = previousUserScoring
        
        d.rankingLabel = previousUserScoring === currentUserScoring ? "-" : currentRank.toString()
        d.rankingPosition = currentRank
        
        return d
        

      });
      
      setMaxrank(currentRank)
      setUserRanking(userRanking)
      setPlayersScore(playersScoreProcess || []);
  };

  useMemo(() => {
    if (dataStats) {
      const totalCrosses = dataStats.crosses?.open + dataStats.crosses?.close;
      const totalBets = dataStats.bets?.hits + dataStats.bets?.unhits;
      stats[0].from = dataStats.crosses?.close;
      stats[0].total = isNaN(totalCrosses) ? 0 : totalCrosses;
      stats[1].from = dataStats.bets?.hits;
      stats[1].total = isNaN(totalBets) ? 0 : totalBets;
      setEventStats(stats);
    }
  }, [dataStats]);

  useMemo(() => {
    if (data && selectedTab) {
      processEventScore(data);
    }
  }, [data, selectedTab]);

  return (
    <Container className="flex flex-col gap-4 px-5 py-5 md:py-12 lg:px-0">
      { selectedTab &&
              <section className="flex flex-col justify-between md:flex-row">
                <h3><span className="text-transform: capitalize">{selectedTab}</span>: {userRanking}° / {maxRank}</h3>
                <h3>Tus puntos: {userScore}</h3>
              </section>
      }
      {/* <dl className="grid grid-cols-1 overflow-hidden bg-white border border-gray-200 divide-y divide-gray-200 rounded-lg md:grid-cols-5 md:divide-y-0 md:divide-x">
        {eventStats.map(({ name, from, total }) => (
          <StatsCard key={name} name={name} from={from} total={total} />
        ))}
      </dl> */}
      <img
          className="max-w-md"
          src="/banners/benner-crear-liga.png"
        />
      <CreateCustomRanking refetchGetRanking={refetchGetRanking}/>
      
      {
        selectedTab &&  <Tabs
                            tabs={tabs}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                          />
      }
     

      { 
       isLoading 
        ? 
       <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
          <Spinner className="mt-10"/>
          Cargando tabla de datos
        </div> :
      
          <>
          { playersScore.length === 0 && <>
                <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
              Todavía no hay datos para mostrar
            </div>
            </> 
            }

          {
            !selectedTab ? <h4 className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">Todavía no tiene una liga creada ni perteneces a otras ligas</h4>:
            
            playersScore.length > 0 &&
            <PredictionsTable
              columns={predictions.columns}
              players={playersScore}
              currenPlayer={store?.authUser}
              selectedTab={selectedTab}
              setUserPosition={setUserPosition}
            />

          }
          </> 
          }
    </Container>
  );
};

export default CustomLeaguesPage;
