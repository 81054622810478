import { FaWhatsapp } from 'react-icons/fa';

interface WhatsAppButtoProps{
    className?: string;
    message: string
}

export const WhatsAppButton: React.FC<WhatsAppButtoProps> = (props) => {
    const  { message } =  props
    const handleClick = () => {
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    }
  
    return (
      <button 
      className="max-w-max flex items-center justify-center space-x-2 px-4 py-2 rounded-md bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
        onClick={handleClick}>
            <FaWhatsapp />
            <span>Compartir en WhatsApp</span></button>
    );
  }