import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { CreateGroupQuery } from '../../queries/groups.query';
import useStore from '../../store';



const schema = yup.object({
    name: yup.string().required()
}).required();

type FormData = yup.InferType<typeof schema>;

const eventId = "63f5f82492b435262c15a14b"

const FormCreateGroup = () => {
    const store = useStore();
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [groupName, setGroupName] = useState<string>("")
    const { register, handleSubmit,  resetField, formState: { errors }, reset } = useForm<FormData>({
        resolver: yupResolver(schema)
    });

  const {
    mutate: registerEvent,
    isSuccess
  } = CreateGroupQuery()

  useEffect(()=>{
    if(isSuccess){
      setSuccessMessage(`El grupo  ${groupName} fue creado`)
      resetField('name');
    }
  },[isSuccess])

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    setGroupName(values.name)
    registerEvent({
      name: values.name,
      event: eventId,
    });
  };

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
            <Label
              htmlFor="name"
              value={"Nombre del grupo: "}
            />
            <TextInput
              {...register("name")}
            />
            
            <p>{errors.name?.message}</p>

            { successMessage && <Alert color="success" >
                <p className="font-medium">
                    { successMessage }
                </p>
            </Alert>
            }
          
          <Button type="submit">
            Crear Grupo
          </Button>

        </form>
    );
  };
  
export default FormCreateGroup;
