// Components

import { Disclosure } from "@headlessui/react";
import Container from "../Container/Container";
import { MenuIcon, XMark } from "../Icons";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  { name: "¿Cómo jugar?", href: "#como-jugar" },
  { name: "Premios", href: "#premios" },
  { name: "Preguntas Frecuentes", href: "#preguntas" },
  {
    name: "Terminos y Condiciones",
    href: "/Reglas Liga Fantástica de Claro.pdf",
  },
];

const HeaderLandingB = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <Disclosure
        as="nav"
        className="bg-navbar fixed top-0 w-full z-[999999999]"
      >
        {({ open }) => (
          <>
            <Container className="px-5 py-5 md:px-0 md:py-6">
              <div className="flex items-center justify-between h-16">
                <div className="flex-shrink-0">
                  <a href="/landing">
                    <img className="h-20" src="/logo.png" alt="Claro" />
                  </a>
                </div>
                <div className="flex items-center">
                  <div className="hidden md:block">
                    <div className="flex items-baseline gap-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            "text-white hover:text-claro-500 transition hover:no-underline",
                            "px-3 py-2 rounded-md font-medium"
                          )}
                          target={item.href.includes("#") ? "_self" : "_blank"}
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="hidden md:block">
                  <a
                    href={"/login"}
                    className={classNames(
                      "text-claro-1000 bg-white rounded-full transition hover:no-underline",
                      "px-6 py-5 rounded-md font-medium"
                    )}
                  >
                    Empieza a Jugar
                  </a>
                </div>
                <div className="flex -mr-2 md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-claro-1000 focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-claro-700">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMark className="block w-10 h-10" aria-hidden="true" />
                    ) : (
                      <MenuIcon
                        className="block w-10 h-10"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </Container>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "text-white transition hover:no-underline",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    target={item.href.includes("#") ? "_self" : "_blank"}
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default HeaderLandingB;
