import React from "react";
import ProgressBar from "./ProgressBar";
import { calcPercentage } from "../helpers";

interface StatsCardProps {
  name: string;
  from: number;
  total: number;
}

const StatsCard: React.FC<StatsCardProps> = (props) => {
  const { name, from, total } = props;
  return (
    <div className="px-4 py-5 sm:p-6 flex flex-col justify-center">
      <h4 className="text-gray-900">{name}</h4>
      <dd className="flex items-baseline justify-between md:block lg:flex">
        <div className="flex flex-col w-full">
          <div className="flex items-baseline text-2xl font-semibold text-claro-600">
            {from}
            <span className="ml-2 text-sm font-medium text-gray-500">
              de {total}
            </span>
          </div>
          <div>
            <ProgressBar percentage={calcPercentage(from, total)} />
          </div>
        </div>
      </dd>
    </div>
  );
};

export default StatsCard;
