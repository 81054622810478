import { Card, Label, Radio } from "flowbite-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IFields } from "../api/types/fields.type";
import { GetFieldsByCrossQuery } from "../queries/fields.query";
import useStore from "../store";

const FieldsTable = () => {
    const { crossId } = useParams()
    const [ fields, setFields ] = useState<IFields[]>([])
    const store = useStore();
    
    const { refetch } = GetFieldsByCrossQuery({ store, crossId: crossId ?? "", enabled:true })

    useEffect(()=>{
      if(store.fields) setFields(store.fields)
    },[store.fields])

    useEffect(()=>{
      refetch()
    },[])

    const fieldCard = (field: IFields) => { 
      return  <Card>
            <h5 className="text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
              {field.question}
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              opciones :
            </p>
            
              {
                field.options.map( (o) => {
                  return <div className="flex items-center gap-2">
                          <Radio
                            id={o.uuid}
                            name={field._id}
                            value={o.uuid}
                          />
                          <Label htmlFor="united-state">
                            {o.option}
                          </Label>
                        </div>
                })
              }
            
          </Card>
    }

    return (<>
      { fields.map( f => fieldCard(f)) }
    </>);
  };
  
export default FieldsTable;
