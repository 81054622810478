import { Button, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import PlayerCell from "../PlayerCell";
import { AdminPredictionsTableProps } from "../types";



const pageSize = 25; // number of rows to display per page

const AdminPredictionsTable: React.FC<AdminPredictionsTableProps> = (props) => {
  const { columns, players, currenPlayer, selectedTab } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(players.length / pageSize);
  const start = Math.max(currentPage * pageSize, 0);
  const end = start + pageSize;
  const [paginatedData , setPaginationData] = useState(players.slice(start, end))
  const [filterUser , setFilterUser] = useState<string>("")
  const [filteredPlayer, setFilteredPlayer] = useState(players)

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const filterUserHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setFilterUser(e.currentTarget.value)
  }

  const handleFindUser = () => {
    if(filterUser === ""){
      setFilteredPlayer(players)
    }else{
      const regex = new RegExp(filterUser);
      setFilteredPlayer(players.filter( p => regex.test(p.user.email) ));
    }
  }

  useEffect(()=>{
    // const start = Math.max(1 * pageSize, 0);
    // const end = start + pageSize;
    setPaginationData(filteredPlayer.slice(start, end))
  },[filteredPlayer])

  // useEffect(()=>{
  //   setPaginationData(players.slice(start, end))
  // },[currentPage])

  

  useEffect(()=>{
    setFilteredPlayer(players)
  },[players])

  useEffect(() => {
    const currentIndex = players.findIndex(
      (player) => player.user?._id === currenPlayer?.id
    );
    const newPage = Math.floor(currentIndex / pageSize);
    setCurrentPage(newPage);
  }, [currenPlayer, players]); 

  

  return (
    <>
      <div className="overflow-x-auto">
        {
              <div className="flex flex-row justify-left align-center align-middle">
                  <TextInput
                      onChange={filterUserHandler}
                      placeholder={"Email del usuario"}
                      className={"max-w-sm"}
                  />
                  <Button
                    className={"ml-2"}
                    color={"failure"}
                    onClick={handleFindUser}
                  >Buscar</Button>
              </div>
              
          }
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
                <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  ></th>
              {columns
                .map((column) => (
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {column}
                  </th>
                ))}
                <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >Telefono</th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >Email</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {paginatedData.map((player, index) => {
              return (
                <PlayerCell
                  player={player}
                  index={
                    index + currentPage * pageSize + (currentPage === 0 ? 1 : 0)
                  }
                  currenPlayer={currenPlayer}
                  selectedTab={selectedTab}
                  admin={true}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {players.length !== 0 && (
        <div className="flex justify-between mt-4">
          <button
            className="px-4 py-2 text-white rounded bg-claro-1000 hover:bg-claro-800 disabled:bg-gray-600"
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            Anterior
          </button>
          
          <button
            className="px-4 py-2 text-white rounded bg-claro-1000 hover:bg-claro-800 disabled:bg-gray-600"
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
          >
            Siguiente
          </button>
        </div>
      )}
    </>
  );
};

export default AdminPredictionsTable;
