import { API_ENDPOINTS } from '../enums/api.enum';
import { authApi } from '.';
import { IAddFieldToCross, ICreateField, IFields, IResults, IUpdateFieldResult, IUpdateFieldToCross } from './types/fields.type';

export const getFieldsByEventApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<IFields[]>(`${API_ENDPOINTS.GET_FIELDS_BY_EVENT}?eventId=${eventId}`);
  return response.data;
};

export const getFieldsByCrossApi = async ({crossId}:{crossId:string}) => {
  const response = await authApi.get<IFields[]>(`${API_ENDPOINTS.GET_FIELDS_BY_CROSS}?id=${crossId}`);
  return response.data;
};

export const getFieldsByIdApi = async ({fieldId, userId}:{fieldId:string, userId?:string}) => {
  let url = `${API_ENDPOINTS.GET_FIELDS_BY_FIELD}`
  if( fieldId ) url += `?id=${fieldId}`
  if( userId ) url += `&userId=${userId}`
  const response = await authApi.get<IFields>(url);
  return response.data;
};

export const updateFieldToCrossApi = async (updateFieldToCross:IUpdateFieldToCross) => {
  const response = await authApi.put<IFields>(API_ENDPOINTS.UPDATE_FIELD_TO_CROSS, updateFieldToCross);
  return response.data;
};

export const updateFieldResultsApi = async (result:IResults) => {
  const response = await authApi.put<IFields>(API_ENDPOINTS.UPDATE_FIELD_RESULT, result);
  return response.data;
};

export const addFieldApi = async (createField:ICreateField) => {
  const response = await authApi.post<IFields>(API_ENDPOINTS.CREATE_FIELD, createField);
  return response.data;
};

export const addFieldToCrossApi = async (addFieldToCross:IAddFieldToCross) => {
  const response = await authApi.post<IFields>(API_ENDPOINTS.ADD_FIELD_TO_CROSS, addFieldToCross);
  return response.data;
};