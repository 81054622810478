import { Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useStore from "../store";
import { UserRolesEnum } from "./enums/userRoles.enum";

export function RolesAuthRoute({
  children,
  roles,
}: {
  children: ReactNode;
  roles: UserRolesEnum[];
}) {
  const store = useStore();
  const user = store.authUser;
  const userRoles = user?.roles;
  const canAccess = userRoles?.some((userRole) => roles.includes(userRole));
  if (canAccess) return <Fragment>{children}</Fragment>;
  return <Navigate to={"/"} />;
}
