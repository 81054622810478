import React from "react";

interface ProgressBarProps {
  percentage?: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { percentage = 0 } = props;
  return (
    <div className="relative pt-1">
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-claro-200">
        <div
          style={{ width: `${percentage}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-claro-500"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
