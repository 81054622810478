import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { GetTeamsByIdQuery, UpdateTeamQuery } from "../../queries/treams.query";
import useStore from "../../store";
import { useParams } from "react-router-dom";
import { GetAlleventsQuery } from "../../queries/events.query";


const schema = yup.object({
    events: yup.array().default(() => []).required("Error en eventos"),
}).required();

type FormData = yup.InferType<typeof schema>;

const EditTeamsPage = () => {
  let { teamId } = useParams();
  const store = useStore();
  GetAlleventsQuery({ store })

  const events = store.events;
  const team = store.team;
  const teamEvents = store.team?.events.map( (e:any) => e._id);

  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  if(teamId) GetTeamsByIdQuery({store, id: teamId})

  const {
    mutate: updateTeam,
    data,
    isSuccess,
  } = UpdateTeamQuery()

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    updateTeam({
      teamId,
      eventsIds: values.events
    });
  };

  const ifChecked = (eventId:String): boolean => {
    return teamEvents ? teamEvents.includes(eventId) : false
  }

    return (
      <>
        <section className="bg-ct-blue-600 min-h-screen pt-20">
        <div className="max-w-4xl mx-auto h-[20rem] flex justify-center items-center">
            <div>
                Equipo: { team?.name }
                
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <label>Eventos: </label>
                  { events?.map( (event) => <p>
                  {event.name} <input
                    key={event.id as string}
                    type='checkbox'
                    placeholder={event.name as string}
                    defaultChecked={ ifChecked(event?.id as string) }
                    value={event.id as string}
                    {...register('events')}
                  />  </p> )}
                    
                    
                    <button type="submit"> Actualizar </button>
                </form>
            </div>
          </div>
        </section>
      </>
    );
  };
  
export default EditTeamsPage;
