import { API_ENDPOINTS } from '../enums/api.enum';
import { authApi } from '.';
import { IGeneralAdminMetrics } from './types/admin.types';



export const platformGeneralMetrics = async () => {
  const response = await authApi.get<IGeneralAdminMetrics>(API_ENDPOINTS.GENERAL_METRICS);
  return response.data;
};
