import React from "react";
import Logo from "../Logo";

const AuthContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col w-full gap-4 py-8 mx-auto bg-white border-b-8 md:w-3/4 lg:w-1/2 border-claro-800">
      <Logo className="w-1/2 mx-auto px-4 md:px-8" />
      <section className="flex flex-col gap-2 px-4 md:px-8">
        <hr />
        <h3 className="font-normal">{title}</h3>
      </section>
      {children}
    </div>
  );
};

export default AuthContainer;
