import { addEventToUserApi, addTeamsToEventApi, createEventsApi, getAlleventsApi, getAllOpenEventsApi, getEventApi, getEventDetaislApi, getEventScoreApi, getEventStatsApi, getUserEventsApi, processEventScoreApi, updateEventStatusApi } from "../api/events.api";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { Store } from "../store";
import { IEvent } from "../interfaces/event.interfaces";
import { IAddEventToUser, IAddTeamsToEvent, IUpdateEventStatus } from "../api/types/events.type";

export const GetAlleventsQuery = ({store}:{store:Store}) => 
    useQuery(["getAlleventsQuery"], getAlleventsApi, {
        onSuccess(data) {
            store.setEvents(data);
            store.setRequestLoading(false);
        },
        onError(error) {
        store.setRequestLoading(false);
        },
    });

export const GetAllOpenEventsQuery = ({store}:{store:Store}) => 
useQuery(["getAllOpenEventsApi"], getAllOpenEventsApi, {
    onSuccess(data) {
        store.setEvents(data);
        store.setRequestLoading(false);
    },
    onError(error) {
    store.setRequestLoading(false);
    },
});

export const GetUserEventsQuery = ({store}:{store:Store}) => 
    useQuery(["getUserEventsApi"], getUserEventsApi, {
        onSuccess(data) {
            store.setEvents(data);
            store.setRequestLoading(false);
        },
    onError(error) {
    store.setRequestLoading(false);
    },
});

export const GetEventQuery = ({store, eventId}:{store:Store, eventId:string}) => 
    useQuery(["getEventApi",{eventId}], () => getEventApi({eventId}), {
        onSuccess(data) {
            store.setEvent(data);
            store.setRequestLoading(false);
        },
        onError(error) {
        store.setRequestLoading(false);
        },
    });

export const GetEventDetailsQuery = ({store, eventId}:{store:Store, eventId:string}) => 
    useQuery(["getEventDetaislApi",{eventId}], () => getEventDetaislApi({eventId}), {
        onSuccess(data) {
            store.setEvent(data);
            store.setRequestLoading(false);
        },
        onError(error) {
        store.setRequestLoading(false);
        },
    });

export const GetEventScoreQuery = ({eventId, enabled=false, limit}:{eventId:string, enabled:boolean, limit?: number}) => 
    useQuery(["getEventScoreApi",{eventId}], () => getEventScoreApi({eventId, limit}), {
        onSuccess(data) {
            return data
        },
        enabled
});

export const GetEventStatsQuery = ({eventId, enabled=false}:{eventId:string, enabled:boolean}) => 
    useQuery(["getEventStatsApi",{eventId}], () => getEventStatsApi({eventId}), {
        onSuccess(data) {
            return data
        },
        enabled
});

export const GetProcessEventScoreApi = ({eventId, enabled=false}:{eventId:string, enabled:boolean}) => 
    useQuery(
        ["processEventScoreApi",{eventId}], 
        () => processEventScoreApi({eventId}), 
        {
        onSuccess(data) {
            return data
        },
        retry: false,
        enabled
    }
);





export const CreateEventQuery = () => useMutation({
    mutationFn: async (eventData: IEvent) => {
        return await createEventsApi(eventData)
    },
})

export const AddEventToUserQuery = () => useMutation({
    mutationFn: async (addEventToUer: IAddEventToUser) => {
        return await addEventToUserApi(addEventToUer)
    },
})

export const UpdateEventStatusQuery = () => useMutation({
    mutationFn: async (eventStatus: IUpdateEventStatus) => {
        return await updateEventStatusApi(eventStatus)
    },
})

export const AddTeamsToEventQuery = () => useMutation({
    mutationFn: async (addTeamsToEvent: IAddTeamsToEvent) => {
        return await addTeamsToEventApi(addTeamsToEvent)
    },
})