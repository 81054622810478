import { Badge, Button, Card, Label, Select } from "flowbite-react";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IBets } from "../api/types/bets.type";
import { ICross } from "../api/types/cross.type";
import { IFields } from "../api/types/fields.type";
import { IGroup } from "../api/types/group.type";
import { GetCrossesByEventQuery } from "../queries/crosses.query";
import { GetFieldsByCrossQuery } from "../queries/fields.query";
import { GetGroupsByEventQuery } from "../queries/groups.query";
import { AddUserBetQuery, GetUserBetsByEvent } from "../queries/user.query";
import useStore from "../store";



const UserEventDetails = () => {
    let { eventId } = useParams();
    const [groups, setGroups ] = useState<IGroup[]>([])
    const [crosses, setCrosses ] = useState<ICross[]>([])
    const [crossId, setCrossId ] = useState<string>("")
    const [crossesFields, setCrossesFields ] = useState<any>({})
    const [optionsSelected, setOptionsSelected ] = useState<any>({})
    const [bets, setBets ] = useState<IBets[]>([])
    const store = useStore();
    

    const { data, refetch, isLoading } = GetFieldsByCrossQuery({store, crossId, enabled:false});
    const {
        mutate: registerEvent,
      } = AddUserBetQuery()

    if(eventId){
        GetGroupsByEventQuery({ store, eventId})        
        GetCrossesByEventQuery({store,eventId, enabled: true})
        GetUserBetsByEvent({store,eventId, enabled: true})
    }
    
    useEffect(()=>{
        setGroups(store.groups ?? [])
    },[store.group]) 
    
    useEffect(()=>{
        setBets(store.bets ?? [])
    },[store.bets]) 

    useEffect(()=>{
        setCrosses(store.crosses ?? [])
    },[store.crosses])  

    useEffect(()=>{
        if(crossId) refetch();
    },[crossId])
    
    useEffect(()=>{
        if(store && store.fields && store.fields?.length > 0){
            setCrossesFields((newCrossFields: any) => ({
                ...crossesFields,
                [crossId]: store.fields
              }))

        }
    },[store.fields])

    const handleFieldAnswerSubmition  = ({crossId, fieldId}:{crossId:string, fieldId:string}) =>{

        registerEvent({
            crossId,
            field: fieldId,
            optionSelcted: optionsSelected[fieldId]
        });

    }

    const handleOptionSeleted = (e:React.ChangeEvent<HTMLSelectElement>, fieldId:string) => {
        setOptionsSelected((newOptions: any) => ({
            ...optionsSelected,
            [fieldId]: e.target.value
          }))

    }
    const predictionsCard = (crossId:string) => {
        let html
        Object.keys(crossesFields).forEach(function(key) {
            if( crossId === key ){
                const fields: IFields[] = crossesFields[key]
                html = fields?.map( (f) => {
                    return <>
                        <Label
                            htmlFor={f._id}
                            value={`Predice: ${f.name}`}
                            />
                        <Select
                            id={key}
                            onChange={ (e) => { 
                                handleOptionSeleted(e, f._id || "")
                            }}
                        >
                            { f.options.map( o => <option value={o.uuid as string} key={o.uuid as string} >{o.option}</option>)}
                            
                        </Select>
                        <Button onClick={()=>{ handleFieldAnswerSubmition({crossId, fieldId: f._id ?? ""}) }}>Guardar prediccion</Button>
                    </>
                    
                })
            }
        });
        if(!html) return <></>
        return html
    }

    const formAnswer = async (crossId:string) => { 
        if(crossId){
            setCrossId(crossId)
        }
    }

    
    const cardCross = (cross:ICross) => {

        const momentDate = moment.utc(cross.playTime)
        
        return <Card href="#">
            
            <span className="text-l  font-bold tracking-tight">
                {cross.group.name }
            </span>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {cross.team1.name} vs {cross.team2.name}
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
                Fecha del cruce: {momentDate.format("DD/MM/YYYY")} a las {momentDate.format('h:mm A')}
            </p>
            { cross.status === "OPEN" ? 
                    <Badge color="success">
                        ABIERTO
                    </Badge> :
                    <Badge color="failure">
                        CERRADO
                    </Badge>
                }
             { cross.status === "OPEN" &&  <Button onClick={()=>{ formAnswer(cross._id) }}> Predecir </Button> }
             
             {
                crossesFields[cross._id] ? predictionsCard(cross._id) : <></>
             }
            
        </Card>
    }

    return (
            <div>
                {crosses.map( c => cardCross(c))}
            </div>
    );
  };
  
export default UserEventDetails;
