import { Container } from "../components";
import Carousel from "../components/Carousel";
import Faq from "../components/Faq";
import HeaderLanding from "../components/Header/HeaderLanding";

const prices = [
  // "/premios/premio1.png",
  "/premios/premio2.png",
  "/premios/premio3.png",
  // "/premios/premio1.png",
  // "/premios/premio1.png",
];

const LandingPage = () => {
  return (
    <div className=" overflow-x-hidden ">
      <HeaderLanding />
      <section style={{ backgroundSize: '20vw'}} className="p-8 bg-lightgray md:p-12 bg-deco-1 bg-no-repeat bg-left-top">
        
          <Container>
            <div className="z-20 max-w-[550px] mt-[20px] flex flex-col justify-center py-0 min-h-min m-auto relative">
              <img
                  className="w-full"
                  src="/pegala.png"
                  alt="Pegala ahi"
                />
              <div className="text-black text-center text-3xl font-raleway italic font-extrabold tracking-tighter  mt-[30px]">con el fantasy más rankeao’<br/>de Puerto Rico</div>
              <div className="text-black text-center text-xl font-raleway  leading-none tracking-tighter  mt-[20px] mb-[32px]">
                  Juega con los partidos del torneo nacional <br/> <span className="font-semibold">¡y podrías ganar premios! </span></div>  
                  <div className="text-black text-center text-xl font-raleway  leading-none tracking-tighter">
                  Monta tu Liga Privada<br/> <span className="font-semibold">¡a ver quién esta más duro!</span></div>  
            </div>
            <img
                  className="absolute z-10 lg:w-[18vw] lg:right-[2vw] lg:bottom-[7vw] w-[22vw] right-[2vw] bottom-[17vw]  "
                  src="/deco-3-1.png"
                  alt="Pegala ahi"
                />
          </Container>
      </section>
      
      <section className="p-8 relative bg-white md:p-12" id="como-jugar">  
          <Container >
            <img src="deco-4.png" className="absolute left-[-12vw] lg:left-[2vw] max-w-[230px] w-[22vw] md:w-[20vw] sm:w-[20vw]"/>
            <div  className="max-w-[550px] mt-[20px] flex flex-col justify-center py-0 min-h-min m-auto">
            <h1 className=" text-claro-1500 m-auto font-raleway ">¿Cómo jugar?</h1>
            <br/>
            <p className="italic">Intenta predecir el ganador, el marcador ﬁnal y la cantidad de tiros de 3 de cada partido. ¡Así de fácil!</p>
            <br/>
            <p>Tienes hasta 15 minutos previos al inicio del juego. Si no ingresas tu predicción, no se otorgarán puntos, pero puedes seguir jugando para ganar premios.
Cada jugador también podrá hacer hasta una Liga Privada, identiﬁcada como Mi  Liga. Con tus predicciones estarás jugando para la Liga Local o Visitante y para tu Liga Privada a la vez.</p>
<br/>

            </div>
            
            
            
          </Container>
          <img src="deco-5.png" className="absolute w-[22vw] lg:w-[22vw] md:top-[10vw] right-[-12vw] lg:right-0  top-[44vw]"/>
          <img src="deco-3.png" className="absolute w-[10vw] lg:top-[22vw] lg:right-[5vw] md:top-[24vw] right-[2vw] top-[65vw]"/>
      </section>

      <section className="p-8 bg-lightgray md:p-12">  
          <Container>
            <h1 className="text-claro-1500 m-auto text-6xl text-center mb-5">PUNTOS</h1>
            
              <div className="flex flex-col sm:flex-row items-center justify-around md:items-top max-w-[550px] m-auto">
                <div className="max-w-[140px]">
                  <div className="text-black text-center text-5xl font-raleway italic font-extrabold leading-none mb-3">+1pt</div>
                  <div className="text-black text-center text-lg font-raleway w-fit leading-none tracking-tighter">Si aciertas el equipo ganador.</div>
                </div>
                <div className="max-w-[140px] pt-[25px]">
                  <div className="text-black text-center text-5xl font-raleway italic font-extrabold leading-none mb-3">+2pt</div>
                  <div className="text-black text-center text-lg font-raleway w-fit leading-none tracking-tighter">Si aciertas la puntuación ﬁnal anotada por el equipo ganador.</div>
                </div>
                <div className="max-w-[140px]">
                  <div className="text-black text-center text-5xl font-raleway italic font-extrabold leading-none mb-3">+2pt</div>
                  <div className="text-black text-center text-lg font-raleway w-fit leading-none tracking-tighter">Si aciertas la puntuación ﬁnal anotada por el equipo ganador.</div>
                </div>
              </div>
            
          </Container>
      </section>

      <section style={{ backgroundSize: '30vw'}} className="p-8 bg-lightgray md:p-12 bg-deco-2 bg-no-repeat bg-left-top relative">  
          <Container>
            <div className="max-w-[550px] flex flex-col justify-center py-0 min-h-min m-auto">
              <div className="m-auto">
                <img className="mt-80px max-w-[300px]" src="tabla_ligas.png"/>
                <br/>
                <p className="text-black text-base font-raleway font-semibold leading-125 tracking-tighter">
                La tabla del torneo se actualizará después de cada juego. El jugador con más puntos al ﬁnal de cada fase, ganará la fase. En caso de empates, el jugador con más juegos y puntuaciones acertadas se clasiﬁcará más alto y será posicionado como ganador de la fase. (Ver sección de Reglas para más detalles).
                </p>
              </div>
              <img src="separador-rojo.png" className="mt-[25px] mb-[50px] w-full max-w-[700px] m-auto"/>
            </div>
          </Container>
          <img src="deco-6.png" className="absolute lg:w-[15vw] lg:right-[4vw] lg:top-[7vw] top-[10vw] right-[-20vw] w-[23vw]"/>
      </section>

      <section className="p-8 bg-lightgray md:p-12" id="premios">  
          <Container>
            <div className="max-w-[550px] flex flex-col justify-center py-0 min-h-min m-auto">
              <h1 className="center m-auto text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-900 font-raleway italic font-extrabold leading-none">PREMIOS</h1>
              <div>
                <Carousel images={prices} delay={3000} />
              </div>
            </div>
            
          </Container>
          <div className="w-full">
              <img src="separador_azul.png" className="max-w-[900px] w-full m-auto mt-[50px]"/>
            </div>
      </section>

      <section className="p-8 bg-lightgray md:p-12 relative" id="preguntas">  
          <Container>
            <div className="max-w-[550px] bg-white sm:p-10 md:p-10 lg:p-20 flex flex-col justify-center py-0 min-h-min m-auto">
              <h1 className="mt-10 mb-[20px] center m-auto text-3xl md:text-4xl lg:text-5xl text-claro-1500 font-raleway italic font-extrabold leading-none">PREGUNTAS <br/> FRECUENTES</h1>
              <Faq />
            </div>
          </Container>
          <img src="deco-7.png" className="absolute w-[18vw] left-0 min-w-[150px] bottom-[-3vw]"/>
          <img src="deco-9.png" className="absolute right-0 bottom-0 w-[10vw]"/>
      </section>

      <section className="p-8 bg-lightgray md:p-12 relative">  
          <Container>
            <div className="max-w-[550px]  flex flex-col justify-center py-0 min-h-min m-auto">
              <h1 className="mt-10 center m-auto text-3xl md:text-4xl lg:text-5xl text-claro-1500 font-raleway italic font-extrabold leading-none center text-center mb-[20px]">Síguenos pa’ que <br/> las pegues</h1>

              <div className="flex justify-center items-center gap-0">
                <a className="mr-5" href="https://www.facebook.com/Claropr/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="fb.png" className="max-h-[50px]"/>
                </a>
                <a className="mr-5"  href="https://www.instagram.com/claropr/?hl=es"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="ig.png" className="max-h-[50px]"/>
                </a>
                <a href="https://twitter.com/clarotodo"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="tw.png" className="max-h-[50px]"/>
                </a>
              </div>
              <img src="logo.png" className="max-w-[250px] m-auto mt-[50px]" />
              
            </div>
            
          </Container>
          <img src="deco-10.png" className="absolute lg:right-[3vw] lg:top-[2vw] lg:w-[15vw] w-[22vw] top-[20vw] right-[-10vw]"/>
      </section>  
      <section className="p-8 bg-white md:p-20">  
          <Container>
           <p className="center text-center text-lg italic font-semibold font-raleway mb-[30px] lg:mb-0"> 
           <a
              href={"/Reglas Liga Fantástica de Claro.pdf"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black "
            >
            Reglas | Legales 
            </a>
          </p>

            
          </Container>
          
      </section>  
      <div className="w-full min-h-[30px] bg-separador-rojo">
      </div>

      <a href={"/login"} className="fixed bottom-[10px] z-50 left-1/2 transform -translate-x-1/2 lg:hidden">
        <img className="w-[150px] m-auto" src="/empieza_a_jugar.png"/>
      </a>
      
    </div>
  );
};

export default LandingPage;
