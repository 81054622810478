import { Card } from "flowbite-react";
import Body from "../../components/Body";
import GroupsTable from "../../components/GroupsTable";

const GroupPage = () => {
  
    return (
      <>
        <Body title={"Listado de Grupos"}>
            <Card>
              <GroupsTable/>   
            </Card>
        </Body>
        </>
    );
  };
  
export default GroupPage;
