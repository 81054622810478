import { FC, useEffect, useState } from "react";
import { ICross } from "../../api/types/cross.type";
import { IFields } from "../../api/types/fields.type";
import AnswerAdmin from "./AnswerAdmin";

export interface AnswersProps {
  cross: ICross;
  team1Result: number;
  team2Result: number;
}

const AnswersAdmin: FC<AnswersProps> = (props) => {
  const { cross, team1Result, team2Result } = props;
  const [questions, setQuestions] = useState<IFields[]>(cross.fields || []);
  const crossId = cross._id || "";
  const isOpen = cross.status === "OPEN";

  useEffect(() => {
    const updatedQuestions = questions.map((q) => {
      if (team1Result && team2Result) {
        if (q.name === "QUIEN_GANARA") {
          if (team1Result > team2Result) {
            return {
              ...q,
              correctOption: q.options[0].uuid,
            };
          }
          if (team1Result < team2Result) {
            return {
              ...q,
              correctOption: q.options[1].uuid,
            };
          }
        }

        if (q.name === "CANTIDAD_PUNTOS") {
          const points = team1Result > team2Result ? team1Result : team2Result;
          if (points < 80) {
            return {
              ...q,
              correctOption: q.options[0].uuid,
            };
          }
          if (81 <= points && points < 89) {
            return {
              ...q,
              correctOption: q.options[1].uuid,
            };
          }
          if (90 <= points) {
            return {
              ...q,
              correctOption: q.options[2].uuid,
            };
          }
        }
      }
      return q;
    });
    setQuestions(updatedQuestions);
  }, [team1Result, team2Result]);

  useEffect(() => {
    setQuestions(cross.fields || []);
  }, [cross.fields]);

  return (
    <>
      <div className="flex flex-col gap-6">
        {questions.map((q, qindex) => (
          <AnswerAdmin
            key={qindex}
            isOpen={isOpen}
            correctOption={q.correctOption}
            answers={q.options}
            question={q.question}
            crossId={crossId}
            questionId={q._id || ""}
          />
        ))}
      </div>
    </>
  );
};

export default AnswersAdmin;
