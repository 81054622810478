import axios, { InternalAxiosRequestConfig } from 'axios';
import useStore from "../store";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

authApi.defaults.headers.common['Content-Type'] = 'application/json';

authApi.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const token = useStore.getState().token
  request.headers.set('Authorization', `Bearer ${token}`);
  return request;
});


export const api = axios.create({
  baseURL: BASE_URL,
});

