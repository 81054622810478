import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from "swiper/react";

interface CarouselProps {
  delay?: number;
  images: string[];
}

const Carousel = ({ images, delay = 2500 }: CarouselProps) => {
  return (
    <Swiper
      className="mySwiper"
      autoplay={{
        delay: delay,
        disableOnInteraction: false,
      }}
      navigation
      pagination={{
        clickable: true,
        renderBullet: (index, className) =>
          `<span class="${className} inline-block w-4 h-4 bg-white border border-black rounded-full"></span>`,
      }}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {images.map((image, imgx) => (
        <SwiperSlide key={imgx}>
          <div className="grid place-items-center mb-10">
            <img src={image} alt="#" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
