import { Card } from "flowbite-react";
import Body from "../../components/Body";
import TeamsList from "../../components/TeamsList";


const TeamsPage = () => {
    return (
      <Body title={"Listado de equipos"}>
        <Card>
          <TeamsList/>   
        </Card>
    </Body>
    );
  };
  
export default TeamsPage;
