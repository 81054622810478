import { Button, ListGroup } from 'flowbite-react';
import React, { useState } from 'react';
import { ITeam } from '../api/types/teams.type';
import { GetAllTeamsQuery } from '../queries/treams.query';
import useStore from '../store';
import FormTeam from './Forms/FormTeam';
import DefaultModal from './Modal/Modal';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TeamsList: React.FC = () => {
  const [newTeam, setNewTeam] = useState<boolean>(false)
  const [editTeam, setEditTeam] = useState<ITeam>()
  const store = useStore();
  const { data, isFetching } = GetAllTeamsQuery({ store });

  const teamList = (teams: ITeam[]) => {
    return (
      <ListGroup className="w-100 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5">
        {teams.map((team) => (
          <ListGroup.Item 
            key={team.id} 
            className='w-full min-w-200'
            onClick={()=>{ handleEditTeam(team) }}
            >
            <div className="flex flex-row justify-between w-full align-middle">
              {team.name}
              <img
                className="w-10 ml-2 h-max"
                src={`${BASE_URL}/bets/public/team-photo?photo=photo/${team.photo}`}
                alt="Team Photo"
              />
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const handleCreateOrUpdateTeam = () => {
    setEditTeam(undefined)
    store.setShowModal(!store.showModal)
  }

  const handleEditTeam = (team: ITeam) => {
    setEditTeam(team)
    store.setShowModal(!store.showModal)
  }

  return (
    <>
        {
          store.showModal && <DefaultModal title={ editTeam ? "Editar equipo" : "Crear equipo"}>
            <FormTeam team={editTeam}/>
          </DefaultModal>
        }

      <div>
        <Button onClick={() => handleCreateOrUpdateTeam()}>Crear equipo</Button>
        <ul className="mt-10">
          {isFetching && "Cargando..."}
          {data && teamList(data)}
        </ul>
      </div>
    </>
  );
};

export default TeamsList;
