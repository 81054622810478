import { group } from "console";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICross } from "../api/types/cross.type";
import { Container } from "../components";
import GroupLabel from "../components/GroupLabel";
import ResultVS from "../components/ResultVS/ResultVS";
import { GetCrossesByEventQuery } from "../queries/crosses.query";
import useStore from "../store";

interface ICrossesByGroup {
  [key: string]: ICross[]
}

const ResultsPage = () => {
  const store = useStore();
  const [crosses, setCrosses ] = useState<ICross[]>([])
  const [crossesByGroup, setCrossesByGroup ] = useState<ICrossesByGroup[]>([])
  const { refetch: refetchCrosses, isLoading, data } = GetCrossesByEventQuery({
    store,
    eventId: "63f5f82492b435262c15a14b",
    enabled: true,
  });

  useEffect(()=>{
    if(data) setCrosses(data.filter( e => e.status === "CLOSE"))
  },
  [data])

  useEffect(()=>{
    const crossesGrouped = crosses.reduce((cross, a) => {
        cross[a.group?.name] = cross[a.group?.name] || [];
        cross[a.group?.name].push(a);
        return cross;
    }, Object.create(null));
    setCrossesByGroup(crossesGrouped)
  },[crosses])

  return (
    <Container className="flex flex-col gap-4 px-5 py-5 md:py-12 lg:px-0 md:gap-6">
        { isLoading && <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
            <Spinner className="mt-10"/>
            Cargando tabla de datos
            </div> 
        }
          
      {
        !isLoading && Object.keys(crossesByGroup).map( (group: string) =>{
          return <>
                    <GroupLabel>Grupo {group}</GroupLabel>
                    <div className="px-4 py-6 bg-gray-100 rounded-md">
                      {
                        // @ts-ignore: Unreachable code error
                        crossesByGroup[group].map((cross: ICross)=>{
                          return <div className="px-4 py-6 bg-gray-100 rounded-md">
                                  <ResultVS cross={cross}/>
                                </div>
                        })
                      }
                    </div>
                </>
        })
      }
    </Container>
  );
};

export default ResultsPage;
