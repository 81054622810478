import create from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IBets } from "../api/types/bets.type";
import { ICross } from "../api/types/cross.type";
import { IFields } from "../api/types/fields.type";
import { IGroup } from "../api/types/group.type";
import { ITeam } from "../api/types/teams.type";
import { IUser } from "../api/types/types";
import { IEvent, IEventDetail } from "../interfaces/event.interfaces";

export type Store = {
  authUser: IUser | null;
  requestLoading: boolean;
  token: string | null;
  events: IEvent[] | null;
  event: IEventDetail | IEvent | null;
  teams: ITeam[] | null;
  team: ITeam | null;
  fields: IFields[] | null;
  cross: ICross | null;
  crosses: ICross[] | null;
  groups: IGroup[] | null;
  group: IGroup | null;
  bets: IBets[] | null;
  bet: IBets | null;
  showModal: boolean;
  setAuthUser: (authUser: IUser | null) => void;
  setToken: (token: string | null) => void;
  setGroups: (groups: IGroup[] | null) => void;
  setGroup: (group: IGroup | null) => void;
  setRequestLoading: (isLoading: boolean) => void;
  setEvents: (events: IEvent[]) => void;
  setEvent: (event: IEventDetail | IEvent) => void;
  setTeams: (teams: ITeam[]) => void;
  setTeam: (team: ITeam) => void;
  setBets: (bets: IBets[]) => void;
  setBet: (bet: IBets) => void;
  setCrosses: (crosses: ICross[]) => void;
  setCross: (cross: ICross) => void;
  setFields: (fields: IFields[]) => void;
  reset: () => void;
  setShowModal: (showModal:boolean) => void
};

const initialState = {
  authUser: null,
  requestLoading: false,
  token: null,
  events: null,
  event: null,
  teams: null,
  team: null,
  cross: null,
  crosses: null,
  fields: null,
  groups: null,
  group: null,
  bets: null,
  bet: null,
  showModal: false,
};

const useStore = create<Store>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setToken: (token) => set((state) => ({ ...state, token })),
        setEvent: (event) => set((state) => ({ ...state, event })),
        setEvents: (events) => set((state) => ({ ...state, events })),
        setAuthUser: (authUser) => set((state) => ({ ...state, authUser })),
        setTeams: (teams) => set((state) => ({ ...state, teams })),
        setTeam: (team) => set((state) => ({ ...state, team })),
        setBets: (bets) => set((state) => ({ ...state, bets })),
        setBet: (bet) => set((state) => ({ ...state, bet })),
        setGroups: (groups) => set((state) => ({ ...state, groups })),
        setGroup: (group) => set((state) => ({ ...state, group })),
        setCrosses: (crosses) => set((state) => ({ ...state, crosses })),
        setCross: (cross) => set((state) => ({ ...state, cross })),
        setFields: (fields) => set((state) => ({ ...state, fields })),
        setShowModal: (showModal) => set((state) => ({ ...state, showModal })),
        setRequestLoading: (isLoading) =>
          set((state) => ({ ...state, requestLoading: isLoading })),
        reset: () => set(initialState),
      }),
      {
        name: "claro-storage", // unique name
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);

export default useStore;
