import { API_ENDPOINTS } from '../enums/api.enum';
import { ILoginResponse } from './types/types';
import { api } from '.';


export const loginUserFn = async (user: any) => {
  const response = await api.post<ILoginResponse>(API_ENDPOINTS.LOGIN, user);
  return response.data;
};

export const verifyTokenApi = async (token: string) => {
  const response = await api.post<any>(API_ENDPOINTS.VERIFY_TOKEN, {token});
  return response.data;
};
