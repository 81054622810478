import { Card } from "flowbite-react";
import Body from "../../components/Body";
import FormAddFieldToCross from "../../components/Forms/FormAddFieldToCross";

const CreateFieldPage = () => {

    return (
      <Body title={"Agregar campos al cruce"}>
        <Card>
          <FormAddFieldToCross/>
        </Card>
      </Body>
    );
  };
  
export default CreateFieldPage;
