import Container from "../Container/Container";
import { footerData } from "./data";

const Footer = () => {
  return (
    <footer
      className="py-4 bg-claro-1000 md:py-8"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Container className="flex flex-col gap-4 md:flex md:flex-row md:items-center md:justify-between">
        <p className="text-xs leading-5 text-center text-white">
          Todos los derechos reservados. Claro Puerto Rico -{" "}
          {new Date().getFullYear()}.
        </p>
        <div className="flex justify-center gap-4">
          {footerData.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-white transition hover:text-claro-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-6 h-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </Container>
    </footer>
  );
};

Footer.Landing = () => {
  return (
    <footer
      className="py-4 bg-navbar md:py-8"
      aria-labelledby="footer-heading"
      id="terminos"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Container className="flex justify-end px-5 md:p-0">
        <a
          href={"/Reglas Liga Fantástica de Claro.pdf"}
          className="text-lg font-bold leading-5 text-right text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legales
        </a>
      </Container>
    </footer>
  );
};

export default Footer;
