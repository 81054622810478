import React from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "./Spinner";

interface LoadingButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  loading: boolean;
  btnColor?: string;
  textColor?: string;
  children?: React.ReactNode;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  const {
    loading = false,
    btnColor = "bg-claro-1000",
    textColor = "text-white",
    children,
    ...rest
  } = props;

  return (
    <button
      type="submit"
      className={twMerge(
        `w-full py-3 font-semibold rounded-lg outline-none border-none flex justify-center transition mt-2 hover:bg-claro-800 hover:text-white px-4 border border-claro-500 hover:border-transparent`,
        `${btnColor} ${loading && "bg-gray-300 hover:bg-gray-300"}`
      )}
      {...rest}
    >
      {loading ? (
        <div className="flex items-center gap-3">
          <Spinner />
          <span className="inline-block text-white">Loading...</span>
        </div>
      ) : (
        <span className={`${textColor}`}>{children}</span>
      )}
    </button>
  );
};
