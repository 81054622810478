import React, { useMemo, useState } from "react";
import Toggle from "../Toggle/Toggle";


interface FormToggleProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  disable?: boolean,
  setIsCostumber: (value:boolean) => void,
  defaultValues?: boolean
}

const FormToggle: React.FC<FormToggleProps> = (props) => {
  const { label, disable=false, defaultValue, setIsCostumber, ...rest } = props;
  
  const [checked, setChecked] = useState(props.defaultValue === 1);
  const handleToggle = () => {
    setChecked(!checked)
    setIsCostumber(!checked)
  };

  useMemo(()=>{
    setChecked(defaultValue === 1)
  },[defaultValue])
  
  return (
    <div className="flex flex-col gap-2">
      <label className="text-black">{label}</label>
      <div className="flex gap-2">
        <Toggle value={checked} setValue={handleToggle} disable={disable} />
        <p>{checked ? "Si 🤩" : "No"}</p>
      </div>
      <input type="hidden" {...rest} />
    </div>
  );
};

export default FormToggle;
