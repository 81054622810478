import { API_ENDPOINTS } from '../enums/api.enum';
import { authApi } from '.';
import { IEvent, IEventDetail } from '../interfaces/event.interfaces';
import { IAddEventToUser, IAddTeamsToEvent, IEventScore, IEventsStats, IUpdateEventStatus } from './types/events.type';

export const getAlleventsApi = async () => {
  const response = await authApi.get<IEvent[]>(API_ENDPOINTS.GET_ALL_EVENTS);
  return response.data;
};

export const getAllOpenEventsApi = async () => {
  const response = await authApi.get<IEvent[]>(API_ENDPOINTS.GET_ALL_OPEN_EVENTS);
  return response.data;
};

export const getUserEventsApi = async () => {
  const response = await authApi.get<IEvent[]>(API_ENDPOINTS.GET_USER_EVENTS);
  return response.data;
};

export const getEventApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<IEvent>(`${API_ENDPOINTS.GET_EVENT}?id=${eventId}`);
  return response.data;
};

export const getEventDetaislApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<IEventDetail>(`${API_ENDPOINTS.GET_EVENT_DETAILS}?id=${eventId}`);
  return response.data;
};

export const getEventScoreApi = async ({eventId, limit}:{eventId:string, limit?:number}) => {
  let url = `${API_ENDPOINTS.GET_EVENT_SCORES}?id=${eventId}`
  if(limit) url += `&limit=${limit.toString()}`
  const response = await authApi.get<IEventScore[]>(url);
  return response.data;
};

export const getEventStatsApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<IEventsStats>(`${API_ENDPOINTS.GET_EVENT_STATS}?id=${eventId}`);
  return response.data;
};

export const createEventsApi = async (event:IEvent) => {
  const response = await authApi.post<IEvent>(API_ENDPOINTS.CREATE_EVENT, event);
  return response.data;
};

export const addEventToUserApi = async (events:IAddEventToUser) => {
  const response = await authApi.put<IAddEventToUser>(API_ENDPOINTS.ADD_EVENTS_TO_USER, events);
  return response.data;
};

export const updateEventStatusApi = async (event:IUpdateEventStatus) => {
  const response = await authApi.patch<IEvent>(API_ENDPOINTS.UPDATE_EVENT_STATUS, event);
  return response.data;
};

export const processEventScoreApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<IEvent>(`${API_ENDPOINTS.PROCESS_EVENT_SCORE}?id=${eventId}`);
  return response.data;
};

export const addTeamsToEventApi = async (addTeamsToEvent:IAddTeamsToEvent) => {
  const response = await authApi.put<IEvent>(API_ENDPOINTS.ADD_TEAMS_TO_EVENT, addTeamsToEvent);
  return response.data;
};
