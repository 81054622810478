'use client';

import { Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import useStore from '../../store';

type Props = {
  title: string,
  children: React.ReactNode,
}

export default function DefaultModal(props: Props) {
  const { title, children} = props;
  const store = useStore();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(store.showModal);
  }, [store.showModal]);

  const closeModal = () => {
    setOpenModal(false);
    store.setShowModal(false);
  };

  return (
    <>
      <Modal show={openModal} onClose={closeModal}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
           {children}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
