import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Label } from "flowbite-react";
import 'moment/locale/es';
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { useParams } from "react-router-dom";
import { ITeam } from "../../api/types/teams.type";
import { IEvent } from "../../interfaces/event.interfaces";
import { AddTeamsToEventQuery } from "../../queries/events.query";
import { GetAllTeamsQuery } from "../../queries/treams.query";
import useStore from "../../store";

const FormAddTeamToEvent: React.FC = () => {
    let { eventId } = useParams();
    const [teams, setTeams] = useState<ITeam[] | null>([])
    const [event, setEvent] = useState<IEvent | null>()
    const [eventTeams, setEventTeams] = useState<string[]>([])
    const store = useStore();
    GetAllTeamsQuery({store})
    

    useEffect(()=>{
        setTeams(store.teams)
    },[store.teams])

    useEffect(()=>{
        setEvent(store.event as IEvent)
        const listId = store.event?.teams?.map( (t) => {
            if(typeof t === 'string') return t
            return t.id
        })
        setEventTeams(listId as string[])
    },[store.event])

    const schemaUpdateStatus = yup.object({
        teams: yup.array()
            .required("You can't leave this blank."),
    }).required();
    
    type FormDataStatus = yup.InferType<typeof schemaUpdateStatus>;

  const { register, handleSubmit, formState: { errors },getValues, reset } = useForm<FormDataStatus>({
      resolver: yupResolver(schemaUpdateStatus)
  });

  const {
      mutate: registerEvent,
  } = AddTeamsToEventQuery()

const onSubmitAddTeamsToEventHandler: SubmitHandler<FormDataStatus> = (values) => {
    registerEvent({
    eventId: eventId || "",
    teams: values.teams,
  });
};

  return (
                <form onSubmit={handleSubmit(onSubmitAddTeamsToEventHandler)}>
                    <Label
                      htmlFor="teams"
                      value={"Marque los equipos que forman parte de este evento"}
                    />

                    {
                        teams?.map( (t) => {
                            return <div className="flex items-center mb-4">
                                { t.name }
                                <Checkbox
                                    {...register('teams')}
                                    key={t.id}
                                    id={t.id}
                                    value={t.id}
                                    defaultChecked={ eventTeams?.includes(t._id ?? "") }
                                />
                            </div>
                        })
                    }
                   
                    <br/>
                    
                    
                    <Button type="submit">
                        Agregar equipos al evento
                    </Button>
                    
                </form>
    );
};

export default FormAddTeamToEvent;