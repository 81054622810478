import { Card } from "flowbite-react";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Legend, Pie, PieChart } from "recharts";
import { ICross } from "../../api/types/cross.type";
import { Container, Toggle } from "../../components";
import { GetPlatformGeneralMetrics } from "../../queries/admin.query";

interface DataItem {
  name: string;
  value: number;
  fill: string
  opacity?: number
}

const defaultDataUserCostumers = [
  { name: 'Clientes', value: 0, fill:"#d92a1c" },
  { name: 'No clientes', value: 0, fill:"#d92a1ca3" },
];

const defaultDataUserLigue = [
  { name: 'Local', value: 0, fill:"#d92a1c" },
  { name: 'Visitante', value: 0, fill:"#d92a1ca3" },
];

const defaultDataUserActives = [
  { name: 'Activos', value: 0, fill:"#d92a1c" },
  { name: 'No Activos', value: 0, fill:"#d92a1ca3" },
];

const defaultConfirmedActives = [
  { name: 'Confirmados', value: 0, fill:"#d92a1c" },
  { name: 'No Confirmados', value: 0, fill:"#d92a1ca3" },
];

const defaultuserWithPasword = [
  { name: 'Confirmados', value: 0, fill:"#d92a1c" },
  { name: 'No Confirmados', value: 0, fill:"#d92a1ca3" },
];

const defaultUsersPredictions = [
  { name: 'Con predicciones', value: 0, fill:"#d92a1c" },
  { name: 'Sin predicciones', value: 0, fill:"#d92a1ca3" },
];

const defaultPredictions = [
  { name: 'Acertadas', value: 0, fill:"#d92a1c" },
  { name: 'Sin acertar', value: 0, fill:"#d92a1ca3" },
  { name: 'Sin procesar', value: 0, fill:"#dcc8c6" },
];

const defaultCrosses = [
  { name: 'Abiertos', value: 0, fill:"#d92a1c" },
  { name: 'Cerrados', value: 0, fill:"#d92a1ca3" },
];

const defaultRankings = [
  { name: 'Participando', value: 0, fill:"#d92a1c" },
  { name: 'Sin participar', value: 0, fill:"#d92a1ca3" },
];

const AdminHomePage = () => {
  const [ costumersPieData, setCostumerPieData] = useState<DataItem[]>(defaultDataUserCostumers)
  const [ activeUserPieData, setActiveUserPieData] = useState<DataItem[]>(defaultDataUserActives)
  const [ ligueUserPieData, setLigueUserPieData] = useState<DataItem[]>(defaultDataUserLigue)
  const [ confirmedUserPieData, setConfirmedUserPieData] = useState<DataItem[]>(defaultConfirmedActives)
  const [ passwordUserPieData, setPasswordUserPieData] = useState<DataItem[]>(defaultuserWithPasword)
  const [ userWithPredictionsPieData, setUserWithPredictionsPieData] = useState<DataItem[]>(defaultUsersPredictions)
  const [ predictionsPieData, setPredictionsPieData] = useState<DataItem[]>(defaultPredictions)
  const [ crossesPieData, setCrossesPieData] = useState<DataItem[]>(defaultCrosses)
  const [ rankingsPieData, setRankingsPieData] = useState<DataItem[]>(defaultRankings)
  const [ percentage, setPercentage] = useState<boolean>(true)
  
  const { data, isLoading } =  GetPlatformGeneralMetrics()

  useEffect(()=>{
    
    if(data){
      const { userLocalLigue, userVisitLigue, totalUsers, userCostumers, userActives, userConfirmed, userWithouPassword, usersWithouBetsForTomorrow, usersWithBets, betsHits, betsUnhits, betsUnprocess, openCrosses, totalCrosses, userParticipatingInRankings, totalRankings} = data
      
        setCostumerPieData([
          { name: "Clientes", value: userCostumers, fill: "#d92a1c" },
          { name: "No clientes", value: totalUsers - userCostumers, fill: "#d92a1ca3" },
        ]);

        setLigueUserPieData([
          { name: "Local", value: userLocalLigue, fill: "#d92a1ca3" },
          { name: "Visitante", value: userVisitLigue, fill: "#d92a1c" },
        ]);

        setActiveUserPieData([
          { name: 'Activos', value: userActives, fill:"#d92a1c"},
          { name: 'No Activos', value: totalUsers - userActives, fill:"#d92a1ca3" },
        ])

        setConfirmedUserPieData([
          { name: 'Confirmados', value: userConfirmed, fill:"#d92a1c"},
          { name: 'No Confirmados', value: totalUsers - userConfirmed, fill:"#d92a1ca3" },
        ])

        setPasswordUserPieData([
          { name: 'Sin Password', value: userWithouPassword, fill:"#d92a1c"},
          { name: 'Con Password', value: totalUsers - userWithouPassword, fill:"#d92a1ca3" },
        ])

        setUserWithPredictionsPieData([
          { name: 'Con predicciones', value: usersWithBets, fill:"#d92a1c" },
          { name: 'Sin predicciones', value: usersWithouBetsForTomorrow, fill:"#d92a1ca3" },
        ])

        setPredictionsPieData([
          { name: 'Acertadas', value: betsHits, fill:"#d92a1c" },
          { name: 'Sin acertar', value: betsUnhits, fill:"#d92a1ca3" },
          { name: 'Sin procesar', value: betsUnprocess, fill:"#dcc8c6" },
        ])

        setCrossesPieData([
          { name: 'Abiertos', value: openCrosses, fill:"#d92a1c" },
          { name: 'Cerrados', value: totalCrosses - openCrosses, fill:"#d92a1ca3" },
        ])

        setRankingsPieData([
          { name: 'Participando', value: userParticipatingInRankings, fill:"#d92a1c" },
          { name: 'Sin participar', value: userActives - userParticipatingInRankings, fill:"#d92a1ca3" },
        ])
      }
    
  },[data])
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, fill, value, index, ...other }: any) => {
    
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = (cx - 10) + radius * Math.cos(-midAngle * RADIAN);
    const y = (cy - 5) + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
          x={x}
          y={y}
          fill={fill}
          key = {index}
        >
            { percentage ? `${(percent * 100).toFixed(0)}% ` : value}
        </text>
    );
};

  
  const crossList = (crosses:ICross[]) => {
    return crosses.map( (c) => {
      const momentDate = moment.utc(c.playTime)
      return <li> {c.team1.name} vs {c.team2.name}  - {momentDate.format("DD/MM/YYYY")} a las {momentDate.format('h:mm A') }</li>

    })
  }

  const handleToggle = () => {
    setPercentage(!percentage)
  };
  
  return (
    <>
    
    <Container className="flex gap-4 flex flex-col justify-center gap-12 py-0 md:gap-8 md:pb-20">
          <div className="p-8 bg-beige/70 md:p-12">
            <h2 className="font-semibold">Metricas generales de la plataforma</h2>
            <div className="flex flex-col gap-2">
              <label className="text-black">{"Mostrar porcentajes"}</label>
              <div className="flex gap-2">
                <Toggle value={percentage} setValue={handleToggle} />
                <p>{percentage ? "%" : "Nónimal"}</p>
              </div>
              <input type="hidden"/>
            </div>
          <div className="flex flex-wrap flex-row gap-12 items-start md:py-12">
          
          <Card className="w-full">
            <h4 className="text-center">Usuarios { data?.totalUsers }</h4>
            <div className="flex flex-wrap w-full justify-around items-center ">
            <PieChart width={250} height={170} className="w-1/4 p-4">
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={confirmedUserPieData}
                    cx="50%"
                    cy="110"
                    outerRadius={70}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  />
                  <Legend wrapperStyle={{fontSize: "12px"}}/>
              </PieChart>

              <PieChart width={250} height={170} className="w-1/4 p-4">
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={costumersPieData}
                    cx="50%"
                    cy="110"
                    outerRadius={70}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  />
                  <Legend wrapperStyle={{fontSize: "12px"}}/>
              </PieChart>
         
              <PieChart width={250} height={170} className="w-1/4 p-4">
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={activeUserPieData}
                    cx="50%"
                    cy="110"
                    outerRadius={70}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  />
                  <Legend wrapperStyle={{fontSize: "12px"}}/>
              </PieChart>

              <PieChart width={250} height={170} className="w-1/4 p-4">
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={passwordUserPieData}
                    cx="50%"
                    cy="110"
                    outerRadius={70}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  />
                  <Legend wrapperStyle={{fontSize: "12px"}}/>
              </PieChart>
            </div>
          </Card>

          <Card className="w-full">
            <h4 className="text-center">Cruces para mañana {data?.tomorrowCrosses?.length} </h4>
            <div className="flex flex-wrap w-full justify-around items-center ">
              <PieChart width={350} height={170} className="w-1/4 p-4">
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={userWithPredictionsPieData}
                    cx="50%"
                    cy="110"
                    outerRadius={70}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  />
                  <Legend wrapperStyle={{fontSize: "12px"}}/>
              </PieChart>
              <div className="w-1/8 p-4">
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  { data?.tomorrowCrosses ? crossList(data?.tomorrowCrosses) : ""}
                </p>
              </div>

            </div>
          </Card>

          <div className="flex flex-wrap w-full justify-around items-center ">

          <Card className="mb-10">
              <h4 className="text-center">Usuarios por liga { (data?.userActives || 0) } </h4>
              <div className="flex flex-wrap w-full justify-around items-center ">
              <PieChart width={260} height={170}>
                    <Pie
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      data={ligueUserPieData}
                      cx="50%"
                      cy="110"
                      outerRadius={70}
                      fill="#8884d8"
                      label={renderCustomizedLabel}
                    />
                    <Legend wrapperStyle={{fontSize: "12px"}}/>
                </PieChart>
              </div>
            </Card>

            <Card className="mb-10">
              <h4 className="text-center">Predicciones { (data?.betsHits || 0) + (data?.betsUnhits || 0) + (data?.betsUnprocess || 0) } </h4>
              <div className="flex flex-wrap w-full justify-around items-center ">
              <PieChart width={260} height={170}>
                    <Pie
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      data={predictionsPieData}
                      cx="50%"
                      cy="110"
                      outerRadius={70}
                      fill="#8884d8"
                      label={renderCustomizedLabel}
                    />
                    <Legend wrapperStyle={{fontSize: "12px"}}/>
                </PieChart>
              </div>
            </Card>

            <Card className="mb-10">
              <h4 className="text-center">Cruces { (data?.totalCrosses || 0) } </h4>
              <div className="flex flex-wrap w-full justify-around items-center ">
              <PieChart width={260} height={170}>
                    <Pie
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      data={crossesPieData}
                      cx="50%"
                      cy="110"
                      outerRadius={70}
                      fill="#8884d8"
                      label={renderCustomizedLabel}
                    />
                    <Legend wrapperStyle={{fontSize: "12px"}}/>
                </PieChart>
              </div>
            </Card>

            <Card className="mb-10">
              <h4 className="text-center">Rankings { (data?.totalRankings || 0) } </h4>
              <div className="flex flex-wrap w-full justify-around items-center ">
              <PieChart width={260} height={170}>
                    <Pie
                      dataKey="value"
                      startAngle={180}
                      endAngle={0}
                      data={rankingsPieData}
                      cx="50%"
                      cy="110"
                      outerRadius={70}
                      fill="#8884d8"
                      label={renderCustomizedLabel}
                    />
                    <Legend wrapperStyle={{fontSize: "12px"}}/>
                </PieChart>
              </div>
            </Card>
            </div>
        </div>
      </div>
            
    </Container>
    </>
  );
};

export default AdminHomePage;
