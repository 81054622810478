import moment from 'moment';
import React, { FC, useEffect, useState } from "react";
import { ICross } from "../../api/types/cross.type";
import { EventStatus } from "../../interfaces/event.interfaces";
import { GetCrossUserScoreByIdQuery } from "../../queries/crosses.query";
import GroupLabel from "../GroupLabel";

interface TeamVSProps {
  children?: React.ReactNode;
  cross: ICross;
  userId?: string
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TeamVS: FC<TeamVSProps> = (props) => {
  const { children, cross, userId } = props;
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const isOpen = cross.status === "OPEN";

  const momentDate = moment.utc(cross.playTime)

  const { refetch, data } = GetCrossUserScoreByIdQuery({
    crossId: cross._id,
    enabled: false,
    userId
  });

  useEffect(() => {
    if (cross.status === EventStatus.CLOSE) refetch();
  }, [cross]);

  useEffect(() => {
    if (data) {
      setTotalPoints(data.total);
    }
  }, [data]);

  const showTime =  () => {
    return `
      ${momentDate.format("DD/MM/YYYY")}
      a las
      ${momentDate.format('h:mm A')}
    `
  }

  return (
    
    <div className="w-full border border-gray-200 rounded-md">
      <GroupLabel className={`rounded-t-md ${!isOpen && "bg-gray-600"}`}>
        {cross?.group?.name}
      </GroupLabel>
      <div className="flex flex-col gap-2 px-3 py-5">
        <div className="flex flex-col">
          <p className="font-bold text-center text-gray-600">{cross?.site}</p>
          <p className="text-sm text-center text-gray-400 font-regular">
            {
              cross.confirmedPlayTime ? showTime() : `Fecha: a confirmar`
            }
            
          </p>
        </div>
        <div className="flex flex-col items-center mt-4">
          <div className="flex items-start justify-around w-full">
            <div className="flex flex-col items-center w-1/3 gap-3">
              <img
                src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team1.photo}`}
                alt={cross?.team1?.name}
                className="w-full border border-black rounded-md"
              />
              <h3 className="text-center">{cross?.team1?.name}</h3>
            </div>
            {/* TODO: ADD RESULT */}
            <h3 className="mt-12">VS</h3>
            <div className="flex flex-col items-center w-1/3 gap-3">
              <img
                src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team2.photo}`}
                alt={cross?.team2?.name}
                className="w-full border border-black rounded-md"
              />
              <h3 className="text-center">{cross?.team2?.name}</h3>
            </div>
          </div>
          {!isOpen && (
            <div className="flex flex-col items-center mt:6 md:mt-10">
              <div className="flex items-center gap-2 px-2 py-1 border-t-2 bg-gradient-to-t from-white to-gray-100 border-x-2 border-claro-1000 rounded-t-md">
                <p className="font-bold">Recibiste</p>
                <p className="font-bold text-claro-1000">+ {totalPoints} pts</p>
              </div>
              <div className="flex items-center justify-around gap-4 px-4 rounded-md h-11 bg-claro-1000">
                <p className="px-3 py-2 text-lg font-bold text-black bg-white border-2 rounded-md border-claro-1000">
                  {cross?.team1Result}
                </p>
                <p className="text-xl font-bold text-white">FINAL</p>
                <p className="px-3 py-2 text-lg font-bold text-black bg-white border-2 rounded-md border-claro-1000">
                  {cross?.team2Result}
                </p>
              </div>
            </div>
          )}
        </div>
        <br />
        <hr className="mb-2" />
        {children}
      </div>
    </div>
  );
};

export default TeamVS;
