import Body from "../../components/Body";
import { Card } from "flowbite-react";
import EventsList from "../../components/EventList";

const UserEventsListPage = () => {

    return (
      <Body title={"Seleccionar un evento"}>
            <Card>
              <EventsList/>   
            </Card>
        </Body>
    );
  };
  
export default UserEventsListPage;
