import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Label, Select } from "flowbite-react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { ITeam } from "../../api/types/teams.type";
import { EventStatus, IEvent } from "../../interfaces/event.interfaces";
import { GetEventQuery, UpdateEventStatusQuery } from "../../queries/events.query";
import useStore from "../../store";

const eventStatusOptions = Object.values(EventStatus).filter(value => typeof value === 'string') as string[];

const schemaUpdateStatus = yup.object({
    status: yup.string()
        .oneOf(eventStatusOptions)
        .default(() => (EventStatus.OPEN))
        .min(1, "You can't leave this blank.")
        .required("You can't leave this blank."),
}).required();

type FormDataStatus = yup.InferType<typeof schemaUpdateStatus>;

const EditEventForm = () => {
    let { eventId } = useParams();
    const [teams, setTeams] = useState<ITeam[] | null>([])
    const [event, setEvent] = useState<IEvent | null>()
    const store = useStore();

    if(eventId){
      GetEventQuery({ store, eventId })
    }

    
    useEffect(()=>{
        setEvent(store.event as IEvent)
        setTeams(store.event?.teams as ITeam[])
    },[store.event])

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormDataStatus>({
        resolver: yupResolver(schemaUpdateStatus)
    });

    const {
        mutate: registerEventUpdateStatus,
    } = UpdateEventStatusQuery()

  const onSubmitStatusUpdateHandler: SubmitHandler<FormDataStatus> = (values) => {
    registerEventUpdateStatus({
      eventId: event?.id as string,
      status: values.status,
    });
  };

  const ifOptionSelected = (op: EventStatus) => {
    return op === event?.status as EventStatus
  }

    return (
      <div>
                <h3> Evento: {event?.name} </h3>
                <form onSubmit={handleSubmit(onSubmitStatusUpdateHandler)}>
                    <Label
                      htmlFor="status"
                      value={"Actualizar estado del evento:"}
                    />
                    <Select {...register("status")} >
                      { eventStatusOptions.map( op => <option selected={ifOptionSelected(op as EventStatus)} key={op} value={op}>{op}</option> ) }
                    </Select>
                    
                    <Button type="submit">
                      Actualizar Estado
                    </Button>
                    
                </form>
            </div>
      
    );
  };
  
export default EditEventForm;
