import { Outlet } from "react-router-dom";
import { useIsAdmin } from "../hooks/userRoleHook";
import AdminButtonBox from "./Admin/AdminButtonsBox";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";

const PrivateLayout = ({
  title,
  image,
  main = false,
  children,
}: {
  title: string;
  image?: string;
  main?: boolean;
  children: any;
}) => {
  const isAdmin = useIsAdmin();
  return (
    <>
      <Header />
      <Hero title={title} main={main} image={image} />
      
      { isAdmin && <AdminButtonBox/> }

      <Outlet />
      {children}
      <Footer />
    </>
  );
};

export default PrivateLayout;
