import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TypeOf } from "zod";
import { loginUserFn } from "../api/auth.api";
import { AuthContainer } from "../components";
import FormInput from "../components/Forms/FormInput";
import { LoadingButton } from "../components/LoadingButton";
import SigninLayout from "../layouts/SigninLayout";
import {
  ResendEmailConfirmationQuery,
  VerifyTokenQuery,
} from "../queries/user.query";
import { RoutesEnum } from "../router/enums/routes.enum";
import { UserRolesEnum } from "../router/enums/userRoles.enum";
import { loginSchema } from "../schemas";
import useStore from "../store";

export type LoginForm = TypeOf<typeof loginSchema>;

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { token } = useParams();
  const store = useStore();
  const [showSendEmail, setShowSendEmail] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const {
    mutate: requestResendUserConfirmation,
    isSuccess: isSucccessRequestResendUserConfirmation,
  } = ResendEmailConfirmationQuery();

  useMemo(() => {
    store.reset();
  }, []);

  const methods = useForm<LoginForm>({
    resolver: zodResolver(loginSchema),
  });

  const {
    mutate: verifyToken,
    isSuccess,
    error,
    data: verifyTokenData,
    isLoading,
  } = VerifyTokenQuery();

  useMemo(() => {
    if (!isSuccess && isLoading)
      setErrorMessage("El token ya fue usado o ha expirado.");
  }, [isSuccess, isLoading]);

  useEffect(() => {
    if (token) verifyToken(token);
  }, [token]);

  useEffect(() => {
    if (isSuccess && verifyTokenData && verifyTokenData.access_token) {
      store.setRequestLoading(false);
      store.setToken(verifyTokenData.access_token);
      store.setAuthUser(verifyTokenData.payload);
      navigate(`${RoutesEnum.PREDICTIONS}`);
    }
  }, [isSuccess, verifyTokenData]);

  //  API Login Mutation
  const { mutate: loginUser, isSuccess: isLogged } = useMutation(
    (userData: LoginForm) => loginUserFn(userData),
    {
      onMutate(variables) {
        store.setRequestLoading(true);
      },
      onSuccess: (data) => {
        store.setRequestLoading(false);
        store.setToken(data.access_token);
        store.setAuthUser(data.payload);
        const role = data.payload.roles;
        const from =
          ((location.state as any)?.from.pathname as string) ||
          role.includes(UserRolesEnum.ADMIN) ||
          role.includes(UserRolesEnum.VIEWER)
            ? RoutesEnum.ADMIN_LANDING
            : RoutesEnum.HOME;
        setTimeout(() => {
          navigate(from);
        }, 1000);
      },
      onError: (error: any) => {
        if (error && (error as any).response.status === 403) {
          setShowSendEmail(true);
        }
        setErrorMessage(error.response.data.error.message);
        store.setRequestLoading(false);
      },
    }
  );

  const { handleSubmit } = methods;
  const handleSendEmailConfirmation = () => {
    requestResendUserConfirmation(userEmail);
  };
  const onSubmitHandler: SubmitHandler<LoginForm> = (values) => {
    loginUser(values);
    setUserEmail(values.email);
  };

  return (
    <SigninLayout>
      <AuthContainer title="Conéctate">
        <div className="px-4 space-y-5 md:px-8">
        <p>Juega con los partidos del torneo nacional ¡y podrías ganar premios! Monta tu Liga con tus panas y ¡Quema la malla con la Liga Fantástica Claro!</p>
          {errorMessage}

          {showSendEmail && (
            <Button
              onClick={handleSendEmailConfirmation}
              className={`max-w-fit py-3 font-semibold bg-blue-900 rounded-lg outline-none border-none flex justify-center transition mt-2 hover:bg-blue-800 px-4 border border-blue-500 hover:border-transparent text-white hover:text-white hover:no-underline`}
            >
              Reenviar correo de confirmacion
            </Button>
          )}

          {showSendEmail && isSucccessRequestResendUserConfirmation && (
            <p>Correo enviado, revisá tu bandeja de SPAM</p>
          )}
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="w-full px-4 space-y-5 md:px-8"
          >
            <FormInput
              label="Email"
              placeholder="usuario@claropr.com"
              name="email"
              type="email"
            />
            <FormInput
              label="Contraseña"
              placeholder="*************"
              name="password"
              type="password"
            />
            <div className="text-right">
              <Link to="/reset">¿Olvidaste tu contraseña?</Link>
            </div>
            <LoadingButton
              loading={store.requestLoading}
              btnColor={
                isLogged ? "bg-green-500 hover:bg-green-500" : "bg-claro-1000"
              }
              disabled={isLogged}
            >
              {!isLogged ? "Conéctate" : "Accediendo..."}
            </LoadingButton>
            <div>
              <Link
                to="/register"
                className={`w-full py-3 font-semibold bg-blue-900 rounded-lg outline-none border-none flex justify-center transition mt-2 hover:bg-blue-800 px-4 border border-blue-500 hover:border-transparent text-white hover:text-white hover:no-underline`}
              >
                Me quiero registrar
              </Link>
            </div>
          </form>
          {error && (error as any).response.status === 403 ? (
            <div>"El token de verificacion ya fue usado"</div>
          ) : (
            ""
          )}
        </FormProvider>
      </AuthContainer>
    </SigninLayout>
  );
};

export default LoginPage;
