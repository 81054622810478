import { Switch } from "@headlessui/react";

interface ToggleProps {
  value: boolean;
  disable?: boolean,
  setValue: () => void;
}

const Toggle = ({ value, setValue, disable = false }: ToggleProps) => {
  return (
    <Switch
      disabled={ disable }
      checked={value}
      onChange={setValue}
      className={`${
        value ? "bg-claro-1000" : "bg-gray-200"
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          value ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};

export default Toggle;
