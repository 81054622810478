export enum LiguesENUM {
    LOCAL = "Ranking en Liga Local",
    VISITANTE = "Ranking en Liga Visitante"
}

export enum RankingGroupsENUM {
    ROUND_1 = "ROUND_1",
    ROUND_2 = "ROUND_2",
    SEMIFINAL = "SEMIFINAL",
    ALL = "ALL",
    FINAL = "FINAL",
}

export enum RankingsENUM {
    TRIPLES = "Ranking Triples",
    GENERAL = "Ranking General",
    ROUND_1 = "Primera Ronda",
    ROUND_2 =  "Semifinales de Conferencia",
    SEMIFINAL =  "Finales de Conferencia",
    FINAL =  "Final",
    CUSTOM_RANKING = "CUSTOM_RANKING"
}

export enum UserRankingsActionENUM {
    ADD = "ADD",
    REMOVE = "REMOVE",
}
  