import { useMutation, useQuery } from "@tanstack/react-query";
import { IUpdateRankingMember, IUpdateRankingName } from "../api/types/users-rankings.types";
import { createUserRanking, getRankingMembers, getRankings, getUserRankings, updateRankingNameApi, updateUserRankingMembers } from "../api/users-rankings";

export const GetUserRankingsQuery = ({enabled}:{enabled: boolean}) => 
    useQuery(["getUserRankings"], () => getUserRankings(), {
        onSuccess(data) {
            return data
        },
        enabled
    }
);

export const GetRankingsQuery = ({enabled}:{enabled: boolean}) => 
    useQuery(["getRankings"], () => getRankings(), {
        onSuccess(data) {
            return data
        },
        enabled
    }
);



export const GetRankingMembersQuery = ({rankingId, enabled}:{rankingId: string, enabled: boolean}) => 
    useQuery(["getRankingMembers"], () => getRankingMembers({rankingId}), {
        onSuccess(data) {
            return data
        },
        enabled
    }
);

export const CreateUserRankingQuery = () => useMutation({
    mutationFn: async () => {
        return await createUserRanking()
    },
})

export const UpdateUserRankingMembersQuery = () => useMutation({
    mutationFn: async (updateRankingMember:IUpdateRankingMember) => {
        return await updateUserRankingMembers({updateRankingMember})
    },
})

export const UpdateRankingNameQuery = () => useMutation({
    mutationFn: async (updateRankingName:IUpdateRankingName) => {
        return await updateRankingNameApi({updateRankingName})
    },
})
