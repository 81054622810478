import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { AddFieldToCrossQuery } from "../../queries/fields.query";

const formSchema  = {
  option: yup.string().required(),
  order: yup.number().required()
}

const schema = yup.object({
    name: yup.string().required(),
    score: yup.number().required(),
    question: yup.string().required(),
    options: yup.array().of(yup.object().shape(formSchema)).required()
}).required();


type FormData = yup.InferType<typeof schema>;

const FormAddFieldToCross: React.FC = () => {
    const { crossId } = useParams();
    const [ numberOfFields, setNumberOfFields ] = useState<number>(0)
    const { register, control, handleSubmit, formState: { errors }, watch } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            options: [{ option: "", order: 1}] as any
        }
      });
    
      const { fields, append, remove } = useFieldArray({
        control,
        name: "options",
      });
      
      useEffect(() => {
        const numberOfOptions = numberOfFields;
    
        const oldVal = fields.length;
        if (numberOfOptions > 0 && numberOfOptions > oldVal) {
            for (let i = oldVal; i < numberOfOptions; i++) {
              /* @ts-ignore */
              append({ option: '', order: 1 });
            }
        } else {
            for (let i = oldVal; i > numberOfOptions; i--) {
                remove(i - 1);
            }
        }
    }, [numberOfFields]);
    
      const {
        mutate: registerEvent,
      } = AddFieldToCrossQuery()
    
      const onSubmitHandler: SubmitHandler<FormData> = (values) => {
        const { name, score, question, options } = values 
        registerEvent({
          name,
          score,
          question,
          options,
          cross: crossId || ""
        });
      };
    
      const handleSubmitAddOption = (event: React.MouseEvent<HTMLButtonElement>)=> {
        setNumberOfFields(numberOfFields + 1)
        event.preventDefault();
      }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
    <label>Nombre del campo: </label>
    <input {...register("name")} />
    <p>{errors.name?.message}</p>

    <label>Puntaje para este campo: </label>
    <input {...register("score")} />
    <p>{errors.score?.message}</p>

    <label>Formule la pregunta para este campo: </label>
    <input {...register("question")} />
    <p>{errors.score?.message}</p>

    
    <p>Agregar opciones de respuestas</p>
    {fields.map((field, index) => (
      <>
        <input key={field.id} {...register(`options.${index}.option` as any, { required: true })}  />
        <input key={field.id} {...register(`options.${index}.order` as any, { required: true })}  />
      
      </>
    ))}
    
    <button onClick={handleSubmitAddOption}> Agregar opcion</button>
    
    <button type="submit"> Crear campo</button>
</form>
    );
};

export default FormAddFieldToCross;