import EventsTable from "../../components/EventsTable";
import Body from "../../components/Body";
import { Card } from "flowbite-react";

const EventsPage = () => {
  return (
    <Body title={"Listado de eventos"}>
      <Card>
        <EventsTable />
      </Card>
    </Body>
  );
};

export default EventsPage;
