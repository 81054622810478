import { Badge } from "flowbite-react";
import { useParams } from "react-router-dom";
import { ICross } from "../api/types/cross.type";
import { IFields } from "../api/types/fields.type";
import { ITeam } from "../api/types/teams.type";
import { IUser } from "../api/types/types";
import { IEventDetail } from "../interfaces/event.interfaces";
import { GetEventDetailsQuery } from "../queries/events.query";
import { GetFieldsByEventQuery } from "../queries/fields.query";
import useStore from "../store";


const EventDetails = () => {
    let { eventId } = useParams();
    const store = useStore();
    if(eventId){
        GetEventDetailsQuery({ store, eventId })
        GetFieldsByEventQuery({ store, eventId,enabled: true })
    }

    const event = store.event as IEventDetail;
    const fields = store.fields as IFields[];
    
    
    return (
            <div>
                <p className="text-center">
                    { event?.status === "OPEN" ?   
                        <Badge color="success">
                            ABIERTO
                        </Badge> :
                        <Badge color="failure">
                            CERRADO
                        </Badge>
                    }
                </p>
                
                
                <h1>{event?.name}</h1>
                
                <p className="font-black mt-10">Equipos:</p>
                <ul>
                    { event?.teams?.map( (t: ITeam) => <li> {t.name }</li>)}
                </ul>
                <p className="font-black mt-10">Cruces:</p>
                <ul>
                    { event?.crosses.map( (c: ICross) => <li> {c.team1.name} VS {c.team2.name}  </li>)}
                </ul>
                <p className="font-bold mt-10" >Participantes:</p>
                <ul>
                    { event?.users?.map( (u: IUser) => <li> {u.name}({u.email})  </li>)}
                </ul>
                <p className="font-bold mt-10">Campos:</p>
                <ul>
                    { fields?.map( (f: IFields) => 
                     <>
                        <p className="font-bold mt-2">opciones:</p>
                        <li> {f.name}: ({f.question}) por {f.score} puntos </li>
                        <ul> 
                          { f.options?.map( o => 
                              <li>{o.option}</li>
                            ) } 
                        </ul>
                          
                     </>
                     )}
                </ul>
            </div>
    );
  };
  
export default EventDetails;
