import { Spinner } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { EventScores, IEventScore } from "../../api/types/events.type";
import { Container, Tabs } from "../../components";
import AdminPredictionsTable from "../../components/Table/admin/AdminTable";
import { UserPointsProps } from "../../components/Table/types";
import { InitTabs, Tab } from "../../components/Tabs/initTabs";
import { LiguesENUM, RankingGroupsENUM, RankingsENUM } from "../../enums/rankings.enum";
import { predictions } from "../../mockData/predictionsTable";
import { GetEventScoreQuery } from "../../queries/events.query";
import useStore from "../../store";

const csvHeaders = [
    { label: 'Ranking', key: 'rankingLabel' },
    { label: 'Total de Puntos', key: 'global' },
    { label: 'Score', key: 'score' },
    { label: 'Hits', key: 'hits' },
    { label: 'Simple Hits', key: 'simpleHits' },
    { label: 'Double Hits', key: 'doubleHits' },
    
    { label: 'Liga', key: 'ligue' },
    { label: 'Nombre', key: 'user.name' },
    { label: 'Apellido', key: 'user.lastName' },
    { label: 'Telefono', key: 'user.phoneNumber' },
    { label: 'Email Confirmado', key: 'user.emailConfirmed' },
    { label: 'Email', key: 'user.email' },
    { label: 'Es cliente Claro', key: 'isCostumer' },
    
  ];

const AdminRankingPage = () => {
    const [tabs, setTabs] = useState(InitTabs);
    const [selectedTab, setSelectedTab] = useState(InitTabs[0].name);
    const [playersScore, setPlayerScore] = useState<UserPointsProps[]>([]);

  
  const store = useStore();
  const { data, isLoading } = GetEventScoreQuery({
    enabled: true,
    eventId: "63f5f82492b435262c15a14b"
  });

  useEffect(() => {
    setTabs(InitTabs);
  }, []);


  const calculateTotalScore = ({scores}:{scores:EventScores}) => {
    const totalScores = { score: 0, hits: 0, simpleHits: 0, doubleHits:0 };

    for (const roundKey in scores) {
      if(roundKey !== "ALL"){
        const round = scores[roundKey];
        totalScores.score += round.score;
        totalScores.hits += round.hits;
        totalScores.simpleHits += round.simpleHits;
        totalScores.doubleHits += round.doubleHits;
        
      }
    }
    
    return totalScores
  } 

  const findGroupByName = (tabs: Tab[], name: string): string => {
    const foundTab = tabs.find(tab => tab.name === name);
    return foundTab?.group || RankingGroupsENUM.ALL;
  }

  const processEventScore = (data: IEventScore[]) => {
    
    const tab = tabs.find( tab => tab.name === selectedTab);
    
    let playersScore = data
      .filter((d: IEventScore) => {
        
        if (
          tab?.code === RankingsENUM.GENERAL ||
          tab?.code === RankingsENUM.ROUND_1 ||
          tab?.code === RankingsENUM.ROUND_2 ||
          tab?.code === RankingsENUM.SEMIFINAL ||
          tab?.code === RankingsENUM.FINAL
        ){
          return true
        }
        

        if (tab?.code === LiguesENUM.LOCAL){
            return d.user.isCostumer
        }

        if (tab?.code === LiguesENUM.VISITANTE){
            return !d.user.isCostumer
        }

        return false

      })
      .map((d: IEventScore, index: number) => {
        const _totalScore = calculateTotalScore(d)
        d.scores["ALL"] = _totalScore
        
        const group = findGroupByName(InitTabs, selectedTab);
        
        const phaseScore = d.scores[group]

        if(!phaseScore){
            return {
                user: d.user,
                score: 0,
                doubleHits: 0,
                hits: 0,
                simpleHits: 0,
                global: 0,
                ligue: d.user?.isCostumer ? "Liga local" : "Liga visitante",
                isCostumer: d.user?.isCostumer,
              };

        }

        const data = {
          user: d.user,
          score: phaseScore.score,
          doubleHits: phaseScore.doubleHits,
          hits: phaseScore.hits < 0 ? 0 :  phaseScore.hits,
          simpleHits: phaseScore.simpleHits < 0 ? 0 :  phaseScore.simpleHits,
          global: d.scores[RankingGroupsENUM.ALL].score,
          ligue: d.user?.isCostumer ? "Liga local" : "Liga visitante",
          isCostumer: d.user?.isCostumer,
        };

        return data;
      })
      
      
      // Sort BY
      //1. Score (points1), 2.hits (points2), 3.simpleHits (points4)

      const playersScoreOrdered = playersScore.sort((a, b) => {
        if(tab?.name === RankingsENUM.TRIPLES){
          if (a.doubleHits !== b.doubleHits) {
            return b.doubleHits - a.doubleHits;
          } else if (b.score !== a.score) {
            return b.score - a.score;
          } else {
            return b.global - a.global;
          }
        }else{
          if (a.score !== b.score) {
            return b.score - a.score;
          } else if (b.doubleHits !== a.doubleHits) {
            return b.doubleHits - a.doubleHits;
          } else if (b.hits !== a.hits) {
            return b.hits - a.hits;
          } else {
            return b.global - a.global;
          }
        }
        
      });

      let currentRank = 1

      
      const playersScoreProcess = playersScoreOrdered.map( (d: UserPointsProps, index: number)=>{
        if(index ===  0){
          d.ranking = currentRank
          d.rankingLabel = currentRank.toString()
          return d
        }
        const previousD = playersScore[index - 1] 
        const previousUserScoring =  
          previousD.score +
          previousD.doubleHits +
          previousD.simpleHits +
          previousD.hits +
          previousD.global
        
        const currentUserScoring = 
          d.score +
          d.doubleHits +
          d.simpleHits +
          d.hits +
          d.global

        if (index > 0 && previousUserScoring !== currentUserScoring) {
          currentRank += 1;
        } 


        d.ranking = currentRank
        d.rankingPosition = previousUserScoring
        
        d.rankingLabel = currentRank.toString()
        d.rankingPosition = currentRank
        
        return d
        

      });
      
      setPlayerScore(playersScoreProcess || []);
  };

  useMemo(() => {
    if (data) {
      processEventScore(data);
    }
  }, [data, selectedTab]);

  
  return (
    <Container className="flex gap-4 px-5 py-5 md:py-12 flex flex-col justify-center gap-12 py-0 md:gap-8 md:pb-20">
        <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
        />

          <CSVLink data={playersScore} headers={csvHeaders} separator={";"} filename={"datos_usuarios.csv"} className="blueButton max-w-fit">
              Descargar Tabla en CSV
          </CSVLink>
       
        { 
        isLoading 
            ? 
        <div className="w- h-10 flex flex-col justify-center items-center h-20 mt-20 mb-20">
            <Spinner className="mt-10"/>
            Cargando tabla de datos
            </div> :
            <AdminPredictionsTable
                columns={predictions.columns}
                players={playersScore}
                currenPlayer={store?.authUser}
                selectedTab={selectedTab}
            /> 
            }
    </Container>
  );
};

export default AdminRankingPage;
