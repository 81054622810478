import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from "flowbite-react";
import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { UpdateRankingNameQuery } from "../../queries/users-ranskings.query";
import { LoadingButton } from '../LoadingButton';

export interface UpdateRankingNameProps {
    code: string,
    name: string,
    refetchGetRanking: () => void
}

type FormDataStatus = yup.InferType<typeof schemaSubmitNameRanking>;

const schemaSubmitNameRanking = yup.object({
  newName: yup.string()
        .min(5, 'El nombre de la liga debe tener al menos 5 caracteres')
        .max(15, 'El nombre de la liga no puede tener mas de 15 caracteres')
        .required("El nombre de la liga no puede estar vacio."),
}).required();

const FormUpdateRankingName: React.FC<UpdateRankingNameProps> = (props) => {
    const { code } = props
  
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
    reset
    } = useForm<FormDataStatus>({
    resolver: yupResolver(schemaSubmitNameRanking),
    defaultValues: useMemo(() => {
        return {newName: props.name};
      }, [props])
  });

  useEffect(() => {
    reset({newName: props.name});
  }, [props.name]);

  const {
    mutate: registerUpdateRankingName, 
    data: dataUpdateUserRankingMembers,
    isSuccess,
    isLoading
  } = UpdateRankingNameQuery();

  useEffect(()=>{
    if(isSuccess) props.refetchGetRanking()
  },[isSuccess])

  const handleAddUMemberRanking: SubmitHandler<FormDataStatus> = (values) => {
    const { newName  } = values 
    registerUpdateRankingName({name: newName, code})
  }

  return (
      
    <form onSubmit={handleSubmit(handleAddUMemberRanking)} className={"flex  gap-2 flex-row justify-left items-center"}>
        <Label
            htmlFor="name"
            value={"Ponle un nombre a tu Liga: "}
        />
        {
        
        }
        <input 
            {...register("newName")
        } className={"w-40"}/>
        
        <LoadingButton 
          type="submit"
          btnColor="bg-claro-1000 max-w-fit" 
          loading={isLoading}
          disabled={isLoading}
        >Actualizar</LoadingButton>


    </form>
    )
  };
  
export default FormUpdateRankingName;
