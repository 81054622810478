import { useMutation, useQuery } from "@tanstack/react-query";
import { createOrUpateTeamAPI, createTeamsApi, getAllTeamsApi, getTeamByEventApi, getTeamByIdApi, updateTeamsApi } from "../api/teams.api";
import { ITeam } from "../api/types/teams.type";
import { Store } from "../store";

export const GetAllTeamsQuery = ({store}:{store:Store}) => 
    useQuery(["getAllTeamsQuery"], getAllTeamsApi, {
        onSuccess(data) {
            store.setTeams(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
    });

export const GetTeamsByIdQuery = ({store, id}:{store:Store, id:string}) => 
    useQuery(["getTeamByIdApi",{id}], () => getTeamByIdApi({id}), {
    onSuccess(data) {
        store.setTeam(data);
        store.setRequestLoading(false);
    },
    onError(error) {
        store.setRequestLoading(false);
    },
});

export const GetTeamsByEventQuery = ({store, eventId}:{store:Store, eventId:string}) => 
    useQuery(["getTeamByEventApi",{eventId}], () => getTeamByEventApi({eventId}), {
    onSuccess(data) {
        store.setTeams(data);
        store.setRequestLoading(false);
    },
    onError(error) {
        store.setRequestLoading(false);
    },
});

export const CreateTeamQuery = () => useMutation({
    mutationFn: async (teamData: ITeam) => {
        return await createTeamsApi(teamData)
    },
})

export const CreateOrUpateTeamQuery = () => useMutation({
    mutationFn: async ({params, upload}:{ params: ITeam, upload: File }) => {
        return await createOrUpateTeamAPI({params, upload})
    }
})

export const UpdateTeamQuery = () => useMutation({
    mutationFn: async (teamData: any) => {
        return await updateTeamsApi(teamData)
    },
})


