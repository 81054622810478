import { Container } from "../components";

const HowToPlay = () => {
  return (
    <Container className="flex flex-col gap-4 px-5 py-5 md:py-12 lg:px-0">
      {/* How to play */}
      <h3 className="mt-2 text-claro-700 lg:mt-6">Cómo jugar</h3>
      <p className="text-sm">
      Intenta predecir el ganador, el marcador final y la cantidad de tiros de 3 de cada partido. ¡Así de fácil! Tienes hasta 15 minutos previos al inicio del juego. Si no ingresas tu predicción, no se otorgarán puntos, pero puedes seguir jugando. Hay premios en todas las fases, desde las eliminatorias hasta la final.
      Cada jugador también podrá hacer hasta una Liga Privada, identificada como <b>Mi Liga</b>. Estarás jugando para la Liga Local o Visitante y para tu Liga a la vez.

      </p>
      {/* Points */}
      <h3 className="mt-2 text-claro-700 md:mt-6">Puntos</h3>
      <p className="text-sm"><b>1 punto</b> - Si aciertas el equipo ganador </p>
      <p className="text-sm">
        <b>2 puntos</b>– Si aciertas la puntuación final anotada por el equipo ganador.
      </p>
      <p className="text-sm">
        <b>2 puntos</b>– Si aciertas los tiros de 3 anotados por el equipo ganador.
      </p>
      <p className="text-sm">
      La tabla del torneo se actualizará después de cada juego. El jugador con más puntos al final de cada fase, ganará la fase. En caso de empates, el jugador con más juegos y puntuaciones acertadas se clasificará más alto y será posicionado como ganador de la fase. (Ver sección de Reglas para más detalles).
      </p>
      {/* Competence */}
      <h3 className="mt-2 text-claro-700 md:mt-6">Reglas</h3>
      <p className="text-sm">
      Se considerará que todos los jugadores han aceptado las reglas de la Liga Fantástica Claro. Los jugadores deben asegurarse de que sus predicciones se ingresen y actualicen cómodamente antes del tiempo límite de 15 minutos para tener en cuenta cualquier problema de acceso a Internet que esté fuera del control de los administradores. Es responsabilidad de los jugadores actualizar su página de predicciones después de ingresar predicciones o hacer cualquier modificación a una predicción anterior. De lo contrario, la predicción o alteración no se registrará. En el caso de que las autoridades del torneo cambien cualquier partido por cualquier motivo, o cualquier otro problema que resulte en cambios en el calendario de juegos, se hará todo lo posible para actualizar el gráfico predictor en consecuencia. Sin embargo, en el improbable caso de cualquier problema relacionado con los resultados de los juegos, los puntajes finales y los puntos otorgados, la decisión de los administradores será definitiva y no se mantendrá correspondencia. (Ver sección de Reglas para más detalles).
      </p>
      <em className="mt-8 italic text-center text-gray-400">
        Puedes acceder al reglamento completo{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/Reglas Liga Fantástica de Claro.pdf"
        >
          aquí
        </a>
      </em>
    </Container>
  );
};

export default HowToPlay;
