import { Disclosure } from "@headlessui/react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const faqs = [
  {
    question: "¿Quién puede jugar?",
    answer: `Todos los residentes de Puerto Rico. Todos los fans del baloncesto y los que no sean también. La competencia se divide en ligas: Si eres cliente de Claro entras a la Liga Local. Si no eres cliente de Claro, entras a la Liga Visitante. ¡Hay premios para todos!`,
  },
  {
    question: "¿Cómo juego?",
    answer: `Es bien fácil. 1) Selecciona el equipo que crees será el ganador del partido. 2) Selecciona la cantidad de puntos que crees que van a encestar para ganar el partido 3) Selecciona cuantos canastos triples encestarán. No tienes que hacer nada más que esperar a que termine el partido para saber si ganaste o no. Tienes hasta 15 minutos previo al comienzo del partido para entrar tu predicción. Irás acumulando puntos si aciertas. Mientras más las pegues, más te ranqueas en la liga.`,
  },
  {
    question: "¿Cómo gano puntos?",
    answer: `Haz tus predicciones antes de cada partido. Recibes 1 (uno) punto si predices al ganador, 2 puntos (dos) si aciertas los puntos finales que logren encestar y recibes 2 (dos) puntos si aciertas la cantidad de 3 de tres que encestó el equipo ganador. (Ver sección de Reglas para más detalles)`,
  },
  {
    question: "¿Hay premios?",
    answer: `¡Claro que sí! Apunta y rankéate con tus predicciones, los primeros jugadores con más puntos al final de cada ronda, ganarán premios. Si eres cliente de Claro, ¡hay más premios para ti! ¡Llégale hasta la final!`,
  },
  {
    question: "¿Por qué me conviene cambiarme a Claro?",
    answer: `Los clientes de Claro ¡ganan en todas! Tenemos más beneficios para ti. Los mejores equipos, gratis o con descuento. Poderosa cobertura de costa a costa. Roaming en más de 220+ destinos. Fibra óptica con Internet de alta velocidad. Doble velocidad o data en Internet para el hogar si eres cliente de Claro móvil. 10% de descuento en accesorios, te lo llevas y los pagas poco a poco sin intereses ni pago inicial. Grandes ahorros al combinar servicios y mucho, mucho más.`,
  },
  // More questions...
];

export default function Faq() {
  return (
    <div className="z-20 w-full px-2 rounded-md">
      
        
          <dl className="flex flex-col">
            {faqs.map((faq, findex) => (
              <Disclosure
                as="div"
                key={faq.question}
                className={`p-2 mb-5 border-2 border-black`}
              >
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex items-start justify-between w-full text-lg text-left">
                        <span className="font-raleway italic font-extrabold leading-tight tracking-tight">
                          {faq.question}
                        </span>
                        <span className="flex items-center ml-6 h-7">
                          {open ? (
                            <AiOutlineUp
                              className="w-10"
                              aria-hidden="true"
                            />
                          ) : (
                            <AiOutlineDown
                              className="w-10"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pr-12 mt-2">
                      <p className="text-base leading-7">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
      </div>
    
  );
}
