import { Route, Routes } from "react-router-dom";
import PrivateLayout from "./components/PrivateLayout";
// Admin Pages
import CrossesByEventPage from "./pages/crosses/crosses-by-event.page";
import CrossesByGroupPage from "./pages/crosses/crosses-by-group.page";
import Error404Page from "./pages/error404.page";
import EventEditPage from "./pages/events/edit-event.page";
import EventDetailPage from "./pages/events/event-detail.page";
import EventsPage from "./pages/events/events.page";
import CreateFieldPage from "./pages/fields/create-field.page";
import FieldsByCrossPage from "./pages/fields/fields-by-cross.page";
import GroupPage from "./pages/groups/groups.page";
import ProfilePage from "./pages/profile.page";
import CreateTeamsPage from "./pages/teams/create-team.page";
import EditTeamsPage from "./pages/teams/edit-team.page";
import TeamsPage from "./pages/teams/teams.page";
import UserEventDetailPage from "./pages/user/user-event-detail.page";
import {
  default as UserEventsListPage,
  default as UserEventsPage,
} from "./pages/user/user-events-list.page";
// User Pages
import HowToPlayPage from "./pages/how-to-play.page";
import LeaguePage from "./pages/league.page";
import PrizesPage from "./pages/prizes.page";
import ResultsPage from "./pages/results.page";
// Auth && Public Pages
import LandingPage from "./pages/landing.page";
import LoginPage from "./pages/login.page";
import RegisterPage from "./pages/register.page";
import ResetPage from "./pages/reset.page";
// Routes
import AdminHomePage from "./pages/admin/admin-home.page";
import AdminRankingPage from "./pages/admin/admin-ranking.page";
import EditUserPage from "./pages/admin/admin-user.page";
import CustomLeaguesPage from "./pages/custom-leagues.page";
import HomePage from "./pages/home.page";
import LandingPageB from "./pages/landing_b.page";
import PredictionsPage from "./pages/predictions.page";
import ProfilePasswordPage from "./pages/profile-password.page";
import UserPredictions from "./pages/user-predictions.page";
import { RoutesEnum } from "./router/enums/routes.enum";
import { UserRolesEnum } from "./router/enums/userRoles.enum";
import { RolesAuthRoute } from "./router/rolesAuthRoute";
import useStore from "./store";

// TODO:
// I dont like how I resolve this

const privateRoutes = [
  {
    title: "Inicio",
    image: "jugar",
    path: RoutesEnum.PREDICTIONS,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <PredictionsPage />,
  },
  {
    title: "¿Cómo Jugar?",
    image: "como",
    path: RoutesEnum.HOW_TO_PLAY,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <HowToPlayPage />,
  },
  {
    title: "Resultados",
    image: "results",
    path: RoutesEnum.RESULTS,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <ResultsPage />,
  },
  {
    title: "Premios",
    image: "premios",
    path: RoutesEnum.PRIZES,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <PrizesPage />,
  },
  {
    title: "Mi Perfil",
    path: RoutesEnum.PROFILE,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <ProfilePage />,
  },
  {
    title: "Mi Perfil",
    path: RoutesEnum.PROFILE_PASSWORD,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <ProfilePasswordPage />,
  },
  {
    title: "Eventos",
    path: RoutesEnum.EVENTES_LIST,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <EventsPage />,
  },
  {
    title: "Evento",
    path: `${RoutesEnum.EVENT_DETAILS}/:eventId`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <EventDetailPage />,
  },
  {
    title: "Editar evento",
    path: `${RoutesEnum.EVENT_EDIT}/:eventId`,
    roles: [UserRolesEnum.ADMIN],
    page: <EventEditPage />,
  },
  {
    title: "Equipos",
    path: RoutesEnum.TEAMS_LIST,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <TeamsPage />,
  },
  {
    title: "Editar equipo",
    path: `${RoutesEnum.TEAMS_EDIT}/:teamId`,
    roles: [UserRolesEnum.ADMIN],
    page: <EditTeamsPage />,
  },
  {
    title: "Crear equipo",
    path: RoutesEnum.TEAMS_CREATE,
    roles: [UserRolesEnum.ADMIN],
    page: <CreateTeamsPage />,
  },
  {
    title: "Cruces por evento",
    path: `${RoutesEnum.CROSSES_BY_EVENT}/:eventId`,
    roles: [UserRolesEnum.ADMIN],
    page: <CrossesByEventPage />,
  },
  {
    title: "Campos por cruce",
    path: `${RoutesEnum.CREATE_FIELD}/:crossId`,
    roles: [UserRolesEnum.ADMIN],
    page: <CreateFieldPage />,
  },
  {
    title: "Usuarios por Evento",
    path: `${RoutesEnum.USER_EVENT_LIST}`,
    roles: [UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <UserEventsPage />,
  },
  {
    title: "Grupos",
    path: `${RoutesEnum.GET_ALL_GROUPS}`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <GroupPage />,
  },
  {
    title: "Cruces por grupo",
    path: `${RoutesEnum.GET_CROSSES_BY_GROUP}/:groupId`,
    roles: [UserRolesEnum.ADMIN],
    page: <CrossesByGroupPage />,
  },
  {
    title: "Usuarios por Evento",
    path: `${RoutesEnum.USER_EVENT_LIST}`,
    roles: [UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <UserEventsListPage />,
  },
  {
    title: "Detalle de evento",
    path: `${RoutesEnum.USER_EVENT_DETAILS}/:eventId`,
    roles: [UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <UserEventDetailPage />,
  },
  {
    title: "Campos por cruce",
    path: `${RoutesEnum.GET_FIELDS_BY_CROSS}/:crossId`,
    roles: [UserRolesEnum.ADMIN],
    page: <FieldsByCrossPage />,
  },
  {
    title: "Tabla Liga Fántastica",
    image: "table",
    path: `${RoutesEnum.LEAGUE}/:eventId`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <LeaguePage />,
  },
  {
    title: "Mis Ligas",
    path: `${RoutesEnum.CUSTOM_LEAGUES}/:eventId`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.USER, UserRolesEnum.VIEWER],
    page: <CustomLeaguesPage />,
  },
  {
    title: "Home",
    path: `${RoutesEnum.ADMIN_LANDING}`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <AdminHomePage />,
  },
  {
    title: "Buscar Usuarios",
    path: `${RoutesEnum.ADMIN_EDIT_USER}`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <EditUserPage />,
  },
  {
    title: "Ranking de Usuarios",
    path: `${RoutesEnum.RANKING}`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER],
    page: <AdminRankingPage />,
  },
  {
    title: "Historial",
    path: `${RoutesEnum.USER_PREDICTIONS}/:userId?`,
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.VIEWER, UserRolesEnum.USER],
    page: <UserPredictions />,
  },
];


function App() {
  const store = useStore();
  const user = store.authUser;
  return (
    <Routes>
      <Route
        path="/"
        element={
          user?.roles.includes(UserRolesEnum.ADMIN) ? (
            <AdminHomePage />
          ) : (
            <LandingPage />
          )
        }
      />
      {/* Auth */}
      <Route path={`${RoutesEnum.LOGIN}/:token?`} element={<LoginPage />} />
      <Route path={RoutesEnum.RESET} element={<ResetPage />} />
      <Route path={RoutesEnum.REGISTER} element={<RegisterPage />} />
      {/* Home */}
      <Route path={RoutesEnum.HOME} element={<HomePage />} />
      <Route path={RoutesEnum.LANDING} element={<LandingPage />} />
      <Route path={RoutesEnum.LANDING_B} element={<LandingPageB />} />
      
      {privateRoutes.map((p) => {
        return (
          <Route
            key={p.path}
            path={p.path}
            element={
              <RolesAuthRoute roles={p.roles}>
                <PrivateLayout
                  title={p.title}
                  image={p.image}
                  main={p.path === RoutesEnum.PREDICTIONS}
                >
                  {p.page}
                </PrivateLayout>
              </RolesAuthRoute>
            }
          />
        );
      })}
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default App;
