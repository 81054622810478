import { FC, useMemo, useState } from "react";
import { IBets } from "../../api/types/bets.type";
import { ICross } from "../../api/types/cross.type";
import Answer from "./Answer";

export interface AnswersProps {
  cross: ICross;
  bets: IBets[];
  userId?: string
}

const Answers: FC<AnswersProps> = (props) => {
  const { cross, userId } = props;

  const [crossId, setCrossId] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(cross.status === "OPEN");

  useMemo(() => {
    if(cross?._id){
      setCrossId(cross._id);
      setIsOpen(cross.status === "OPEN");
    }
  }, [cross]);

  return (
    <>
      <div className="flex flex-col gap-6">
        {cross && cross.fields?.map((q, qindex) => {
          return <Answer
            key={qindex}
            isOpen={isOpen}
            correctOption={q.correctOption}
            answers={q.options}
            question={q.question}
            crossId={crossId}
            questionId={q._id || ""}
            userId={userId}
          />
        })}
      </div>
    </>
  );
};

export default Answers;
