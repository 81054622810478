import { authApi } from '.';
import { API_ENDPOINTS } from '../enums/api.enum';
import { ICloseCross, ICreateCross, ICross, ICrossScore, IUpdateCross } from './types/cross.type';

export const getCrossesByEventsApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<ICross[]>(`${API_ENDPOINTS.GET_CROSSES_BY_EVENT}?id=${eventId}`);
  return response.data;
};

export const getCrossesByGroupApi = async ({groupId}:{groupId:string}) => {
  const response = await authApi.get<ICross[]>(`${API_ENDPOINTS.GET_CROSSES_BY_GROUP}?id=${groupId}`);
  return response.data;
};

export const createCrossApi = async (crosss:ICreateCross) => {
  const response = await authApi.post<ICross>(API_ENDPOINTS.CREATE_CROSS, crosss);
  return response.data;
};

export const closeCrossApi = async (closeCross:ICloseCross) => {
  const response = await authApi.patch<ICross>(API_ENDPOINTS.CLOSE_CROSS, closeCross);
  return response.data;
};

export const getCrossesById = async ({crossId}:{crossId:string}) => {
  const response = await authApi.get<ICross>(`${API_ENDPOINTS.GET_CROSSES_BY_ID}?id=${crossId}`);
  return response.data;
};

export const getCrossUserScoreById = async ({crossId, userId}:{crossId:string, userId?:string}) => {
  let url = `${API_ENDPOINTS.GET_CROSSES_USER_SCORE}?id=${crossId}`
  if( userId ) url += `&userId=${userId}`
  const response = await authApi.get<ICrossScore>(url);
  return response.data;
};

export const checkCrossQuestions = async ({crossId}:{crossId:string}) => {
  let url = `${API_ENDPOINTS.CHECK_CROSS_QUESTIONS}/${crossId}`
  const response = await authApi.get<Boolean>(url);
  return response.data;
};

export const updateCrossAPI = async (closeCross:IUpdateCross) => {
  const response = await authApi.put<ICross>(API_ENDPOINTS.UPDATE_CROSS, closeCross);
  return response.data;
};

export const deleteCrossAPI = async ({crossId}:{crossId: string}) => {
  const url = `${API_ENDPOINTS.DELETE_CROSS}?id=${crossId}`
  const response = await authApi.get<ICross>(url);
  return response.data;
};

