import { useMutation, useQuery } from "@tanstack/react-query";
import { verifyTokenApi } from "../api/auth.api";
import { getFieldsByIdApi } from "../api/fields.api";
import { IBetsAdd } from "../api/types/bets.type";
import { INewPassword, ISingUp, IUpdateUserProfile, IUser, IUserUpdate } from "../api/types/types";
import { getUserBetsByEventApi, addUserEventApi, addUserBetApi, singUpApi, requestResetUserPassword, patchUserPassordApi, patchUserProfileApi, getUserProfileFn, getUserByEmailApi, updateUserApi, resendEmailConfirmationApi } from "../api/user.api";
import { Store } from "../store";

export const AddUserEventQuery = () => useMutation({
    mutationFn: async (event: string) => {
        return await addUserEventApi(event)
    }
})

export const AddUserBetQuery = () => useMutation({
    mutationFn: async (bet: IBetsAdd) => {
        return await addUserBetApi(bet)
    }
})

export const SingUpQuery = () => useMutation({
    mutationFn: async (singup: ISingUp) => {
        return await singUpApi(singup)
    }
})

export const VerifyTokenQuery = () => useMutation({
    mutationFn: async (token: string) => {
        return await verifyTokenApi(token)
    }
})

export const UpdateUserProfileQuery = () => useMutation({
    mutationFn: async (updateUserProfile: IUpdateUserProfile) => {
        return await patchUserProfileApi(updateUserProfile)
    }
})

export const UpdateUserQuery = () => useMutation({
    mutationFn: async (user: IUserUpdate) => {
        return await updateUserApi(user)
    }
})

export const RequestResetUserPasswordQuery = () => useMutation({
    mutationFn: async (email: string) => {
        return await requestResetUserPassword(email)
    }
})

export const NewUserPasswordQuery = () => useMutation({
    mutationFn: async (newPassword: INewPassword) => {
        return await patchUserPassordApi(newPassword)
    }
})

export const ResendEmailConfirmationQuery = () => useMutation({
    mutationFn: async (email: string) => {
        return await resendEmailConfirmationApi(email)
    }
})

export const GetUserBetsByEvent = ({store, eventId, userId, enabled}:{store:Store, userId?: string, eventId:string, enabled:boolean}) => 
    useQuery(["getGroupDetailApi",{eventId}], () => getUserBetsByEventApi(eventId, userId), {
        onSuccess(data) {
            store.setBets(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
        enabled
    });

export const GetUserBetsByField = ({fieldId, enabled, userId}:{fieldId:string, enabled:boolean, userId?:string}) => 
    useQuery(["getFieldsByIdApi",{fieldId, userId}], () => getFieldsByIdApi({fieldId, userId}), {
    onSuccess(data) {
        return data
    },
    enabled
});

export const GetUserProfile = ({store, userId}:{store?:Store, userId?:string}) => 
    useQuery(["getUserProfileFn",{userId}], () => getUserProfileFn({userId}), {
    onSuccess(data) {
        const { lastName, name, isCostumer, phoneNumber} = data
        if(store){
            store.setAuthUser({...data, lastName, name, isCostumer, phoneNumber});
        }
        return data
    }
});

export const GetUserByEmailQuery = ({email, enabled = false}:{email:string, enabled: boolean}) => 
    useQuery(["getUserByEmailApi",{}], () => getUserByEmailApi(email), {
    onSuccess(data) {
        return data
    },
    retry: 0,
    enabled
});