import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { Store } from "../store";
import { addFieldApi, addFieldToCrossApi, getFieldsByCrossApi, getFieldsByEventApi, updateFieldResultsApi, updateFieldToCrossApi } from "../api/fields.api";
import { IAddFieldToCross, ICreateField, IResults, IUpdateFieldResult, IUpdateFieldToCross } from "../api/types/fields.type";

export const GetFieldsByEventQuery = ({store, eventId, enabled=true}:{store:Store, eventId:string, enabled:boolean}) => 
    useQuery(["getFieldsByEventApi",{eventId}], () => getFieldsByEventApi({eventId}), {
        onSuccess(data) {
            store.setFields(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
        enabled,
    });

export const GetFieldsByCrossQuery = ({store, crossId,  enabled=true}:{store:Store, crossId:string, enabled:boolean}) => 
    useQuery(["getFieldsByCrossApi",{crossId}], () => getFieldsByCrossApi({crossId}), {
        onSuccess(data) {
            store.setFields(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
        enabled: false,
    }
);

export const UpdateFieldToCrossQuery = () => useMutation({
    mutationFn: async (updateFieldToCross: IUpdateFieldToCross) => {
        return await updateFieldToCrossApi(updateFieldToCross)
    },
})

export const UpdateFieldResultsQuery = () => useMutation({
    mutationFn: async (result: IResults) => {
        return await updateFieldResultsApi(result)
    },
})

export const AddFieldQuery = () => useMutation({
    mutationFn: async (createField: ICreateField) => {
        return await addFieldApi(createField)
    },
})

export const AddFieldToCrossQuery = () => useMutation({
    mutationFn: async (addFieldToCross: IAddFieldToCross) => {
        return await addFieldToCrossApi(addFieldToCross)
    },
})