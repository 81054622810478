
export const predictions = {
  columns: [
    "Posición",
    "Nombre",
    "Liga",
    "Total de Puntos", // points1: Total de puntos
    "Triple Cierto", // points2: La Cantidad de veces que acerto las 3 respustas
    "Doble Acierto", // points3: Cantidad de veces que acerto solo 2 respustas
    "Un acierto", // points4: La Cantidad de veces que acerto solamente la primer pregunta
    "Total global" // points5
    //"Puntos Ganadores Correctos", // points5: La Cantidad de veces que acerto al menos 1 pregunta
  ],
  players: [],
};

