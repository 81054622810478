import { Card } from "flowbite-react";
import Body from "../../components/Body";
import EventDetails from "../../components/EventsDetails";


const EventDetailPage = () => {
    
    return (
      <Body title={"Detalles del evento"}>
            <Card>
              <EventDetails/>   
            </Card>
        </Body>
    );
  };
  
export default EventDetailPage;
