
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Checkbox, Label, Select, TextInput } from "flowbite-react";
import 'moment/locale/es';
import { useEffect, useMemo, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { ICross } from '../../api/types/cross.type';
import { IGroup } from "../../api/types/group.type";
import { ITeam } from "../../api/types/teams.type";
import { CrossStageENUM } from '../../enums/stage.enum';
import { CreateCrossQuery, GetCrossesByIdQuery, UpdateCrossQuery } from "../../queries/crosses.query";
import { GetGroupsByEventQuery } from "../../queries/groups.query";
import { GetAllTeamsQuery } from '../../queries/treams.query';
import useStore from "../../store";

const crossStageOptionsEnum = Object.values(CrossStageENUM).filter(value => typeof value === 'string') as string[];
    
const crossStageOptions = [
    {
        stage: CrossStageENUM.FINAL,
        name: "Semis & Final"
    }
]

const schemaUpdateStatus = yup.object({
    group: yup.string()
        .required("You can't leave this blank."),
    team1: yup.string()
        .required("You can't leave this blank."),
    team2: yup.string()
        .required("You can't leave this blank."),
    time: yup.string()
        .required("You can't leave this blank."),
    confirmedPlayTime: yup.bool().default(true)
        .required("You can't leave this blank."),
    site: yup.string()
        .min(1)
        .required("You can't leave this blank."),
    date: yup.date().required(),
    stage: yup.string()
        .oneOf(crossStageOptionsEnum)
        .min(1, "Tenes que elegir una fase de grupo para el cruce.")
        .required("Fase del cruce es requerido."),
}).required();

type Props = {
    crossId?: string
}
const eventId = "63f5f82492b435262c15a14b"

const FormAddCrossesToEvent = (props:Props) => {
    let { crossId } = props;
    const store = useStore();
    const [ groups, setGroups ] = useState<IGroup[] >([])
    const [ teams, setTeams ] = useState<ITeam[] >([])
    const [ cross, setCross ] = useState<ICross>()
    const [ defaultValues, setDefaultValues] =  useState<any>({
        confirmedPlayTime: true
    })

    GetGroupsByEventQuery({ store, eventId})
    GetAllTeamsQuery({ store })        
    

    if (crossId){
        GetCrossesByIdQuery({ crossId, store, enabled: true });
    }

    useEffect(()=>{
        if(store.groups) setGroups(store.groups)
    },[store.groups])

    useEffect(()=>{
        if(store.teams) setTeams(store.teams)
    },[store.teams])

    const setDateTimeString = (date:Date) => {
        const playTime = new Date(date);
        playTime.setDate(playTime.getDate());
        // Fix the TZ of PR +4
        playTime.setHours(playTime.getHours() + 4);
        const timeString = playTime.toISOString().substring(11, 16);
        const dateObj = new Date(playTime);
        const year = dateObj.getFullYear();
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return { formattedDate, timeString}
    }

    useEffect(()=>{
        if(store.cross){
            const cross = store.cross
            setCross(cross)
            const { formattedDate, timeString } = setDateTimeString(cross.playTime)
            
            setDefaultValues({
                stage: cross.stage,
                date: formattedDate,
                group: cross.group._id,
                team1: cross.team1._id || "",
                team2: cross.team2._id || "",
                time: timeString,
                site: cross.site || "",
                confirmedPlayTime: cross.confirmedPlayTime
            })

        }
    },[store.cross])

    useEffect(()=>{
        reset({
            ...defaultValues
          });
    }, [defaultValues])

    type FormDataStatus = yup.InferType<typeof schemaUpdateStatus>;

    const { register, handleSubmit, resetField, setValue, formState: { errors }, reset } = useForm<FormDataStatus>({
        defaultValues,
        resolver: yupResolver(schemaUpdateStatus),
    });

    const {
        mutate: addCrossEventStatus,
        isSuccess: isSuccessCreateCrossQuery,
        isError: isErrorCreateCrossQuery,
        isLoading: isLoadingCreateCrossQuery
    } = CreateCrossQuery()

    const {
        mutate: updateCrossStatus,
        isSuccess: isSuccessUpdaterossQuery,
        isError: isErrorCUpdateCrossQuery,
        isLoading: isLoadingUpdateCrossQuery
    } = UpdateCrossQuery()

    useMemo(()=>{
        resetField("team1")
        resetField("team2")
    },[isSuccessCreateCrossQuery])

const onSubmitStatusUpdateHandler: SubmitHandler<FormDataStatus> = (values) => {
    const playTime = values.date
    const time =  values.time.split(":")
    const datePlayTime = new Date(playTime);
    const datePart = datePlayTime.toISOString().substring(0, 10);
    // Combine date and time parts into desired format
    
    const playTimeString = `${datePart} ${time[0]}:${time[1]}`;
    // The date is saved in UTC that means I will send the time
    // that the user select but in the server it will save as -4hs from PR
    const payload = {
        event: eventId,
        team1: values.team1,
        team2: values.team2,
        playTime: playTimeString,
        site: values.site,
        group: values.group,
        stage: values.stage,
        confirmedPlayTime: values.confirmedPlayTime
    }
    
    if(crossId){
        updateCrossStatus({
            ...payload,
            cross: crossId,
           
        })
    }else{
        addCrossEventStatus(payload);
    }
    
};


  return (
                <form onSubmit={handleSubmit(onSubmitStatusUpdateHandler)} className="max-w-md m-auto">
                    <Label
                      htmlFor="group"
                      value={"Seleccione un grupo"}
                    />
                    <Select {...register("group")} >
                    <option > Seleccione un grupo </option>
                    { groups.map( g => 
                            <option 
                                key={g._id} 
                                value={g._id}
                                >
                            {g.name}</option> ) }
                    </Select>
                    <Label
                        htmlFor="stage"
                        value={"Fase del cruce: "}
                    />
                    <Select {...register("stage")} >
                    <option > Seleccione una fase de torneo </option>
                    { crossStageOptions.map( c => 
                            <option 
                                key={c.stage} 
                                value={c.stage}
                                >
                            {c.name}</option> ) }
                    </Select>
                    <Label
                      htmlFor="team1"
                      value={"Equipo 1"}
                    />
                    <br/>
                    <Select {...register("team1")} >
                    <option > Seleccione el primer equipo </option>
                    { teams.map( t => 
                            <option 
                                key={t.id} 
                                value={t.id}
                                >
                            {t.name}</option> ) }
                    </Select>

                    <Label
                      htmlFor="team2"
                      value={"Equipo 2"}
                    />
                    <br/>
                    
                    <Select {...register("team2")} >
                    <option > Seleccione el segundo equipo </option>
                    { teams.map( t => 
                            <option 
                                key={t.id} 
                                value={t.id}
                                >
                            {t.name}</option> ) }
                    </Select>
                    
                    <br/>
                    
                    <Label
                        htmlFor="date"
                        value={"Fecha del cruce: "}
                    />
                        <TextInput
                            type={"date"}
                            {...register("date", {
                                valueAsDate: true,
                              })}
                        />
                        <Label
                        htmlFor="time"
                        value={"Horas del cruce: "}
                        />
                        <TextInput
                            type={"time"}
                            {...register("time")}
                        />

                        <Label
                        htmlFor="time"
                        value={"Horario confirmado : "}
                        />
                        <Checkbox
                            {...register("confirmedPlayTime")}
                        />
                    <br/>

                    <Label
                        htmlFor="site"
                        value={"Lugar donde ocurrira el cruce: "}
                    />
                        <TextInput
                            type={"text"}
                            {...register("site")}
                        />
                    <br/>
                    {
                        (isSuccessCreateCrossQuery || isSuccessUpdaterossQuery) && <Alert
                        color="success"
                        className='mb-5'
                        >
                        <span>
                           { isSuccessCreateCrossQuery && "Cruce Creado"}
                           { isSuccessUpdaterossQuery && "Cruce Actualizado"}
                        </span>
                        </Alert>
                    }
                    
                    
                    <Button type="submit" disabled={ isLoadingCreateCrossQuery || isLoadingUpdateCrossQuery}>
                        { crossId ? "Actualizar Cruce" : "Crear Cruce"}
                    </Button>
                    
                </form>
    );
};

export default FormAddCrossesToEvent;