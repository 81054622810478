import { Container } from "../components";
import Header from "../components/Header/Header";
import { RoutesEnum } from "../router/enums/routes.enum";

const HomePage = () => {

  return (
    <>
          <Header />
          <Container className="relative lg:h-screen lg:max-w-[100%] lg:p-0 lg:m-0 sm:p-10 bg-hero-mobile lg:bg-hero-desktop bg-top bg-no-repeat bg-cover">

          <div className="max-w-fit grid grid-cols-1 lg:grid-cols-1 m-auto">
              <div className="flex-col">
                  <img
                      src="/botonera/pegala.png"
                      alt="Tabla de Ligas de Liga Fantastica"
                      className="mb-2 pt-[50px] max-w-[300px] lg:max-w-[600px] m-auto w-[70%]"
                    />
                  <img
                      src="/botonera/sub.png"
                      alt="Tabla de Ligas de Liga Fantastica"
                      className="mb-2 lg:max-w-[500px] max-w-[250px] lg:mb-[50px] m-auto w-[50%]"
                    />
                </div>

          </div>

            
            <div className="grid grid-cols-1 m-auto max-w-screen-sm p-[8vh] lg:pt-0 lg:hidden">

              <div className="flex-col mr-1">
                <a href={RoutesEnum.PREDICTIONS} >
                  <img
                    className="w-full mb-[5vh] lg:mb-[2vh] max-w-[200px] m-auto"
                    src="/botonera/pre-dicciones.png"
                    alt="Predicciones de Liga Fantastica"
                  />
                </a>

                <a href={`${RoutesEnum.CUSTOM_LEAGUES}/63f5f82492b435262c15a14b`} >
                  <img
                    src="/botonera/min-liga.png"
                    alt="Mi Liga Fantastica"
                    className="w-full mb-[5vh] lg:mb-[2vh] max-w-[200px] m-auto"
                  />
                </a>
                
                <a href={`${RoutesEnum.LEAGUE}/63f5f82492b435262c15a14b`} >
                  <img
                    src="/botonera/tabla-liga.png"
                    alt="Tabla de Ligas de Liga Fantastica"
                    className="w-full mb-[5vh] lg:mb-[2vh] max-w-[200px] m-auto"
                  />
                </a>

                <a href={`${RoutesEnum.PRIZES}`} >
                  <img
                    src="/botonera/premios.png"
                    alt="Premios de Liga Fantastica"
                    className="w-full mb-[5vh] lg:mb-[2vh] max-w-[200px] m-auto"
                  />
                </a>

              <a href={`${RoutesEnum.HOW_TO_PLAY}`} >
                <img
                    src="/botonera/como-jugar.png"
                    alt="Como Jugar Liga Fantastica"
                    className="w-full mb-[5vh] lg:mb-[2vh] max-w-[200px] m-auto"
                  />
              </a>
            </div>

          </div>
            <div className="hidden lg:flex lg:flex-col items-center justify-center">
                <div className="flex justify-center mb-4">
                  
                  <a href={RoutesEnum.PREDICTIONS} >
                    <img
                      className="w-full mb-[5vh] lg:mb-[2vh] max-w-[300px] m-[20px]"
                      src="/botonera/pre-dicciones.png"
                      alt="Predicciones de Liga Fantastica"
                    />
                  </a>

                  <a href={`${RoutesEnum.CUSTOM_LEAGUES}/63f5f82492b435262c15a14b`} >
                    <img
                      src="/botonera/min-liga.png"
                      alt="Mi Liga Fantastica"
                      className="w-full mb-[5vh] lg:mb-[2vh] max-w-[300px] m-[20px]"
                    />
                  </a>
                  
                  <a href={`${RoutesEnum.LEAGUE}/63f5f82492b435262c15a14b`} >
                    <img
                      src="/botonera/tabla-liga.png"
                      alt="Tabla de Ligas de Liga Fantastica"
                      className="w-full mb-[5vh] lg:mb-[2vh] max-w-[300px] m-[20px]"
                    />
                  </a>
                  
                </div>
              
                <div className="flex justify-center">
                  <a href={`${RoutesEnum.PRIZES}`} >
                    <img
                      src="/botonera/premios.png"
                      alt="Premios de Liga Fantastica"
                      className="w-full mb-[5vh] lg:mb-[2vh] max-w-[300px] m-[20px]"
                    />
                  </a>
                
                  <a href={`${RoutesEnum.HOW_TO_PLAY}`} >
                    <img
                        src="/botonera/como-jugar.png"
                        alt="Como Jugar Liga Fantastica"
                        className="w-full mb-[5vh] lg:mb-[2vh] max-w-[300px] m-[20px]"
                      />
                  </a>
                </div>
              </div>
            
          </Container>
          </>
  );
};

export default HomePage;
