import Body from "../../components/Body";
import { Card } from "flowbite-react";
import FieldsTable from "../../components/FieldsTable";

const FieldsByCrossPage = () => {

    return (
      <Body title={"Ver campos del cruce"}>
        <Card>
          <FieldsTable/>
        </Card>
      </Body>
    );
  };
  
export default FieldsByCrossPage;
