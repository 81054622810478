import { useQuery } from "@tanstack/react-query";
import { getUserLoginHistory } from "../api/users-history-login";

export const GetUserLoginHistoryQuery = ({enabled, userId}:{enabled: boolean, userId:string}) => 
    useQuery(["getUserLoginHistory"], () => getUserLoginHistory({userId}), {
        onSuccess(data) {
            return data
        },
        enabled
    }
);
