import React, { FC } from "react";

interface GroupLabelProps {
  className?: string;
  children: React.ReactNode;
}

const GroupLabel: FC<GroupLabelProps> = (props) => {
  const { className, children } = props;
  return (
    <div className={`w-full bg-claro-1000 p-4 md:py-5 ${className}`}>
      <h4 className="text-white text-center text-2xl">{children}</h4>
    </div>
  );
};

export default GroupLabel;
