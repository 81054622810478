import { LiguesENUM, RankingGroupsENUM, RankingsENUM } from "../../enums/rankings.enum";

export interface Tab {
    name: string;
    group: string;
    code: string;
  }
  
export let InitTabs: Tab[] = [
  // { 
  //   name: RankingsENUM.FINAL,
  //   group: RankingGroupsENUM.FINAL,
  //   code: RankingsENUM.FINAL
  // },
  { 
    name: RankingsENUM.GENERAL,
    group: RankingGroupsENUM.ALL,
    code: RankingsENUM.GENERAL
   },
   { 
    name: RankingsENUM.TRIPLES,
    group: RankingGroupsENUM.ALL,
    code: RankingsENUM.GENERAL
   },
    { 
      name: LiguesENUM.VISITANTE,
      group: RankingGroupsENUM.ALL,
      code: LiguesENUM.VISITANTE
    },
    { 
      name: LiguesENUM.LOCAL,
      group: RankingGroupsENUM.ALL,
      code: LiguesENUM.LOCAL
    },
    
    //  { 
    //   name: RankingsENUM.ROUND_1,
    //   group: RankingGroupsENUM.ROUND_1,
    //   code: RankingsENUM.ROUND_1
    // },
    // { 
    //   name: RankingsENUM.ROUND_2,
    //   group: RankingGroupsENUM.ROUND_2,
    //   code: RankingsENUM.ROUND_2
    // },
    // { 
    //   name: RankingsENUM.SEMIFINAL,
    //   group: RankingGroupsENUM.SEMIFINAL,
    //   code: RankingsENUM.SEMIFINAL
    // },
    // { 
    //   name: RankingsENUM.CUARTOS ,
    //   group: RankingGroupsENUM.QUEARTEFINALS,
    //   code: RankingsENUM.CUARTOS
    // }
  ];