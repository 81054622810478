import { authApi } from '.';
import { API_ENDPOINTS } from '../enums/api.enum';
import { ICreateTeam, ITeam, IUpdateTeamEvent } from './types/teams.type';

export const getAllTeamsApi = async () => {
  const response = await authApi.get<ITeam[]>(API_ENDPOINTS.GET_ALL_TEAMS);
  return response.data;
};

export const getTeamByIdApi = async ({id}:{id:string}) => {
  const response = await authApi.get<ITeam>(`${API_ENDPOINTS.GET_TEAM_BY_ID}?id=${id}`);
  return response.data;
};

export const getTeamByEventApi = async ({eventId}:{eventId:string}) => {
  const response = await authApi.get<ITeam[]>(`${API_ENDPOINTS.GET_TEAMS_BY_EVENT}?id=${eventId}`);
  return response.data;
};

export const createTeamsApi = async (team:ICreateTeam) => {
  const response = await authApi.post<ITeam>(API_ENDPOINTS.CREATE_TEAM, team);
  return response.data;
};


export const createOrUpateTeamAPI = async ({ params, upload }: { params: ICreateTeam, upload: File }): Promise<ITeam> => {
    
  const formData = new FormData();
  
  formData.append('file', upload);

  Object.entries(params).forEach(([key, value]) => {
      if(value && value !== "undefined" )formData.append(key, value);
    });

  const response = await authApi.post<ITeam>(API_ENDPOINTS.CREATE_TEAM, formData);
  return response.data;
};



export const updateTeamsApi = async (team:IUpdateTeamEvent) => {
  const response = await authApi.put<ITeam>(API_ENDPOINTS.UPDATE_EVENTS_TO_TEAM, team);
  return response.data;
};

