import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TypeOf } from "zod";
import { AuthContainer } from "../components";
import FormInput from "../components/Forms/FormInput";
import { LoadingButton } from "../components/LoadingButton";
import SigninLayout from "../layouts/SigninLayout";
import { RequestResetUserPasswordQuery } from "../queries/user.query";
import { resetSchema } from "../schemas";
import useStore from "../store";

export type ResetForm = TypeOf<typeof resetSchema>;

const ResetPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ errorMessage, setErrorMessage] = useState("")

  const from = ((location.state as any)?.from.pathname as string) || "/profile";

  const methods = useForm<ResetForm>({
    resolver: zodResolver(resetSchema),
  });

  const { mutate: requestUserPassword, isSuccess, isError, data, error } =
    RequestResetUserPasswordQuery();

  const store = useStore();

  useEffect(()=>{
    if(error && (error as any)?.response?.status === 404){
      setErrorMessage("La cuenta de email no existe.")
    }
    if(error && (error as any)?.response?.status !== 404){
      setErrorMessage("Error desconocido.")
    }
  },[error])

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ResetForm> = (values) => {
    requestUserPassword(values.email.trim());
  };

  return (
    <SigninLayout>
      <AuthContainer title="¿Olvidaste tu contraseña?">
        <FormProvider {...methods}>
          {isSuccess && <>Se envio un correo con una nueva contraseña </>}
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="w-full px-4 space-y-5 md:px-8"
          >
            <FormInput
              label="Email"
              placeholder="usuario@claropr.com"
              name="email"
              type="email"
            />
            <div className="text-right">
              <Link to="/login">¿Ya tienes una cuenta?</Link>
            </div>
            { errorMessage }
            <LoadingButton
              loading={store.requestLoading}
              textColor="text-white"
            >
              Enviar
            </LoadingButton>
          </form>
        </FormProvider>
      </AuthContainer>
    </SigninLayout>
  );
};

export default ResetPage;
