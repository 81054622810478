import { authApi } from '.';
import { API_ENDPOINTS } from '../enums/api.enum';
import { IUpdateRankingMember, IUpdateRankingName, IUsersRankings } from './types/users-rankings.types';

export const getUserRankings = async () => {
  const url = `${API_ENDPOINTS.GET_USERS_RANKINGS}`
  const response = await authApi.get<IUsersRankings[]>(url);
  return response.data;
};

export const getRankings = async () => {
  const url = `${API_ENDPOINTS.GET_RANKINGS}`
  const response = await authApi.get<IUsersRankings[]>(url);
  return response.data;
};

export const getRankingMembers = async ({rankingId}:{rankingId:string}) => {
  const url = `${API_ENDPOINTS.GET_RANKING_MEMBERS}/${rankingId}`
  const response = await authApi.get<IUsersRankings>(url);
  return response.data;
};

export const createUserRanking = async () => {
  const url = `${API_ENDPOINTS.CREATE_RANKING}`
  const response = await authApi.post<IUsersRankings>(url);
  return response.data;
};

export const updateUserRankingMembers = async ({updateRankingMember}:{updateRankingMember:IUpdateRankingMember}) => {
  const url = `${API_ENDPOINTS.UPDATE_RANKING_MEMBER}`
  const response = await authApi.patch<IUsersRankings>(url, updateRankingMember);
  return response.data;
};

export const updateRankingNameApi = async ({updateRankingName}:{updateRankingName:IUpdateRankingName}) => {
  const url = `${API_ENDPOINTS.UPDATE_RANKING_NAME}`
  const response = await authApi.patch<IUsersRankings>(url, updateRankingName);
  return response.data;
};