import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IBets } from "../api/types/bets.type";
import { ICross } from "../api/types/cross.type";
import { Container, Tabs, TeamVS } from "../components";
import Answers from "../components/Answers/Answers";
import { GetCrossesByEventQuery } from "../queries/crosses.query";
import useStore from "../store";

const answers = [
  {
    question: "¿Quién ganará?",
    answers: [
      { id: "1", details: "Puerto Rico" },
      { id: "3", details: "Venezuela" },
    ],
  },
  {
    question: "Cantidad de carreras anotadas",
    answers: [
      { id: "1", details: "1 - 4" },
      { id: "2", details: "5 - 8" },
      { id: "3", details: "+9" },
    ],
  },
  // {
  //   question: "Cantidad de Homeruns",
  //   answers: [
  //     { id: "1", details: "1 - 2" },
  //     { id: "2", details: "3 - 4" },
  //     { id: "3", details: "+5" },
  //   ],
  // },
];

const tabs = [{ name: "Próximos" }, { name: "Ver Todos" }];

const PredictionsPage = () => {
  const store = useStore();
  const user = store.authUser;
  const [event, setEvent] = useState<string>("63f5f82492b435262c15a14b");
  const [crosses, setCrosses] = useState<ICross[]>([]);
  const [bets, setBets] = useState<IBets[]>([]);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].name);

  const {
    refetch: refetchCrosses,
    isLoading,
    isFetching,
  } = GetCrossesByEventQuery({
    store,
    eventId: event,
    enabled: false,
  });

  useEffect(() => {
    setCrosses(store.crosses || []);
  }, [store.crosses]);

  useEffect(() => {
    if (event) {
      refetchCrosses();
    }
  }, [event]);

  useEffect(() => {
    if (store.bets) setBets(store.bets);
  }, [store.bets]);

  const openCrosses = crosses.filter((c) => c.status === "OPEN");

  const crossesList = () => {
    const crossesList = crosses.map((c, cindex) => {
      return (
        <TeamVS key={cindex} cross={c}>
          <Answers cross={c} bets={bets} />
        </TeamVS>
      );
    });

    const openCrossesList = openCrosses.map((c, cindex) => {
      return (
        <TeamVS key={cindex} cross={c}>
          <Answers cross={c} bets={bets} />
        </TeamVS>
      );
    });

    return activeTab === tabs[0].name ? openCrossesList : crossesList;
  };

  return (
    <Container className="flex flex-col gap-4 px-5 py-5 md:py-12 lg:px-0">
      <h2>Hola {user?.name}</h2>
      <p className="mb-4">
        <b>¡Rankéate Full!</b> ¡Rankéate full! Pega los marcadores de los partidos de la liga de baloncesto nacional. Mientras más aciertes, irás acumulando más `puntos para ganar magníficos premios. Tienes hasta 15 minutos previos al inicio de cada partido para entrar tus predicciones. ¡Quema la malla! monta tu propia Liga para jugar con tu corrillo. Entra a la sección de ¿Cómo jugar? para más detalles.</p>
        <p> <b>¡Nuevo!</b> Ahora podrás montar tu propia Liga para jugar con
        tu corrillo también. Entra a la sección de{" "}
        <Link to="/how-to-play">
          <i>¿Cómo jugar?</i>
        </Link>{" "}
        para más detalles.
      </p>
      {/* <h3>¿Quién ganará?</h3>
      <p>
        Pendiente a tu elección porque si aciertas sumás un punto extra. Elegí
        tu liga y empezá a jugar.
      </p>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        <TeamCard
          title="Playoffs NBA | Liga Fantástica de Claro"
          img="https://images.unsplash.com/photo-1532040683343-edbde6dd500d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
          setEvent={setEvent}
        />
        <TeamCard
          title="Mundial | Liga Fantástica de Claro"
          img="https://images.unsplash.com/photo-1475440197469-e367ec8eeb19?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          setEvent={setEvent}
        />
        <TeamCard
          title="BSN | Liga Fantástica de Claro"
          img="https://images.unsplash.com/photo-1563299796-b729d0af54a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80"
          setEvent={setEvent}
        />
      </div> */}
      {isFetching ? (
        <div className="flex flex-col items-center justify-center w-full h-10 h-20 mt-20 mb-20">
          <Spinner className="mt-10" />
          Cargando datos
        </div>
      ) : (
        <>
          <Tabs
            selectedTab={activeTab}
            setSelectedTab={setActiveTab}
            tabs={tabs}
          />
          <div className="grid grid-cols-1 gap-12 mt-4 md:grid-cols-2">
            {crossesList()}
          </div>
        </>
      )}
    </Container>
  );
};

export default PredictionsPage;
