import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'flowbite-react';
import { Dispatch, FC, Fragment, ReactElement, SetStateAction } from 'react';


interface ConfirmDialogProps {
    open: boolean;
    title: ReactElement
    description?: string
    body: string
    buttonConfirmText: string
    buttonConfirmEvent: Dispatch<SetStateAction<Boolean>>
    setIsOpenCallback: Dispatch<SetStateAction<boolean>>
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const { open, title, description, body, buttonConfirmText, buttonConfirmEvent, setIsOpenCallback } = props

    const closeModal = () => {
        setIsOpenCallback(false)
    }
  
    const handleConfirm =  () => {
        setIsOpenCallback(false)
        buttonConfirmEvent(true)
    }

  return (
    <Transition appear show={open} as={Fragment}>
                  <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                  >
                    <div className="min-h-screen px-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Dialog.Overlay className="fixed inset-0" />
                      </Transition.Child>

                      {/* This element is to trick the browser into centering the modal contents. */}
                      <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                      >
                        &#8203;
                      </span>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-center"
                          >
                            {title}
                          </Dialog.Title>
                          { description && 
                            <Dialog.Description>
                                {description}
                            </Dialog.Description>
                            }
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 border-t pt-2">
                              {body}
                            </p>
                          </div>

                          <div className="mt-4 inline-flex justify-center">
                            <Button
                              type="button"
                              className="inline-flex justify-center mr-5"
                              onClick={closeModal}
                            >
                              Cancelar
                            </Button>

                            <Button
                                color="failure"
                              type="button"
                              className="inline-flex justify-center"
                              onClick={handleConfirm}
                            >
                              { buttonConfirmText }
                            </Button>

                          </div>
                        </div>
                      </Transition.Child>
                    </div>
                  </Dialog>
                </Transition>
  )
}