import { useMutation, useQuery } from "@tanstack/react-query";
import { checkCrossQuestions, closeCrossApi, createCrossApi, deleteCrossAPI, getCrossUserScoreById, getCrossesByEventsApi, getCrossesByGroupApi, getCrossesById, updateCrossAPI } from "../api/crosses.api";
import { ICloseCross, ICreateCross, IUpdateCross } from "../api/types/cross.type";
import { Store } from "../store";

export const GetCrossesByEventQuery = ({store, eventId, enabled}:{store:Store, eventId:string, enabled: boolean}) => 
    useQuery(["getCrossesByEventQuery",{eventId}], () => getCrossesByEventsApi({eventId}), {
        onSuccess(data) {
            store.setCrosses(data);
            store.setRequestLoading(false);
        },
        onError(error) {
            store.setRequestLoading(false);
        },
        enabled
    });

export const GetCrossesByGroupQuery = ({store, groupId}:{store:Store, groupId:string}) => 
useQuery(["etCrossesByGroupQuery",{groupId}], () => getCrossesByGroupApi({groupId}), {
    onSuccess(data) {
        store.setCrosses(data);
        store.setRequestLoading(false);
    },
    onError(error) {
        store.setRequestLoading(false);
    },
});


export const GetCrossUserScoreByIdQuery = ({crossId, enabled = false, userId}:{ crossId:string, enabled: boolean, userId?:string}) => 
    useQuery(["getCrossesByIdQuery",{crossId, userId}], () => getCrossUserScoreById({crossId, userId}), {
    onSuccess(data) {
        return data
    },
    enabled
});

export const CheckCrossQuestions = ({crossId, enabled = false}:{ crossId:string, enabled: boolean}) => 
    useQuery(["checkCrossQuestions",{crossId}], () => checkCrossQuestions({crossId}), {
    onSuccess(data) {
        return data
    },
    enabled
});



export const GetCrossesByIdQuery = ({store = null, crossId, enabled = false}:{store?:Store | null, crossId:string, enabled:boolean}) => 
    useQuery(["getCrossesByIdQuery",{crossId}], () => getCrossesById({crossId}), {
    onSuccess(data) {
        if(store){
            store.setCross(data);
            store.setRequestLoading(false);
        }

        return data
        
    },
    onError(error) {
        if(store) store.setRequestLoading(false);
    },
    enabled
});
    
export const CreateCrossQuery = () => useMutation({
    mutationFn: async (eventData: ICreateCross) => {
        return await createCrossApi(eventData)
    },
})

export const CloseCrossQuery = () => useMutation({
    mutationFn: async (closeCross: ICloseCross) => {
        return await closeCrossApi(closeCross)
    },
})

export const UpdateCrossQuery = () => useMutation({
    mutationFn: async (updateCross: IUpdateCross) => {
        return await updateCrossAPI(updateCross)
    },
})

export const DelteCrossQuery = () => useMutation({
    mutationFn: async (crossId: string) => {
        return await deleteCrossAPI({crossId})
    },
})


