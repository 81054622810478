import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { TypeOf } from "zod";
import { INewPassword } from "../api/types/types";
import { Container } from "../components";
import FormInput from "../components/Forms/FormInput";
import { LoadingButton } from "../components/LoadingButton";
import { NewUserPasswordQuery } from "../queries/user.query";
import { profilePasswordSchema } from "../schemas";
import useStore from "../store";

export type ProfilePasswordSchema = TypeOf<typeof profilePasswordSchema>;

const ProfilePasswordPage = () => {
  const store = useStore();

  // No están todos los default values
  const methods = useForm<ProfilePasswordSchema>({
    resolver: zodResolver(profilePasswordSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const { mutate: registerEvent, isLoading, isSuccess } = NewUserPasswordQuery();

  const onSubmitHandler: SubmitHandler<ProfilePasswordSchema> = (values) => {
    const newPassword:INewPassword = {
      password: values.oldPassword,
      newPassword: values.password
    }
    registerEvent(newPassword)
  };

  return (
    <Container className="flex flex-col gap-6 py-8">
      <h2 className="font-semibold">Actualizar Contraseña</h2>
      { isLoading }
      { isSuccess && <>Contraseña actualizada</> }
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-x-12"
        >
          <FormInput
            label="Nueva contraseña"
            placeholder="Nueva contraseña"
            name="password"
            type="password"
          />
          <FormInput
            label="Confirma la contraseña"
            placeholder="Confirma la contraseña"
            name="passwordConfirm"
            type="password"
          />
          <FormInput
            label="Actual contraseña"
            placeholder="Actual contraseña"
            name="oldPassword"
            type="password"
          />
          <div className="flex flex-col w-full gap-4 md:col-span-2">
            <LoadingButton
              loading={store.requestLoading}
              textColor="text-white"
            >
              Actualizar Contraseña
            </LoadingButton>
            <div className="text-right">
              <Link to="/profile">Volver a mi perfil</Link>
            </div>
          </div>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ProfilePasswordPage;
