import React, { FC, useEffect, useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../helpers";
import { AnswerProps } from "./types";
import { IBetsAdd } from "../../api/types/bets.type";
import { IOptions } from "../../api/types/fields.type";
import { AddUserBetQuery, GetUserBetsByField } from "../../queries/user.query";
import Spinner from "../Spinner";

const Answer: FC<AnswerProps> = (props) => {
  const { question, answers, crossId, questionId, correctOption, isOpen, userId } =
    props;

  const [selectedAnswers, setSelectedAnswers] = useState<IOptions>();

  const { mutate: registerEvent, isLoading } = AddUserBetQuery();

  const { refetch, data } = GetUserBetsByField({
    fieldId: questionId,
    enabled: false,
    userId
  });

  const handlSubmitAnswer = (optionSelected: IOptions) => {
    setSelectedAnswers(optionSelected);
    const userBet: IBetsAdd = {
      crossId,
      field: questionId,
      optionSelcted: optionSelected.uuid || "",
    };
    registerEvent(userBet);
  };

  useEffect(() => {
    if(questionId){
      refetch();
    }
  }, [questionId]);

  useEffect(() => {
    // if (!isOpen) {
    //   const checkedOption = answers.find((a) => {
    //     return a.uuid === correctOption;
    //   });
    //   if (checkedOption) setSelectedAnswers(checkedOption);
    // }
    
    if(data){
      const check = answers.find((a) => {
        return a.uuid === data?.optionSelcted;
      });
    
      if (check) setSelectedAnswers(check);
    }
  }, [data, answers, isOpen, correctOption]);

  const radioButtonClasses = (
    checked: boolean,
    isOpen: boolean,
    answer: IOptions
  ): string => {
    let classes =
      "focus:outline-none border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 whitespace-nowrap";
    if (isOpen) {
      classes += " cursor-pointer";
      if (checked)
        classes +=
          " bg-claro-600 border-transparent text-white hover:bg-claro-700";
    } else {
      classes += " cursor-not-allowed";
      if (!checked) classes += " bg-white hover:bg-white text-gray-400";
      if (checked)
        classes +=
          " bg-claro-600 border-transparent text-white hover:bg-claro-700 opacity-70";
    }

    return classes;
  };

  return (
    <div>
      <p className="text-center">
        {question}
        {isLoading ? <Spinner /> : ""}
      </p>
      <div className="flex justify-center">
        <RadioGroup
          value={selectedAnswers}
          onChange={(optionSelected: IOptions) => {
            handlSubmitAnswer(optionSelected);
          }}
          className="mt-2"
        >
          <RadioGroup.Label className="sr-only">
          </RadioGroup.Label>
          <div className="flex items-center gap-3">
            {answers.map((answer) => (
              <RadioGroup.Option
                key={answer.uuid}
                value={answer}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-offset-2 ring-claro-500" : "",
                    radioButtonClasses(checked, isOpen, answer)
                  )
                }
                disabled={!isOpen}
              >
                <RadioGroup.Label as="span" className="flex items-center gap-3"> 
                  {  answer.option }      
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default Answer;
