import { object, string, z } from "zod";

export const requiredField = (fieldName: string) => `${fieldName} es requerido`;
export const invalidField = (fieldName: string) => `${fieldName} no es válido`;
export const maxCharacters = (number: number) =>
  `${number} is the maximum number of characters allowed`;

export const resetSchema = object({
  email: string()
    .min(1, requiredField("El email"))
    .email(invalidField("El email")),
});

export const loginSchema = object({
  email: string()
    .min(1, requiredField("El email"))
    .email(invalidField("El email")),
  password: string().min(1, requiredField("Este campo")),
});

export const registerSchema = object({
  name: string().min(1, requiredField("Tu nombre")),
  lastName: string().min(1, requiredField("Tu apellido")),
  email: string()
    .min(1, requiredField("El email"))
    .email(invalidField("El email")),
  phoneNumber: string().min(1, requiredField("Tu teléfono")),
  password: string().min(1, requiredField("Este campo")),
  passwordConfirm: string().min(1, requiredField("Confirma la constraseña")),
  isCostumer: z.boolean().optional().default(false),
  recaptcha: z.string().optional(),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Las contraseñas no coinciden",
});

export const updateUserSchema = object({
  name: string().min(1, requiredField("Tu nombre")),
  lastName: string().min(1, requiredField("Tu apellido")),
  phoneNumber: string().min(1, requiredField("Tu teléfono")),
  isCostumer: z.boolean().optional().default(false),
});

export const profileSchema = object({
  name: string().min(1, requiredField("Tu nombre")),
  lastName: string().min(1, requiredField("Tu apellido")),
  email: string()
    .min(1, requiredField("El email"))
    .email(invalidField("El email")),
  phoneNumber: string().min(1, requiredField("Tu teléfono")),
  isCostumer: z.boolean().optional().default(false),
});

export const profilePasswordSchema = object({
  oldPassword: string().min(1, requiredField("Este campo")),
  password: string().min(1, requiredField("Este campo")),
  passwordConfirm: string().min(1, requiredField("Confirma la constraseña")),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Las contraseñas no coinciden",
});
