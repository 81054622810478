import { useQuery } from "@tanstack/react-query";
import { platformGeneralMetrics } from "../api/admin.api";

export const GetPlatformGeneralMetrics = () => 
useQuery(["getCrossesByIdQuery"], () => platformGeneralMetrics(), {
    onSuccess(data) {
        return data
    }
});

