import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Label, Spinner, Tooltip } from "flowbite-react";
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import { HiClipboardCopy } from 'react-icons/hi';
import { RiTeamFill } from 'react-icons/ri';
import * as yup from "yup";
import { IUpdateRankingMember, IUsersRankings } from "../../api/types/users-rankings.types";
import { UserRankingsActionENUM } from '../../enums/rankings.enum';
import { CreateUserRankingQuery, GetUserRankingsQuery, UpdateUserRankingMembersQuery } from "../../queries/users-ranskings.query";
import { WhatsAppButton } from '../Buttons/whatsapp.buttons';
import { LoadingButton } from '../LoadingButton';
import FormUpdateRankingName from './FormUpdateRankingName';


export interface CreateCustomRankingProps {
  refetchGetRanking: () => void
}

type FormDataStatus = yup.InferType<typeof schemaSubmitCodeRanking>;

const schemaSubmitCodeRanking = yup.object({
  code: yup.string()
        .length(10)
        .required("You can't leave this blank."),
}).required();

const CreateCustomRanking: React.FC<CreateCustomRankingProps> = (props) => {
  const [ userRanking, setUserRankings ] = useState<IUsersRankings[]>()
  const [ rankingName, setRankingName ] = useState<string>("")
  const [ whatsappText, setWhatsappText ] = useState<string>("")
  
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormDataStatus>({
    resolver: yupResolver(schemaSubmitCodeRanking)
  });


  const {
    mutate: registerEvent, 
    isLoading: isLoadingCreateUserRanking, 
    data: dataCreateUserRanking
  } = CreateUserRankingQuery();

  const { 
    isLoading: isLoadingGetUserRankings, 
    data: dataUserRankings
   } = GetUserRankingsQuery({
      enabled: true,
  });

  const {
    mutate: registerUpdateUserRankingMembers, 
    isLoading: isLoadingUpdateUserRankingMembers, 
    isError,
    error,
    isSuccess: isSuccessUpdateUserRankingMembers,
    data: dataUpdateUserRankingMembers 
  } = UpdateUserRankingMembersQuery();

  useEffect(()=>{
    if(isSuccessUpdateUserRankingMembers && dataUpdateUserRankingMembers){
      window.location.reload()  
    }
    
  },[isSuccessUpdateUserRankingMembers])

  

  const handleCreateUserRanking = () => {
    registerEvent();
  }

  useMemo(()=>{
    if(dataCreateUserRanking){
      setUserRankings([dataCreateUserRanking])
      window.location.reload()
    }
  },[dataCreateUserRanking])

  useMemo(()=>{
    if(dataUserRankings && dataUserRankings.length >  0 ){
      setUserRankings(dataUserRankings)
      const WHTAS_APP_SHARE_MESSAGE = `Hey! Te invito a jugar ⛹🏻️‍♂️⛹️‍♀️ en Mi Liga Fantástica 🏀. Este es mi código de Liga ${dataUserRankings[0].code}. . Si no tienes cuenta, regístrate en 🔗https://www.ligafantasticaclaro.com/register`
      setWhatsappText(WHTAS_APP_SHARE_MESSAGE)
    }
  },[dataUserRankings])

  const handleAddUMemberRanking: SubmitHandler<FormDataStatus> = (values) => {
    const { code  } = values 
    
    const payload: IUpdateRankingMember = {
      code,
      action: UserRankingsActionENUM.ADD
    }
    
    registerUpdateUserRankingMembers(payload)
  }


  const FormAddEmemberToRanking:React.FC = () => {
    return <>
    <img
          className="max-w-md"
          src="/banners/banner-sumarse-liga.png"
        />
          <form 
            onSubmit={handleSubmit(handleAddUMemberRanking)} 
            className={"flex gap-2 flex-row justify-left items-center"}>
          
          <br></br>
          <Label
              htmlFor="code"
              value={"Código de liga: "}
            />
          {
          
          }
          <input {...register("code")} size={10} className={"w-40"}/>
          
          <LoadingButton loading={isLoadingUpdateUserRankingMembers} disabled={isLoadingUpdateUserRankingMembers} btnColor="bg-claro-1000 max-w-fit"  type="submit">Conectar</LoadingButton>

          { isSuccessUpdateUserRankingMembers && `Te conectaste a la liga de ${dataUpdateUserRankingMembers.owner.name}`}
        </form>
        </>
  }

  const createUserRanking = () => {
    return <>
      <LoadingButton 
        btnColor="bg-claro-1000 max-w-fit" 
        onClick={handleCreateUserRanking}
        loading={isLoadingCreateUserRanking}
        disabled={isLoadingCreateUserRanking}
      >Crear Mi Liga</LoadingButton>
    </>

  }

  const showMyLeagueCode = (userRanking:IUsersRankings[]) =>{
    return <Alert
    color="failure"
    icon={RiTeamFill}
    withBorderAccent={true}
    additionalContent={<React.Fragment>
      
      <WhatsAppButton message={whatsappText}/>
        
      </React.Fragment>
      }
  >
    
    <span className="font-medium flex flow-row justify-left px-3 py-1 text-claro-1000 align-middle items-center">
        <span className='mr-3'>{`Código de liga: ${userRanking[0].code}`}</span>
        <Tooltip
          content="Código de liga copiado"
          trigger="click"
        >
          <span className='text-red cursor-pointer py-1 px-2 rounded-md outline-none border-none flex justify-center transition hover:bg-claro-500 hover:text-white border border-claro-500 hover:border-transparent'  onClick={() => {navigator.clipboard.writeText(userRanking[0].code)}}><HiClipboardCopy size='1.2rem'/></span>
        </Tooltip>
        
    </span>
    
    
  </Alert>
  }

  
  return (
      <>
        { !userRanking?.length && createUserRanking()}
        
        {/* { userRanking?.length && <WhatsAppButton message={whatsappText}/>} */}
        
        { userRanking?.length && showMyLeagueCode(userRanking) }

        { userRanking?.length ? <FormUpdateRankingName refetchGetRanking={props.refetchGetRanking} code={userRanking[0].code} name={userRanking[0].name} /> : "" }
          
        {  isLoadingUpdateUserRankingMembers ? <Spinner/> : <FormAddEmemberToRanking/> }
        
      </>
    )
  };
  
export default CreateCustomRanking;
