import { Button, Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../api/types/types";
import { Container } from "../../components";
import BackButton from "../../components/Buttons/BackButtons";
import EditUserForm from "../../components/Forms/FormEditUser";
import { LoadingButton } from "../../components/LoadingButton";
import { GetUserByEmailQuery } from "../../queries/user.query";
import { RoutesEnum } from "../../router/enums/routes.enum";
import useStore from "../../store";


const EditUserPage = () => {
  const navigate = useNavigate();
  const [ userEmail, setUserEmail] = useState("")
  const { refetch, data, isFetching, isError, error, } = GetUserByEmailQuery({email: userEmail, enabled: false})
  const [ user, setUser ] = useState<IUser>()
  const store = useStore();
  
  const handleFindUserEmail = () =>{
    refetch()
  }

  useEffect(()=>{
    setUser(data)
  },[data])

  return (
    <Container className="flex gap-4 px-5 py-5 md:py-12 flex flex-col justify-center gap-12 py-0 md:gap-8 md:pb-20">
        
          <Card className="max-w-xs  position-auto">
          <BackButton/><label>Buscar usuario: </label>
          <input 
              type="text" 
              name="email" 
              value={userEmail}
              placeholder="email"
              onChange={(e) => setUserEmail(e.target.value)}
            ></input>

            { error && (error as any).response.status === 404 ? "Usuario no encontrado" : ""}

            <LoadingButton
              loading={isFetching}
              textColor="text-white"
              onClick={handleFindUserEmail}
            >
              Buscar usuario
            </LoadingButton>
          </Card>
            
          
          { user ? 
          <Card > 
            <Button onClick={()=> navigate(`${RoutesEnum.USER_PREDICTIONS}/${user.id}`) }>Ver predicciones hechas del usuario</Button>
            <EditUserForm user={user}/>
          </Card>: ""}
          
          
    </Container>
  );
};

export default EditUserPage;
