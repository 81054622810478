import Body from "../../components/Body";
import { Card } from "flowbite-react";
import UserEventDetails from "../../components/UserEventDetails";

const UserEventDetailPage = () => {

    return (
      <Body title={"Detalles del evento"}>
            <Card>
              <UserEventDetails/>   
            </Card>
        </Body>
    );
  };
  
export default UserEventDetailPage;

