import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { TypeOf } from "zod";
import { IUpdateUserProfile, IUser } from "../api/types/types";
import { Container } from "../components";
import FormInput from "../components/Forms/FormInput";
import FormToggle from "../components/Forms/FormToggle";
import { LoadingButton } from "../components/LoadingButton";
import { GetUserProfile, UpdateUserProfileQuery } from "../queries/user.query";
import { profileSchema } from "../schemas";
import useStore from "../store";

export type ProfileForm = TypeOf<typeof profileSchema>;

const ProfilePage = () => {
  const [isCostumer, setIsCostumber] = useState<boolean>(false)
  const store = useStore();
  const user = store.authUser;
  GetUserProfile({store})

  useEffect(()=>{
    setValue("isCostumer", store.authUser?.isCostumer || false)
    setValue("name", store.authUser?.name || "")
    setValue("lastName", store.authUser?.lastName || "")
  },[store.authUser])

  const methods = useForm<ProfileForm>({
    resolver: zodResolver(profileSchema),
    
    defaultValues: {
      name: user?.name,
      email: user?.email,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      isCostumer: user?.isCostumer
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;

  const {
    mutate: registerEvent,
    data,
    isSuccess,
    isLoading
  } = UpdateUserProfileQuery()

  useEffect(()=>{
    if(isSuccess && data && store.authUser){
        const authUser:IUser = store.authUser
        authUser.lastName = data.lastName
        authUser.name = data.name
        authUser.phoneNumber = data.phoneNumber
        store.setAuthUser(authUser)
    }
  },[data,isSuccess])

  const onSubmitHandler: SubmitHandler<ProfileForm> = (values) => {
    const { name, lastName, phoneNumber}  = values
    const updateUserProfile: IUpdateUserProfile = {
      name,
      lastName,
      phoneNumber
    }

    registerEvent(updateUserProfile)
  };

  return (
    <Container className="flex flex-col gap-6 py-8">
      <h2 className="font-semibold">Perfil de {user?.name}</h2>
      { isSuccess && <>Perfil actualizado</>}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-x-12"
        >
          <FormInput label="Nombre" name="name" type="text" />
          <FormInput label="Apellido" name="lastName" type="text" />
          <FormInput disabled={true} label="Email" name="email" type="email" />
          <FormInput label="Teléfono" name="phoneNumber" type="text" />
          <FormToggle
            disable={true} 
            label="¿Eres un cliente Claro?"
            setIsCostumber={setIsCostumber}
            defaultValue={store.authUser?.isCostumer ? 1 : 0}
            
          />
          <div className="flex flex-col w-full gap-4 md:col-span-2">
            <LoadingButton
              loading={isLoading}
              textColor="text-white"
            >
              Actualizar Perfil
            </LoadingButton>
            <div className="text-right">
              <Link to="/profile/password-reset">Actualizar contraseña</Link>
            </div>
          </div>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ProfilePage;
