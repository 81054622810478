import Body from "../../components/Body";
import { Card } from "flowbite-react";
import CrossesByEventCards from "../../components/CrossesByEventCards";

const CrossesByEventPage = () => {

    return (
        <Body title={"Cruces por evento"}>
            <Card>
                <CrossesByEventCards/>
            </Card>
        </Body>
    );
  };
  
export default CrossesByEventPage;
