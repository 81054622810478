import { FiArrowLeft } from "react-icons/fi";

const BackButton = () => {
  const goBack = () => {
    window.history.back();
  };

  const shouldShowButton = window.history.length > 1;

  return (
    shouldShowButton  ? <button
      className="bg-gray-500 mr-1 w-min hover:bg-gray-600 text-white rounded-md p-2 flex items-center"
      onClick={goBack}
    >
      <FiArrowLeft   />
    </button> : <></>
  );
};

export default BackButton;
