import { TbStarFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { RankingsENUM } from "../../enums/rankings.enum";
import { RoutesEnum } from "../../router/enums/routes.enum";

const PlayerCell = (props: any) => {
  const { player, index, selectedTab, currenPlayer, admin } = props;
  if(player.user.email === "admin@admin.com") console.log({player})
  const navigate = useNavigate();
  const processStart = () => {
    const rankingPosition = player.rankingPosition ?? 1
    // Fase de Grupos (Ranking Fase de Grupos): 6 local / 4 visitante; 
    if( 
      selectedTab === RankingsENUM.ROUND_1 && 
      player?.isCostumer &&
      rankingPosition < 7) return <TbStarFilled className="ml-5 text-amber-400"/>

      // Premio al que mas acierta tiple aciertos
    if( 
      selectedTab === RankingsENUM.TRIPLES && 
      rankingPosition === 1) return <TbStarFilled className="ml-5 text-amber-400"/>
    
    if( 
      selectedTab === RankingsENUM.ROUND_1 && 
      !player?.isCostumer &&
      rankingPosition < 5) return <TbStarFilled className="ml-5 text-amber-400"/>


    // Segunda Ronda (Ranking Segunda Ronda): 4 local / 2 visitante; 
    if( 
      selectedTab === RankingsENUM.ROUND_2 && 
      player?.isCostumer &&
      rankingPosition < 5) return <TbStarFilled className="ml-5 text-amber-400"/>
    
    if( 
        selectedTab === RankingsENUM.ROUND_2 && 
        !player?.isCostumer &&
        rankingPosition < 2) return <TbStarFilled className="ml-5 text-amber-400"/>
    
      // Semi & Final: 3 local / 1 visitante.
      if( 
        (selectedTab === RankingsENUM.SEMIFINAL || selectedTab === RankingsENUM.FINAL) && 
        player?.isCostumer &&
        rankingPosition < 4) return <TbStarFilled className="ml-5 text-amber-400"/>
    if( 
      (selectedTab === RankingsENUM.SEMIFINAL || selectedTab === RankingsENUM.FINAL) && 
        !player?.isCostumer &&
        rankingPosition < 2) return <TbStarFilled className="ml-5 text-amber-400"/>

      return <></>
   
  }
  const checkUserBets = (player:any) => {
    navigate(`${RoutesEnum.USER_PREDICTIONS}/${player?.user?._id}`)
  }
  
  
  return (
    <tr
      key={player?.user?.id}
      className={`${currenPlayer?.id === player?.user?._id && "bg-claro-300"}`}
    >
      <td>
        {processStart()}
      </td>
      <td className="py-4 pl-6 text-sm font-bold text-gray-500 whitespace-nowrap sm:pl-6">
        {player.rankingLabel}
      </td>
      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10">
            <img
              className="w-10 h-10 rounded-full"
              src={`https://api.dicebear.com/5.x/initials/svg?seed=${player?.user?.name}`}
              alt="player.user.name"
            />
          </div>
          <div className="ml-4">
              <div className="font-medium cursor-pointer hover:text-claro-1000 hover:underline" onClick={()=>{ checkUserBets(player)
                }}>{player?.user?.name}</div>
          </div>
        </div>
      </td>

      {selectedTab === RankingsENUM.GENERAL || admin ? (
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {player?.ligue}
        </td>
      ) : (
        ""
      )}

      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {player?.score}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {player?.doubleHits}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {player?.simpleHits}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {player?.hits}
      </td>
      {/* <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {player?.points5}
      </td> */}
      {selectedTab === RankingsENUM.ROUND_2 || selectedTab === RankingsENUM.SEMIFINAL  || selectedTab === RankingsENUM.FINAL || admin ? (
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {player?.global}
        </td>
      ) : (
        ""
      )}

      { admin ? 
        <>
          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
            {player?.user.phoneNumber}
          </td> 
          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
            {player?.user.email}
          </td> 
        </>
        :  ""
      }

    </tr>
  );
};

export default PlayerCell;
