import { Container, Footer } from "../components";
import Carousel from "../components/Carousel";
import Faq from "../components/Faq";
import HeaderLandingB from "../components/Header/HeaderLandingB";

const prices = [
  "/premios/first-round.png",
  "/premios/finales.png",
  "/premios/final.png",
  "/premios/overall.png",
  "/premios/sorteos.png",
];

const LandingPageB = () => {
  return (
    <div>
      <HeaderLandingB />
      <section className="overflow-hidden bg-center">
        <div className="p-8 bg-center bg-no-repeat bg-cover bg-hero-basket md:p-12">
          <Container className="flex flex-col justify-center gap-12 py-0 md:gap-8 md:pb-20">
            <div className="relative grid landing-hero place-items-center">
              <img
                className="absolute z-10 w-full mx-auto scale-125 top-20 md:scale-100 md:w-3/4 lg:w-1/2"
                src="/media/rankeate.png"
                alt="Rankeate Full"
              />
              <img
                className="absolute z-20 w-1/2 transform -translate-x-1/2 -translate-y-1/2 top-2/3 left-1/2 md:w-1/5 md:top-3/4 md:left-1/2"
                src="/media/jumpa.png"
                alt=""
              />
            </div>
          </Container>
        </div>
      </section>
      <section className="bg-center">
        <div
          className="pt-56 bg-center bg-no-repeat bg-cover md:mt-0 bg-wall md:pt-0"
          id="como-jugar"
        >
          <Container className="flex flex-col justify-center gap-12 px-6 py-0 md:gap-8 md:py-20">
            <h2 className="text-2xl text-white font-freshman">Juega con los partidos del torneo nacional ¡y podrías ganar premios! Monta tu Liga con tus panas y ¡Quema la malla con la Liga Fantástica Claro!
          </h2>
            <iframe
              className="z-30 object-cover w-full mx-auto overflow-hidden bg-transparent aspect-video rounded-3xl -mt-28 md:mt-10 md:w-3/4"
              src="https://www.youtube.com/embed/254X9PpHH7U"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <div
              className="flex items-center justify-center pt-20 -mt-20 md:mt-0 md:pt-0"
              id="premios"
            >
              <h2 className="text-4xl text-white font-freshman">Premios</h2>
            </div>
            <div>
              <Carousel images={prices} delay={3000} />
            </div>
            <div className="h-1 bg-claro-1000" />
          </Container>
        </div>
      </section>
      <section className="bg-center">
        <div
          className="relative pt-20 bg-center bg-no-repeat bg-cover bg-faq md:mt-0 md:pt-0"
          id="preguntas"
        >
          <img
            className="absolute hidden -left-1/4 md:block md:w-1/2"
            src="/media/puntos.png"
            alt="Preguntas Frecuentes"
          />
          <Container className="flex flex-col items-center justify-center px-4 py-12 overflow-hidden md:py-20 md:overflow-visible">
            {/* <img className="w-full md:w-3/4" src="/media/faq.png" alt="FAQ" /> */}
            {/* <img  src="/logo-liga.svg" alt="Claro" /> */}
            <div className="relative flex flex-col items-center justify-center w-full md:flex-row md:justify-start md:items-start">
              <h2 className="text-4xl text-white font-freshman">
                Preguntas Frecuentes
              </h2>
              <Faq />
            </div>
          </Container>
          <Container className="flex items-center justify-around gap-4 px-5 py-12 md:py-20">
            <img className="w-1/4" src="/logo.png" alt="Logo" />
            <section className="flex flex-col items-center justify-start flex-1 gap-2 md:gap-4 md:w-1/2 md:flex-auto">
              <img className="w-1/2" src="/media/siguenos.png" alt="Siguenos" />
              <h3 className="text-base text-center text-white uppercase font-radley md:text-5xl">
                & RANKEATE
              </h3>
              <div className="flex gap-4 md:gap-8">
                <a
                  href="https://www.facebook.com/Claropr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-8 h-8 md:w-20 md:h-20"
                    src="/media/facebook.png"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/claropr/?hl=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-8 h-8 md:w-20 md:h-20"
                    src="/media/instagram.png"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://twitter.com/clarotodo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-8 h-8 md:w-20 md:h-20"
                    src="/media/twitter.png"
                    alt="Twitter"
                  />
                </a>
              </div>
            </section>
          </Container>
        </div>
      </section>
      <Footer.Landing />
      <div className="fixed z-50 grid w-full mx-auto bottom-10 place-items-center md:hidden">
        <a
          href={"/login"}
          className={
            "text-white bg-claro-1000 hover:bg-claro-800 rounded-full transition hover:no-underline px-6 py-5 font-medium font-alfa"
          }
        >
          Empieza a Jugar
        </a>
      </div>
    </div>
  );
};

export default LandingPageB;
