
import { Button, Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../api/types/types";
import { IEvent } from "../interfaces/event.interfaces";
import { GetAllOpenEventsQuery } from "../queries/events.query";
import { AddUserEventQuery } from "../queries/user.query";
import { RoutesEnum } from "../router/enums/routes.enum";
import useStore from "../store";

const EventsList = () => {
    const navigate = useNavigate();
    const [ events, setEvents ] = useState<IEvent[]>([])
    const [ user, setUser ] = useState<IUser>()
    const store = useStore();
    
    GetAllOpenEventsQuery({ store })

    useEffect(()=>{
        if(store.events) 
            setEvents(store.events)
    },[store.events])

    useEffect(()=>{
        if(store.authUser) 
            setUser(store.authUser)
    },[store.authUser])

    const {
        mutate: registerEvent,
      } = AddUserEventQuery()
    
    const handleUserEventSuscribe = (eventId: string) => {
        registerEvent(eventId);
        user?.events.push(eventId)
        setUser(user)
    }

    const handleUserEvent = (eventId: string) => {
        navigate(`${RoutesEnum.USER_EVENT_DETAILS}/${eventId}`)
    }

    const eventCard = ( event: IEvent ) => {
        return <div className="max-w-sm">
          <Card key={event._id as string}>
              
            <h5 className="text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
              {event.name}
            </h5>

            {  !user?.events.includes(event._id as string) ? 
                <Button onClick={ () => handleUserEventSuscribe(event._id as string) }>Participar</Button> :
                <Button onClick={ () => handleUserEvent(event._id as string) } color="success">Ver evento</Button>
            }
            
          </Card>
        </div>
      }
    
    return (
        <>
            {events.map( e => eventCard(e))}
        </>
        
    );
  };
  
export default EventsList;
