import { ICross } from "../../api/types/cross.type";
import dayjs from "dayjs";

interface ResultVSProps {
  cross: ICross;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ResultVS = ({ cross }: ResultVSProps) => {

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center">
          <p className="font-bold text-center text-gray-600">
            {cross.site}
          </p>
          <p className="text-sm text-center text-gray-400 font-regular">
            {dayjs(cross.playTime).format("DD/MM/YYYY")} a las {dayjs(cross.playTime).format("h:mm a")}
          </p>
        </div>

        <div className="flex flex-col"></div>
      </div>
      <div className="flex items-center justify-around md:col-span-2">
        <div className="flex flex-col items-center w-1/3 gap-3">
        <img
            src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team1.photo}`}
            alt={cross.team1?.name}
            className="rounded-md"
          />
          <h4 className="whitespace-nowrap">{cross.team1?.name}</h4>
        </div>
        <h3>{cross?.team1Result} - {cross?.team2Result}</h3>
        <div className="flex flex-col items-center w-1/3 gap-3">
          <img
            src={`${BASE_URL}/bets/public/team-photo?photo=photo/${cross.team2.photo}`}
            alt={cross.team2.name}
            className="rounded-md"
          />
          <h4 className="whitespace-nowrap">{cross.team2.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default ResultVS;
